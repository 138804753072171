import React, { useContext, useEffect, useRef, useState } from "react";
import { Modal, Overlay, Popover } from "react-bootstrap";
import { FiChevronDown, FiEdit3 } from "react-icons/fi";
import Lottie from "react-lottie";
import ReactOTP from "react-otp-input";
import { useHistory, useLocation } from "react-router-dom";
import {
  createCart,
  createNewUser,
  getOTP,
  getUser,
  verifyOTP,
} from "../../api";
import successAnimation from "../../assets/animations/success.json";
import nestoLogo from "../../assets/images/logo.png";
import background from "../../assets/images/nesto-online-cover-img1.png";
import cartLogo from "../../assets/images/nesto-online-logo.svg";
import ButtonSpinner from "../../components/ButtonSpinner";
import { AppContext } from "../../context/AppContext";
import validate from "../../utils/formValidator";
import "./LoginPopup.css";

const defaultOptions = {
  autoplay: true,
  loop: false,
  animationData: successAnimation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const LoginPopup = (props) => {
  const {
    setLoggedIn,
    updateUser,
    showLogin,
    setShowToast,
    setToastData,
    currentStore,
    initializeCart,
    setAddressBook,
  } = useContext(AppContext);
  const [isNewUser, setIsNewUser] = useState(false);
  const [otpSend, setOtpSend] = useState(false);
  const [authSuccess, setAuthSuccess] = useState(false);
  const [countryCode, setCountryCode] = useState(971);
  const [phone, setPhone] = useState();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [checkValidation, setCheckValidation] = useState(false);
  const [otp, setOTP] = useState("");
  const history = useHistory();
  const location = useLocation();

  const handleClose = () => props.onHide(false);
  const resetData = () => {
    setName("");
    setEmail("");
    setPhone("");
    setOTP("");
    setIsNewUser(false);
    setOtpSend(false);
    setAuthSuccess(false);
    setCheckValidation(false);
  };
  useEffect(() => {
    resetData();
  }, [showLogin]);

  const handleReqOTP = () => {
    setCheckValidation(true);
    if (!phone) {
      setShowToast(true);
      setToastData({
        type: "info",
        text: "Please enter a phone number",
      });
      return 0;
    }
    if (
      (countryCode === 91 && phone <= 1000000000) ||
      (countryCode === 971 && phone <= 100000000) ||
      phone === NaN
    ) {
      setShowToast(true);
      setToastData({
        type: "info",
        text: "Invalid Phone Number",
      });
      return 0;
    }
    setIsLoading(true);
    getOTP({ mobilenumber: `+${countryCode}${phone}` })
      .then((res) => {
        if (res[0].status === "200") {
          setShowToast(true);
          setToastData({
            type: "success",
            text: res[0].message,
          });
          setOtpSend(true);
        } else {
          setShowToast(true);
          setToastData({
            type: "error",
            text: "Something Went Wrong!",
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
        setCheckValidation(false);
      });
  };

  const handleVerifyOTP = (otpData) => {
    if (otpData > 100000) {
      setOTP(otpData);
      setIsLoading(true);
      verifyOTP({
        customer: {
          mobilenumber: `+${countryCode}${phone}`,
          otp: otpData.toString(),
        },
      })
        .then((res) => {
          if (res[0]?.verify) {
            if (res[0]?.registerCustomer) {
              loginSuccess(res[0]?.token);
            } else {
              setOtpSend(false);
              setIsNewUser(true);
            }
          } else {
            setShowToast(true);
            setToastData({
              type: "error",
              text: res[0]?.message,
            });
          }
        })
        .finally(() => {
          setIsLoading(false);
        })
        .catch((e) => console.log(e));
    } else {
      setShowToast(true);
      setToastData({
        type: "info",
        text: "Enter a valid 6-digit OTP",
      });
    }
  };

  const loginSuccess = async (token) => {
    setLoggedIn({ access_token: token });
    try {
      const user = await getUser();
      updateUser(user);
      setAuthSuccess(true);
      resetData();
      handleClose();
      setAuthSuccess(false);
      location.pathname === "/" ? history.push("/") : window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  const handleSignUp = () => {
    setCheckValidation(true);
    if (email === "" || name === "") {
      setShowToast(true);
      setToastData({
        type: "info",
        text: "Please fill all required Fields",
      });
      return 0;
    }
    if (!validate(email.trim(), "email")) {
      setShowToast(true);
      setToastData({
        type: "info",
        text: "Invalid Email Format",
      });
      return 0;
    }
    setIsLoading(true);
    createNewUser({
      customer: {
        email: email,
        name: name,
        mobilenumber: `+${countryCode}${phone}`,
        otp: otp,
      },
    })
      .then((res) => {
        if (res[0].status === "200") {
          loginSuccess(res[0].token);
          createCart(currentStore?.store_code).then((cartres) => {
            initializeCart({ id: cartres });
          });
        } else {
          setShowToast(true);
          setToastData({
            type: "error",
            text: res[0]?.message,
          });
        }
      })
      .catch((e) => {
        setShowToast(true);
        setToastData({
          type: "error",
          text: e.message,
        });
      })
      .finally(() => {
        setCheckValidation(false);

        setIsLoading(false);
      });
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="p-0">
        {authSuccess ? (
          <div className="d-flex flex-column align-items-center justify-content-between pb-5">
            <Lottie options={defaultOptions} height={250} width={250} />
            <div className="h5 pb-2"> You are signed in.</div>
            <div className="pb-2">
              You have created an account successfully.
            </div>
          </div>
        ) : (
          <form
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            {otpSend ? (
              <VerifyOTP
                handleVerifyOTP={handleVerifyOTP}
                handleReqOTP={handleReqOTP}
                isLoading={isLoading}
                countryCode={countryCode}
                phone={phone}
                setOtpSend={setOtpSend}
              />
            ) : (
              <LoginUI
                isNewUser={isNewUser}
                setCountryCode={setCountryCode}
                setIsNewUser={setIsNewUser}
                handleReqOTP={handleReqOTP}
                countryCode={countryCode}
                phone={phone}
                setPhone={setPhone}
                isLoading={isLoading}
                handleSignUp={handleSignUp}
                setName={setName}
                name={name}
                setEmail={setEmail}
                email={email}
                checkValidation={checkValidation}
              />
            )}
          </form>
        )}
      </Modal.Body>
    </Modal>
  );
};

const LoginUI = ({
  isNewUser,
  setIsNewUser,
  handleReqOTP,
  setCountryCode,
  countryCode,
  phone,
  setPhone,
  isLoading,
  handleSignUp,
  setName,
  name,
  setEmail,
  email,
  checkValidation,
}) => {
  return (
    <div className="row m-0">
      <div className="col-lg-5 col-12 login-brading-container">
        <div className="login-logo p-3 d-flex justify-content-end">
          <img src={nestoLogo} alt="nestoLogo" width="20%" />
        </div>
        <div className="px-1 h3 fw-500">
          Welcome to
          <br />
          <span className="branding-main">Nesto Online Shopping</span>
        </div>
        <img
          src={background}
          alt="Nesto Online Background Image"
          className="login-image p-0"
        />
      </div>
      <div className="col-lg-7 col-12">
        {isNewUser ? (
          <RegisterUser
            setIsNewUser={setIsNewUser}
            isLoading={isLoading}
            handleSignUp={handleSignUp}
            setName={setName}
            name={name}
            setEmail={setEmail}
            email={email}
            checkValidation={checkValidation}
          />
        ) : (
          <RequestOTP
            setIsNewUser={setIsNewUser}
            handleReqOTP={handleReqOTP}
            setCountryCode={setCountryCode}
            countryCode={countryCode}
            phone={phone}
            setPhone={setPhone}
            isLoading={isLoading}
            checkValidation={checkValidation}
          />
        )}
      </div>
    </div>
  );
};
const RequestOTP = ({
  handleReqOTP,
  setCountryCode,
  countryCode,
  phone,
  setPhone,
  isLoading,
  checkValidation,
}) => {
  const countryRef = useRef();
  const [open, setOpen] = useState(false);
  const selectCountry = (code) => {
    setCountryCode(code);
    setOpen(false);
  };

  return (
    <>
      <div className="py-3 px-2 d-flex flex-column align-items-center text-center">
        <img src={cartLogo} alt="cartLogo" width="60px" />
        <div className="fw-700 fs-18">Welcome Back</div>
        <div className="fs-12 faded-color">
          To enjoy shopping to its full extend, let's sign you in.
        </div>
      </div>
      <div className="py-5 px-2">
        <div className="login-item px-2 mb-2">
          <label className="phone-input-label">
            Phone Number:
            {checkValidation && phone === "" && (
              <span className="required">*required</span>
            )}
          </label>
          <div className="phone-input">
            <span
              onClick={() => setOpen((old) => !old)}
              className="phone-country-dropdown col-lg-2 col-3 c-pointer"
              ref={countryRef}
            >
              {`+${countryCode}`}
              <FiChevronDown
                size="18"
                className={
                  "ml-2 arrow-rotate" + (open === true ? " spin180" : "")
                }
              />
            </span>
            <Overlay target={countryRef.current} placement="bottom" show={open}>
              <Popover
                id="popover-basic"
                className="d-flex flex-column country-popover"
              >
                <Popover.Content
                  className="fs-14 c-pointer highlight"
                  onClick={() => selectCountry(971)}
                >
                  +971
                </Popover.Content>
                <Popover.Content
                  className="fs-14 c-pointer highlight"
                  onClick={() => selectCountry(91)}
                >
                  +91
                </Popover.Content>
              </Popover>
            </Overlay>

            <input
              defaultValue={phone}
              onChange={(e) => setPhone(parseInt(e.target.value))}
              className="phone-input-number col-lg-10 col-9"
              maxLength={countryCode !== 91 ? 9 : 10}
              type="tel"
            />
          </div>
        </div>

        <div className="d-flex flex-column  align-items-center justify-content-center pt-4">
          <button className="btn btn-success px-4" onClick={handleReqOTP}>
            <ButtonSpinner buttonText={"Get OTP"} isLoading={isLoading} />
          </button>
        </div>
      </div>
    </>
  );
};

const RegisterUser = ({
  setIsNewUser,
  handleSignUp,
  isLoading,
  setName,
  setEmail,
  name,
  email,
  checkValidation,
}) => {
  return (
    <>
      <div className="py-3 px-2 d-flex flex-column align-items-center text-center">
        <img src={cartLogo} alt="cartLogo" width="60px" />
        <div className="fw-700 fs-18">Getting Started</div>
        <div className="fs-12 faded-color">Create an account to continue.</div>
      </div>
      <div className="py-5 px-2">
        <div className="login-item px-2 mb-2">
          <label className="phone-input-label">
            Name:
            {checkValidation && name === "" && (
              <span className="required">*required</span>
            )}
          </label>
          <input
            autoFocus
            className="phone-input-number col-12"
            maxLength={30}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="login-item px-2 mb-2">
          <label className="phone-input-label">
            Email:
            {checkValidation && email === "" && (
              <span className="required">*required</span>
            )}
          </label>
          <input
            className="phone-input-number col-12"
            maxLength={30}
            type="email"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="d-flex flex-column  align-items-center justify-content-center pt-4">
          <button className="btn btn-success px-4" onClick={handleSignUp}>
            <ButtonSpinner buttonText={"Register"} isLoading={isLoading} />
          </button>
          <span className="mt-4 faded-color">
            Already have an account?
            <a className="green-link" onClick={() => setIsNewUser(false)}>
              Sign In
            </a>
          </span>
        </div>
      </div>
    </>
  );
};

const VerifyOTP = ({
  handleVerifyOTP,
  handleReqOTP,
  isLoading,
  countryCode,
  phone,
  setOtpSend,
}) => {
  const [otpResend, setOtpResend] = useState(0);
  const [otpCounter, setOtpCounter] = useState(0);
  const [otp, setOtp] = useState();
  const startTimer = (x) => {
    let otpTempCounter = x;
    let otpTimer = setInterval(() => {
      if (otpTempCounter === 0) clearInterval(otpTimer);
      else {
        otpTempCounter--;
        setOtpCounter(otpTempCounter);
      }
    }, 1000);
  };
  useEffect(() => {
    startTimer(otpResend * 60);
  }, [otpResend]);
  return (
    <div className="d-flex flex-column align-items-center py-3 px-2">
      <img src={cartLogo} alt="cartLogo" width="60px" />
      <div className="fw-700 fs-18">OTP Authentication</div>
      <div className="fs-14 faded-color mt-2">
        {`OTP sent to +${countryCode}${phone}`}
        <a className="green-link fs-14" onClick={() => setOtpSend(false)}>
          (Edit <FiEdit3 fill="mediumseagreen" />)
        </a>
      </div>
      <ReactOTP
        value={otp}
        shouldAutoFocus
        onChange={setOtp}
        inputStyle="my-3 mx-1 form-control otp-input"
        numInputs={6}
        isInputNum={true}
      />
      <div className="d-flex flex-column  align-items-center justify-content-center">
        <span className="mb-3 faded-color">
          Didn't receive code?
          <a
            className="green-link"
            onClick={() => {
              if (otpCounter === 0) {
                handleReqOTP();
                setOtpResend((old) => old + 1);
              }
            }}
          >
            {otpCounter !== 0 ? ` Resend(${otpCounter}s)` : ` Resend`}
          </a>
        </span>

        <button
          className="btn btn-success px-4"
          onClick={() => handleVerifyOTP(otp)}
        >
          <ButtonSpinner buttonText={"Continue"} isLoading={isLoading} />
        </button>
      </div>
    </div>
  );
};
export default LoginPopup;
