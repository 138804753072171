import React, { useContext, useEffect, useState } from "react";
import { IoLocation } from "react-icons/io5";
import Loader from "react-loader-spinner";
import { deleteAddress, getAllAddress } from "../../../../api";
import { AddressDetails } from "../../../../components/AddressDetails/AddressDetails";
import AddressField from "../../../../components/AddressField";
import ModalPopup from "../../../../components/ModalPopup/ModalPopup";
import { AppContext } from "../../../../context/AppContext";
import "./AddressBook.css";

const getAddressString = (addr) => {
  let area = addr?.area;
  let city = addr?.city;
  let street = addr?.street;

  const ifIsString = (v, last = false) => {
    if (v) {
      return v + (last ? "" : ", ");
    }
    return "";
  };

  if (addr.residence_type === "villa") {
    let villa_number = addr.villa_number;
    let street_number = addr.street_number;

    return (
      ifIsString(villa_number) +
      ifIsString(street_number) +
      ifIsString(street) +
      ifIsString(area) +
      ifIsString(city, true)
    );
  } else {
    let bulding_name = addr.building_name;
    let flat_number = addr.flat_number;

    return (
      ifIsString(bulding_name) +
      ifIsString(flat_number) +
      ifIsString(street) +
      ifIsString(area) +
      ifIsString(city, true)
    );
  }
};

const AddressBook = () => {
  const { addressBook, setAddressBook } = useContext(AppContext);
  const [dataLoading, setDataLoading] = useState(false);
  const [addressModalDetails, setAddressModalDetails] = useState({
    show: false,
    startsWith: "",
    endsWith: "",
    item: null,
    initialLocation: null,
  });

  useEffect(() => {
    setDataLoading(true);
    getAllAddress()
      .then((res) => {
        setAddressBook(res[0]?.addresses);
      })
      .finally(() => setDataLoading(false));
  }, []);
  const removeItem = (id) => {
    return setAddressBook((addr) =>
      addr?.filter((add) => add.entity_id !== id)
    );
  };

  const setNewLocationData = (addr) => {
    return {
      lat: parseFloat(addr.latitude),
      lng: parseFloat(addr.longitude),
      name: addr?.location,
      area: {
        id: addr.area_id,
        name: addr.area_name,
      },
      zone: {
        id: addr.zone_id,
        name: addr.zone_name,
      },
      city: {
        id: addr.zms_city_id,
        name: addr.city,
      },
      country: addr.country_id,
    };
  };

  return (
    <>
      <AddressDetails
        show={addressModalDetails.show}
        onClose={() => setAddressModalDetails((d) => ({ ...d, show: false }))}
        startsWith={addressModalDetails.startsWith}
        endsWith={addressModalDetails.endsWith}
        item={addressModalDetails.item}
        initialLocation={addressModalDetails.initialLocation}
      />
      <div className="d-flex-column justify-content-between mb-3 address-book">
        <span className="profile-title my-2 mx-3">My Address</span>
        <p
          className="text-13 font-weight-normal green-color c-pointer border border-success rounded m-lg-3 p-lg-3 my-2 mx-1 py-3 px-0"
          onClick={() =>
            setAddressModalDetails({
              show: true,
              startsWith: "add-map",
              endsWith: "add-form",
            })
          }
        >
          <span
            className="py-2 px-3 mx-2 rounded"
            style={{ background: "#00983d33" }}
          >
            +
          </span>
          ADD NEW DELIVERY ADDRESS
        </p>
      </div>
      <div className="position-relative">
        <Loader
          className="address-book-loader"
          type="ThreeDots"
          color={"mediumseagreen"}
          height={70}
          width={70}
          visible={dataLoading}
        />
        {addressBook?.length > 0
          ? addressBook?.map((address, index) => {
              return (
                <div
                  className="item-container row p-2 text-break mb-2 mx-lg-2 mx-1"
                  key={`${index}-${address?.id}`}
                >
                  <div className="col-sm-1 d-flex justify-content-center align-items-center">
                    <IoLocation size={"25px"} color={"#00983d"} />
                  </div>
                  {/* ======================================== */}
                  <div className="col-lg-9 col-sm-11 col-12 d-flex flex-column justify-content-center">
                    <div className="font-weight-bold">{`${address?.firstname}`}</div>
                    {address?.default_shipping && (
                      <div className="text-success fs-12">Default Address</div>
                    )}
                    <div>{getAddressString(address)}</div>
                  </div>
                  {/* ======================================== */}

                  <div className="col-lg-2 col-12 d-flex flex-lg-column flex-row justify-content-around">
                    <button
                      className="edit-btn-addr"
                      onClick={() =>
                        setAddressModalDetails({
                          show: true,
                          startsWith: "edit-form",
                          endsWith: "close",
                          item: address,
                          initialLocation: setNewLocationData(address),
                        })
                      }
                    >
                      {"Edit"}
                    </button>
                    <button
                      className="delete-btn-addr"
                      onClick={() =>
                        address?.default_shipping
                          ? setAddressModalDetails({
                              show: true,
                              startsWith: "default-delete-message",
                              item: address,
                              endsWith: "close",
                            })
                          : setAddressModalDetails({
                              show: true,
                              startsWith: "delete",
                              item: address,
                              endsWith: "close",
                            })
                      }
                    >
                      {"Delete"}
                    </button>
                  </div>
                </div>
              );
            })
          : !dataLoading && (
              <div className="item-container my-3 mx-5 p-3 d-flex text-center justify-content-center">
                No Address Found
              </div>
            )}
      </div>
    </>
  );
};
export default AddressBook;
