import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import CartItem from "../../components/CartItem";
import "./ItemListing.css";

function ItemListing(props) {
  const history = useHistory();
  const location = useLocation();
  return (
    <div className="cart-table-wrap p-3 mb-4 md-md-0">
      {/* <table className="w-100">
        <thead className="border-line">
          <tr>
            <th className="faded-text pb-2 col-md-6 text-nowrap">ITEM</th>
            <th className="faded-text pb-2 col-md-2 text-nowrap">PRICE</th>
            <th className="faded-text pb-2 col-md-2 text-nowrap text-center">
              QTY
            </th>
            <th className="faded-text pb-2 col-md-2 text-nowrap">SUB TOTAL</th>
          </tr>
        </thead>
        <tbody>
          {props?.data?.map((item, index) => {
            return (
              <CartItem
                key={`cart-item-${index}-${item?.item_id}`}
                data={item}
                setIsDelete={props?.setIsDelete}
                type={props?.type}
                setOrderItems={props?.setOrderItems}
                orderItems={props?.orderItems}
                orderData={props?.orderData}
                setOrderItemData={props?.setOrderItemData}
                orderItemData={props?.data}
              />
            );
          })}
        </tbody>
      </table> */}
      <table className="w-100">
        <thead className="border-line d-none d-md-block">
          <tr className="row">
            <th className="faded-text pb-2 col-md-6 text-nowrap">ITEM</th>
            <th className="faded-text pb-2 col-md-2 text-nowrap">PRICE</th>
            <th className="faded-text pb-2 col-md-2 text-nowrap">QTY</th>
            <th className="faded-text pb-2 col-md-2 text-nowrap">SUB TOTAL</th>
          </tr>
        </thead>
        <tbody>
          {props?.data?.map((item, index) => {
            return (
              <CartItem
                key={`cart-item-${index}-${item?.item_id}`}
                data={item}
                setIsDelete={props?.setIsDelete}
                type={props?.type}
                setOrderItems={props?.setOrderItems}
                orderItems={props?.orderItems}
                orderData={props?.orderData}
                setOrderItemData={props?.setOrderItemData}
                setOrderItemDataEdit={props?.setOrderItemDataEdit}
                orderItemDataEdit={props?.orderItemDataEdit}
                orderItemData={props?.data}
              />
            );
          })}
        </tbody>
      </table>
      {location.pathname === "/cart" ? (
        <div className="d-flex justify-content-md-end mt-3 justify-content-center">
          <button
            className="btn btn-outline-success"
            onClick={() => {
              history.push("/");
            }}
          >
            Continue shopping
          </button>
          {/* <button className="secondary-button rounded-button">Add To Favourites</button> */}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default ItemListing;
