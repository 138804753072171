import React, { useEffect, useMemo, useRef } from "react";
import { useState } from "react";
import { useContext } from "react";
import { FiSearch } from "react-icons/fi";
import Loader from "react-loader-spinner";
import { Link, useHistory, useLocation } from "react-router-dom";
import { searchProduct } from "../../api";
import { AppContext } from "../../context/AppContext";
import useDebounce from "../../hooks/useDebounce";
import CartCard from "../CartCard/CartCard";
import "./SearchBar.css";
import useOnclickOutside from "react-cool-onclickoutside";

export const SearchBar = () => {
  const {
    setShowToast,
    setToastData,
    searchProductName,
    setSearchProductName,
    currentStore,
  } = useContext(AppContext);
  const { search } = useLocation();
  const history = useHistory();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const debounceSearchTerm = useDebounce(searchProductName, 200);
  const inputRef = useRef();
  const location = useLocation();

  const [productList, setProductList] = useState([]);
  const [suggestionList, setSuggestionList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [searchIsEmpty, setSearchIsEmpty] = useState(true);
  const [showResultsPopup, setShowResultsPopup] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [impressionList, setimpressionList] = useState(false);

  const ref = useOnclickOutside(() => {
    setShowResultsPopup(false);
  });

  useEffect(() => {
    const searchTerm = query.get("name");
    if (searchTerm) {
      setSearchProductName(searchTerm);
    } else {
      setSearchProductName("");
    }
  }, [query, setSearchProductName]);

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    let regVar = /^\s+$/;
    if (debounceSearchTerm.length < 3) {
      setShowToast(true);
      setToastData({
        type: "info",
        text: "Atleast 3 letter needed to search!",
      });
      return;
    }
    if (debounceSearchTerm !== "" && !regVar.test(debounceSearchTerm)) {
      window.gtag("event", "search", {
        search_term: debounceSearchTerm,
      });
      inputRef.current.blur();
      setShowResultsPopup(false);

      history.push(`/search?name=${debounceSearchTerm}`);
    }
  };

  useEffect(() => {
    const handlePopupSearch = async () => {
      setSearchLoading(true);
      let limit = 10;

      if (!debounceSearchTerm) {
        setProductList([]);
        setSuggestionList([]);
        setCategoryList([]);
        setSearchLoading(false);
        return;
      }

      if (debounceSearchTerm.length < 3) {
        setSearchLoading(false);
        return;
      }

      if (currentStore?.store_id) {
        await Promise.all([
          searchProduct(
            debounceSearchTerm,
            currentStore?.store_id,
            1,
            limit,
            "products"
          )
            .then((res) => {
              setProductList(res);
            })
            .catch((err) => {
              console.log(err);
              setProductList([]);
            }),
          searchProduct(
            debounceSearchTerm,
            currentStore?.store_id,
            1,
            limit,
            "suggestions"
          )
            .then((res) => {
              setSuggestionList(res);
            })
            .catch((err) => {
              console.log(err);
              setSuggestionList([]);
            }),
          searchProduct(
            debounceSearchTerm,
            currentStore?.store_id,
            1,
            limit,
            "categories"
          )
            .then((res) => {
              setCategoryList(res);
            })
            .catch((err) => {
              console.log(err);
              setCategoryList([]);
            }),
        ]);
      }

      setSearchLoading(false);
    };
    handlePopupSearch();
    setSearchIsEmpty(debounceSearchTerm.length < 3);
  }, [debounceSearchTerm, currentStore?.store_id]);

  useEffect(() => {
    setShowResultsPopup(false);
    inputRef.current.blur();
  }, [location.pathname]);

  useEffect(() => {
    showResultsPopup
      ? document.body.classList.add("lock-scroll")
      : document.body.classList.remove("lock-scroll");
  }, [showResultsPopup]);

  return (
    <>
      <form
        className="search-wrap ignore-onclickoutside"
        onSubmit={(e) => handleSearchSubmit(e)}
      >
        <input
          className="search-wrap-input"
          type="search"
          placeholder="What are you looking for...."
          value={decodeURIComponent(searchProductName)}
          onChange={(e) => {
            setSearchProductName(e.target.value);
          }}
          onFocus={() => setShowResultsPopup(true)}
          ref={inputRef}
        />
        <button
          className="btn d-inline-block search-wrap-icon click-anim"
          type="submit"
        >
          <FiSearch className="search-wrap-svg" />
        </button>
      </form>
      {showResultsPopup && <div className="search-popup-overlay"></div>}
      {showResultsPopup && (
        <div className="search-hits">
          <div
            className={`hits-holder hits-holder-border ${
              suggestionList.length !== 0 || categoryList.length !== 0
                ? "double-max-width"
                : ""
            }`}
            ref={ref}
          >
            <div className="mobile-hide-search">
              <button
                className="btn btn-sm btn-light"
                type="button"
                onClick={() => setShowResultsPopup(false)}
              >
                X
              </button>
            </div>
            {(suggestionList.length !== 0 || productList.length !== 0) && (
              <>
                <div className="suggestion-and-category">
                  {suggestionList.length !== 0 && (
                    <div className="suggestion-hits">
                      <h4 className="suggestion-hits-heading">SUGGESTIONS</h4>
                      <ul className="suggestion-hits-list">
                        {suggestionList?.map((sugg) => {
                          return (
                            <li className="mb-1">
                              <button
                                className="btn suggestion-hits-btn"
                                onClick={() =>
                                  setSearchProductName(sugg?.query)
                                }
                                type="button"
                              >
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      sugg?._highlightResult?.query?.value,
                                  }}
                                ></span>
                              </button>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                  {categoryList.length !== 0 && (
                    <div className="category-hits">
                      <h4 className="category-hits-heading">Categories</h4>
                      <ul className="category-hits-list">
                        {categoryList?.map((cat) => {
                          return (
                            <li className="mb-1">
                              <Link
                                className="category-hits-link"
                                to={`/categories/${cat?.objectID}`}
                              >
                                {cat?.name} ({cat?.product_count})
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                </div>
                <div className="divider-container" style={{ display: "block" }}>
                  <div className="divider"></div>
                </div>
              </>
            )}
            <div className="product-hits">
              <h4 className="product-hits-heading">MATCHING PRODUCTS</h4>
              {searchLoading ? (
                <div className="d-flex align-items-center justify-content-center py-4">
                  <Loader type="Oval" height={40} width={40} />
                </div>
              ) : (
                <>
                  {productList.length === 0 ? (
                    searchIsEmpty ? (
                      <div>Type 3 or more characters to get started</div>
                    ) : (
                      <div>No products found</div>
                    )
                  ) : (
                    <div className="product-hits-grid mb-3">
                      {productList?.map((item, index) => {
                        return (
                          <div key={item?.name} className="mb-2">
                            <CartCard
                              index={index}
                              className="cart-card-container-grid"
                              item={item}
                              from={null}
                              setimpressionList={setimpressionList}
                              impressionList={impressionList}
                            />
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {productList.length >= 10 && (
                    <Link
                      className="btn btn-outline-success mb-3"
                      onClick={() => setShowResultsPopup(false)}
                      to={`/search?name=${debounceSearchTerm}`}
                    >
                      View all products
                    </Link>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
