import React from "react";
import nestoCartLogo from "../../assets/images/nesto-cart-logo-big.png";
import appAndroidPhone from "../../assets/images/app-android-phone.png";
import googlePlay from "../../assets/images/google-play.png";
import appStore from "../../assets/images/app-store.png";
import QRCode from "qrcode.react";
import "./NestoApp.css";
import { Link } from "react-router-dom";
import { FiX } from "react-icons/fi";

export default function NestoApp() {
  return (
    <div className="nesto-app">
      <div className="content">
        <div className="box-one">
          <div className="main">
            <div className="mb-4 logo-box" style={{ zIndex: 1 }}>
              <img src={nestoCartLogo} alt="" style={{ maxWidth: "100%" }} />
            </div>
            <div className="details-box" style={{ zIndex: 1 }}>
              <div className="heading">Download the Nesto App</div>
              <div className="details">
                Bring the Experience of Hypermarket to your hands
              </div>
            </div>
            <div className="image-box" style={{ zIndex: 1 }}>
              <div>
                <img src={appAndroidPhone} alt="" />
              </div>
              <div>
                <img src={appAndroidPhone} alt="" />
              </div>
            </div>
          </div>
          <Link className="x-link" to="/">
            <FiX />
          </Link>
          <div className="vector-1"></div>
          <div className="vector-2"></div>
          <div className="vector-3"></div>
          <div className="vector-4"></div>
          <div className="vector-5"></div>
          <div className="badge one">Shop on the go</div>
          <div className="badge two">Secure Transaction</div>
          <div className="badge three">One Day Deliveries</div>
          <div className="badge four">Freshness Guaranteed</div>
          <div className="badge five">Get INAAM Rewards</div>
          <div className="badge six">Exclusive Deals</div>
        </div>
        <div className="box-two">
          <div className="details-box qr-box">
            <div className="heading">Scan the Code</div>
            <QRCode
              value={process.env.REACT_APP_BASE_URL + "inaam-app/qrcode"}
            />
          </div>
          <div className="details-box">
            <div className="heading">Download from Stores</div>
            <div className="mb-4">
              <a href="https://google">
                <img src={googlePlay} alt="" />
              </a>
            </div>
            <div>
              <a href="https://google">
                <img src={appStore} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
