import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import useWindowSize from "../../hooks/useWindowSize";
import "../../pages/HomepageNew/HomepageNew.css";
import compareWidgetsPosition from "../../utils/compareWidgetsPosition";
import "./Grid.css";
import CircularGridItem from "./Widgets/CircularGridItem/CircularGridItem";
import SquareGridItem from "./Widgets/SquareGridItem/SquareGridItem";

const Grid = ({ componentDesignData }) => {
  const { width } = useWindowSize();

  const getGridItem = (widget, index, key) => {
    switch (widget.widgetCode) {
      case "SQUARE_GRID_ITEMS":
        return <SquareGridItem widget={widget} index={index} key={key} />;
      case "CIRCULAR_GRID_ITEM":
        return <CircularGridItem widget={widget} index={index} key={key} />;
    }
  };
  return (
    <div className="container mt-4 Grid">
      {componentDesignData?.subTemplate?.details?.title && width > 450 && (
        <h1 className="homepage_title">
          {componentDesignData?.subTemplate?.details?.title}
        </h1>
      )}

      <div
        className={
          componentDesignData?.subTemplate?.widget.length < 5
            ? "grid-wrapper-4"
            : "grid-wrapper-8"
        }
      >
        {componentDesignData?.subTemplate?.widget
          .sort(compareWidgetsPosition)
          .map((widget, index) => getGridItem(widget, index, widget?._id))}
      </div>
    </div>
  );
};

export default Grid;
