import React from "react";
import xMockup from "../../assets/images/x-mockup.png";
import xMockup2 from "../../assets/images/x-mockup-2.png";
import inaamLogoAndTextWhite from "../../assets/images/inaam-logo-and-text-white.png";
import googlePlay from "../../assets/images/google-play.png";
import appStore from "../../assets/images/app-store.png";
import QRCode from "qrcode.react";
import "./InaamDownload.css";
import { Link } from "react-router-dom";
import { FiX } from "react-icons/fi";

export default function InaamDownload() {
  return (
    <div className="inaam-app">
      <div className="content">
        <div className="vector"></div>
        <Link to="/" className="x-link">
          <FiX />
        </Link>
        <div className="box-one row mx-0">
          <div className="col-lg-4 d-flex align-items-center justify-content-left justify-content-sm-center px-0 mb-3 mb-lg-0">
            <img src={inaamLogoAndTextWhite} alt="" />
          </div>
          <div className="col-lg-8 d-flex align-items-center justify-content-center px-0 mb-5 mb-lg-0">
            <div className="message-box">
              <div className="heading">Download the INAAM Loyalty App</div>
              <div className="details">
                Earn Rewards, Claim points, cashbacks and discounts in return.
              </div>
            </div>
          </div>
        </div>
        <div className="box-two row mx-0">
          <div className="link-box col-lg-4">
            <div className="details-box qr-box">
              <div className="heading">Scan the Code</div>
              <QRCode
                value={process.env.REACT_APP_BASE_URL + "inaam-app/qrcode"}
              />
            </div>
            <div className="details-box">
              <div className="heading">Download from Stores</div>
              <div className="mb-4">
                <a href="https://google">
                  <img src={googlePlay} alt="" />
                </a>
              </div>
              <div>
                <a href="https://google">
                  <img src={appStore} alt="" />
                </a>
              </div>
            </div>
          </div>
          <div className="image-box col-lg-8 px-0 pl-5">
            <div className="first-image">
              <img src={xMockup} className="" alt="" />
            </div>
            <div className="">
              <img src={xMockup2} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
