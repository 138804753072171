import Firebase from "firebase/app";
import "firebase/remote-config";
export const MAGENTO_BASE_URL = process?.env?.REACT_APP_MAGENTO_BASE_URL;
export const LAMBDA_BASE_URL = process?.env?.REACT_APP_LAMBDA_BASE_URL;
export const IMAGE_BASE_URL = process?.env?.REACT_APP_IMAGE_BASE_URL;
export const PAYMENT_BASE_URL = process?.env?.REACT_APP_PAYMENT_BASE_URL;
export const HOME_BUILDER_BASE_URL =
  process?.env?.REACT_APP_HOME_BUILDER_BASE_URL;
export const ZMS_BASE_URL = process?.env?.REACT_APP_ZMS_BASE_URL;
// export const DYNAMIC_HOMEPAGE_BASE_URL =
//   process?.env?.REACT_APP_DYNAMIC_HOMEPAGE_BASE_URL;
export const MAP_API_KEY = process?.env?.REACT_APP_MAP_API_KEY;
export const firebase = Firebase.initializeApp({
  apiKey: "AIzaSyBuE4QXYSWexZfVgjPGvRhuwlGndhSJ1pM",
  authDomain: "nesto-ecommerce.firebaseapp.com",
  projectId: "nesto-ecommerce",
  storageBucket: "nesto-ecommerce.appspot.com",
  messagingSenderId: "711047430980",
  appId: "1:711047430980:web:8f273b900e96c628a74d11",
  measurementId: "G-PH7HSV5KMF",
});
