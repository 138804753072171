/**Function to detect card type. ex- Visa, master card, etc. */
function detectCardType(number) {
  var re = {
    visa: /^4[0-9]{6,}$/,
    mastercard:
      /^5[1-5][0-9]{5,}|222[1-9][0-9]{3,}|22[3-9][0-9]{4,}|2[3-6][0-9]{5,}|27[01][0-9]{4,}|2720[0-9]{3,}$/,
    diners: /^3(?:0[0-5]|[68][0-9])[0-9]{4,}$/,
    discover: /^6(?:011|5[0-9]{2})[0-9]{3,}$/,
    americanexpress: /^3[47][0-9]{5,}$/,
  };

  for (var key in re) {
    if (re[key].test(number)) {
      return key;
    }
  }
}

export default detectCardType;
