import { useContext, useEffect, useState } from "react";
import { IoLocation } from "react-icons/io5";
import { AppContext } from "../../context/AppContext";
import detectCardType from "../../utils/detectCardType";
import {
  FaCcVisa,
  FaCcMastercard,
  FaCcDinersClub,
  FaCcDiscover,
} from "react-icons/fa";
import { SiAmericanexpress } from "react-icons/si";
import { MdDelete } from "react-icons/md";
import { deleteSavedCard, getSavedCards } from "../../api";
import { confirmAlert } from "react-confirm-alert";
import Loader from "react-loader-spinner";
const SavedCards = () => {
  const { userSavedCards, profile, setUserSavedCards } = useContext(AppContext);
  const [selectedSavedCard, setSelectedSavedCard] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);
  const { setShowToast, setToastData } = useContext(AppContext);

  const getCardIcon = (scheme) => {
    switch (scheme) {
      case "VISA":
        return <FaCcVisa size={"25px"} />;
      case "MASTERCARD":
        return <FaCcMastercard size={"25px"} />;
      case "DINERS":
        return <FaCcDinersClub size={"25px"} />;
      case "DISCOVER":
        return <FaCcDiscover size={"25px"} />;
      case "AMEX":
        return <SiAmericanexpress size={"25px"} />;
      default:
        return <FaCcVisa size={"25px"} />;
    }
  };

  const getUniqueSavedCards = (cards) => {
    const flag = {};
    const uniqueCards = [];
    cards.forEach((card) => {
      if (!flag[card?.maskedPan]) {
        flag[card?.maskedPan] = true;
        uniqueCards.push(card);
      }
    });
    return uniqueCards;
  };

  useEffect(() => {
    getSavedCards()
      .then((res) => JSON.parse(res))
      .then((res) => {
        const uniqueCards = getUniqueSavedCards(res?.data);
        setUserSavedCards([...uniqueCards]);
      })
      .catch((err) => console.log(err));
  }, []);

  console.log("cards", userSavedCards)

  const deleteSavedCardHandler = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body confirm-popup-button-body">
            <h2 className="fw-700">{"Delete?"}</h2>
            <div>{"Are you sure about deleting this Card?"}</div>
            <div className="btn-group d-flex justify-content-end">
              <button
                className={"confirm-popup-button confirm-button-delete"}
                onClick={() => {
                  setDataLoading(true);
                  deleteSavedCard({
                    card_id: id,
                    env: process.env.REACT_APP_ENV,
                    customer_id: profile.id,
                  })
                    .then((res) => {
                      getSavedCards()
                        .then((res) => JSON.parse(res))
                        .then((res) => {
                          if (res?.data?.length > 0 || res?.data !== "") {
                            const uniqueCards = getUniqueSavedCards(res?.data);
                            setUserSavedCards([...uniqueCards]);
                          } else {
                            setUserSavedCards([]);
                          }
                        })
                        .catch((err) => console.log(err));
                      setShowToast(true);
                      setToastData({
                        type: "success",
                        text: "Card Deleted",
                      });
                    })
                    .catch((e) => console.log(e))
                    .finally(() => {
                      setDataLoading(false);
                      window.scroll({
                        top: 0,
                        left: 0,
                        behavior: "smooth",
                      });
                    });
                  onClose();
                }}
              >
                {"Delete"}
              </button>
              <button
                className={"cancel-popup-button cancel-button-delete"}
                onClick={onClose}
              >
                No
              </button>
            </div>
          </div>
        );
      },
      closeOnClickOutside: true,
    });
  };

  // deleteSavedCard
  return (
    <div>
      <span className="profile-title my-3 mx-3">Saved Cards</span>
      <Loader
        className="address-book-loader"
        type="ThreeDots"
        color={"mediumseagreen"}
        height={70}
        width={70}
        visible={dataLoading}
      />
      {userSavedCards?.length > 0 ? (
        <div>
          {userSavedCards.map((item, index) => (
            // <div>{item?.savedcard?.maskedPan}</div>

            <div className="item-container row p-2 text-break mb-2 mx-lg-2 mx-1 justify-content-between">
              <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="d-flex justify-content-center align-items-center mx-3">
                  {getCardIcon(item?.scheme)}
                </div>
                {/* ======================================== */}
                <div className="">
                  <div className="font-weight-bold">{item?.cardholderName}</div>
                  <div>{item?.maskedPan}</div>
                  {/* <div>hey</div> */}
                </div>
              </div>
              {/* ======================================== */}
              <div className="d-flex flex-lg-column flex-row justify-content-around ml-3">
                <div class="d-flex flex-lg-column flex-row justify-content-around">
                  {/* <button class="edit-btn-addr">Edit</button> */}
                  <button
                    class="delete-btn-addr"
                    onClick={() => deleteSavedCardHandler(item.id)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="item-container my-3 mx-5 p-3 d-flex text-center justify-content-center">
          No Cards Found
        </div>
      )}
    </div>
  );
};

export default SavedCards;
