import React, { useEffect, useState, useContext } from "react";
import { FiTrash2 } from "react-icons/fi";
import CartButton from "../CartButton/CartButton";
import box from "../../assets/images/placeholder.svg";
import { IMAGE_BASE_URL } from "../../api/config";
import { deleteFromCart, addToCart, updateToCart } from "../../api";
import { AppContext } from "../../context/AppContext";
import "./SidebarCartItem.css";
import { useHistory } from "react-router";
import TagManager from "react-gtm-module";

function SidebarCartItem(props) {
  const history = useHistory();
  const {
    setShowToast,
    setToastData,
    currentStore,
    addToCartContext,
    updateCartContext,
    removeFromCartContext,
    cartId,
    cartData
  } = useContext(AppContext);
  const [cartValue, setCartValue] = useState(props?.cartData?.qty);
  const [updatedValue, setUpdatedValue] = useState(props?.cartData?.qty);
  const [isCartLoading, setIsCartLoading] = useState(true);
  const [hasClicked, setHasClicked] = useState(0);
  const [cartEventForGA, setCartEventForGA] = useState("");

  const fixTwo = (x) => {
    let fix = Math.round(x * 100) / 100;
    return fix;
  };

  const getCartTotal = () => {
    let mSubTotal = 0;
    let mTaxTotal = 0;
    cartData?.map((item) => {
      mSubTotal += fixTwo(item?.qty * item?.price);
      mTaxTotal += fixTwo(
        fixTwo(fixTwo(item?.qty * item?.price) * item?.tax_percentage) / 100
      );
    });
    return mSubTotal + mTaxTotal
  }
  function handleCart(methodToDo) {
    setIsCartLoading(true);
    const addToCartData = {
      cartItem: {
        quote_id: cartId,
        sku: props?.cartData?.sku,
        qty: cartValue,
      },
    };
    switch (methodToDo) {
      case "addToCart":
        addToCart(currentStore?.store_code, addToCartData)
          .then((res) => {
            cartDataLogger("addToCart", res);
            addToCartContext(res?.item_id, props?.cartData, res?.qty);
            setShowToast(true);
            setToastData({
              type: "success",
              text: "Product Added",
            });
            setUpdatedValue(cartValue);
          })
          .catch((e) => {
            setShowToast(true);
            setToastData({
              type: "error",
              text: e.message,
            });
          })
          .finally(() => {
            setIsCartLoading(false);
          });
        break;
      case "updateToCart":
        updateToCart(
          currentStore?.store_code,
          props?.cartData?.item_id,
          addToCartData
        )
          .then((res) => {
            cartDataLogger(cartEventForGA, res);
            setUpdatedValue(cartValue);
            updateCartContext(res?.item_id, res?.qty);
            setShowToast(true);
            setToastData({
              type: "success",
              text: "Product Updated",
            });
          })
          .catch((e) => {
            setCartValue((oldCartValue) => oldCartValue - 1);
            setShowToast(true);
            setToastData({
              type: "error",
              text: e.message,
            });
          })
          .finally(() => {
            setIsCartLoading(false);
          });
        break;
      case "deleteFromCart":
        deleteFromCart(currentStore?.store_code, props?.cartData?.item_id)
          .then((res) => {
            if (res) {
              cartDataLogger("removeFromCart", props?.cartData);
              removeFromCartContext(props?.cartData?.item_id);
              setShowToast(true);
              setToastData({
                type: "success",
                text: "Product Removed",
              });
            }
          })
          .catch((e) => {
            setShowToast(true);
            setToastData({
              type: "error",
              text: e.message,
            });
          })
          .finally(() => {
            setIsCartLoading(false);
          });
        break;
    }
  }

  const cartDataLogger = (eventName, data) => {
    // process?.env?.REACT_APP_ENV === "production" &&
    //   TagManager.dataLayer({
    //     dataLayerName: "dataLayer",
    //     dataLayer: {
    //       ecommerce: null,
    //     },
    //   });
      if(eventName === "removeFromCart"){
        window.gtag("event", "remove_from_cart", {
          currency: "AED",
          value: parseFloat(data?.tax_included_price || data?.price).toFixed(2),
          items: [
            {
              item_id: data?.sku,
              item_name: data?.name,
              currency: "AED",
              index: 0,
              price: data?.price,
              quantity: data?.qty
            }
          ]
        });
      }
    if (eventName === "addToCart") {
      window.gtag("event", "add_to_cart", {
        currency: "AED",
        value: parseFloat(data?.tax_included_price || data?.price).toFixed(2),
        items: [
          {
            item_id: data?.sku,
            item_name: data?.name,
            currency: "AED",
            index: 0,
            price: data?.price,
            quantity: data?.qty
          }
        ]
      });
      
      // process?.env?.REACT_APP_ENV === "production" &&
      //   TagManager.dataLayer({
      //     dataLayerName: "dataLayer",
      //     dataLayer: {
      //       event: eventName,
      //       ecommerce: {
      //         currencyCode: "AED",
      //         add: {
      //           // 'add' actionFieldObject measures.
      //           products: [
      //             {
      //               //  adding a product to a shopping cart.
      //               name: data?.name,
      //               id: data?.sku,
      //               price:
      //                 props?.cartData?.tax_included_special_price > 0
      //                   ? props?.cartData?.tax_included_special_price || 0
      //                   : props?.cartData?.tax_included_price,
      //               brand: "",
      //               category: "",
      //               variant: "",
      //               quantity: data?.qty,
      //             },
      //           ],
      //         },
      //       },
      //     },
      //   });
    } 
    // else 
    // {
    //   process?.env?.REACT_APP_ENV === "production" &&
    //     TagManager.dataLayer({
    //       dataLayerName: "dataLayer",
    //       dataLayer: {
    //         event: eventName,
    //         ecommerce: {
    //           currencyCode: "AED",
    //           remove: {
    //             // 'add' actionFieldObject measures.
    //             products: [
    //               {
    //                 //  adding a product to a shopping cart.
    //                 name: data?.name,
    //                 id: data?.sku,
    //                 price:
    //                   props?.cartData?.tax_included_special_price > 0
    //                     ? props?.cartData?.tax_included_special_price || 0
    //                     : props?.cartData?.tax_included_price,
    //                 brand: "",
    //                 category: "",
    //                 variant: "",
    //                 quantity: data?.qty,
    //               },
    //             ],
    //           },
    //         },
    //       },
    //     });
    // }
  };
  useEffect(() => {
    if (hasClicked === 0) return;
    if (cartValue == 0) return handleCart("deleteFromCart");
    if (cartValue > 0) return handleCart("updateToCart");
    if (cartValue > 0) return handleCart("addToCart");
  }, [hasClicked]);

  useEffect(() => {
    setCartValue(props?.cartData?.qty);
    setUpdatedValue(props?.cartData?.qty);
    setIsCartLoading(false);
  }, [props?.cartData?.qty]);

  function handleNoImage(el) {
    el.target.src = box;
  }

  const onCartItemClick = () => {
    // process?.env?.REACT_APP_ENV === "production" &&
    //   TagManager.dataLayer({
    //     dataLayerName: "dataLayer",
    //     dataLayer: {
    //       ecommerce: null,
    //     },
    //   });
    // process?.env?.REACT_APP_ENV === "production" &&
    //   TagManager.dataLayer({
    //     dataLayerName: "dataLayer",
    //     dataLayer: {
    //       event: "productClick",
    //       ecommerce: {
    //         click: {
    //           actionField: { list: "sidecart" }, // Optional list property.
    //           products: [
    //             {
    //               name: props?.cartData?.name, // Name or ID is required.
    //               id: props?.cartData?.sku,
    //               price:
    //                 props?.cartData?.tax_included_special_price > 0
    //                   ? props?.cartData?.tax_included_special_price || 0
    //                   : props?.cartData?.tax_included_price,
    //               brand: "",
    //               category: "",
    //               variant: "",
    //               position: 0,
    //             },
    //           ],
    //         },
    //       },
    //     },
    //   });
    history.push(`/products/${props?.cartData?.sku}`);
    props.setSidebar && props.setSidebar(false);
  };

  return (
    <div className="d-flex justify-content-between p-2 pb-4 p-md-4">
      <div className="d-flex flex-grow-1 w-100 mr-2">
        <img
          className="sidebar-item-img mr-1"
          onError={handleNoImage}
          onClick={onCartItemClick}
          src={
            props?.cartData?.image
              ? IMAGE_BASE_URL +
                "/catalog/product" +
                props?.cartData?.image.replace("/media" || "/product", "")
              : box
          }
          style={{ cursor: "pointer" }}
          alt="product"
        />
        <div className="d-flex flex-column justify-content-between mr-1">
          <p
            className="paragraph-info mb-3"
            style={{ cursor: "pointer" }}
            onClick={onCartItemClick}
          >
            {props?.cartData?.name}
          </p>
          <div style={{ maxWidth: "85px" }}>
            {props?.cartData?.is_salable === 0 ||
            props?.cartData?.salable_qty[0]?.qty === 0 ? (
              <p className="no-stock-cart ml-2 ml-md-0">Out of Stock</p>
            ) : (
              <></>
            )}
            {props?.cartData?.is_salable === 0 ||
            props?.cartData?.salable_qty[0]?.qty === 0 ? (
              <></>
            ) : (
              <CartButton
                loaderColor="#00983d"
                bg="transparent"
                color="#454545"
                border="1px solid #f2f2f2"
                type="compact"
                updatedValue={updatedValue}
                hasClicked={hasClicked}
                setHasClicked={setHasClicked}
                isCartLoading={isCartLoading}
                cartValue={cartValue}
                setCartValue={setCartValue}
                setCartEventForGA={setCartEventForGA}
              />
            )}
            {0 < props?.cartData?.salable_qty[0]?.qty &&
            props?.cartData?.salable_qty[0]?.qty <= cartValue ? (
              <div className="hurry-items-sidebar">
                Only {props?.cartData?.salable_qty[0]?.qty} left
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className="d-flex flex-column justify-content-between text-right flex-grow-1 pr-2">
        <p className="green-label straight-text side-cart-price">{`AED ${parseFloat(
          props?.cartData?.tax_included_price
        ).toFixed(2)}`}</p>
        <div>
          <button
            className="btn btn-sm px-0 py-2 py-md-0"
            onClick={() => handleCart("deleteFromCart")}
            style={{ whiteSpace: "nowrap" }}
          >
            <span className="d-none d-md-inline">Remove Item</span>
            <FiTrash2 fontSize="18px" className="d-md-none" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default SidebarCartItem;
