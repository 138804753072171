import React, { useEffect, useState, useContext } from "react";
import CartCard from "../CartCard/CartCard";

// NAVIGATION
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

import "./HorizontalProductList.css";
import { getCategoryItemsNoPage } from "../../api";
import { AppContext } from "../../context/AppContext";
import { useHistory } from "react-router";
import { BiArrowToRight } from "react-icons/bi";
import TagManager from "react-gtm-module";

SwiperCore.use([Navigation]);

const HorizontalProductList = ({
  title,
  swiperName,
  link,
  data = [],
  ctaData,
  loading
}) => {
  const history = useHistory();
  const { currentStore } = useContext(AppContext);
  const [horizontalListData, setHorizontalListData] = useState([]);
  let maxProductLength = 20;
  const [impressionList, setimpressionList] = useState([]);

  // useEffect(() => {
  //   if (impressionList?.length > 0) {
  //     process?.env?.REACT_APP_ENV === "production" &&
  //       TagManager.dataLayer({
  //         dataLayerName: "dataLayer",
  //         dataLayer: {
  //           ecommerce: null
  //         }
  //       });
  //     process?.env?.REACT_APP_ENV === "production" &&
  //       TagManager.dataLayer({
  //         dataLayerName: "dataLayer",
  //         dataLayer: {
  //           event: "productsViewed",
  //           ecommerce: {
  //             currencyCode: "AED",
  //             impressions: impressionList
  //           }
  //         }
  //       });
  //   }
  // }, [impressionList]);

  useEffect(() => {
    link &&
      currentStore?.store_id &&
      getCategoryItemsNoPage(link, currentStore?.store_id)
        .then(res => {
          let tempData = [];
          res?.map(item1 => {
            if (item1.type_id === "simple" && parseFloat(item1.price) !== 0)
              tempData.push(item1);
            else
              item1?.configurable_child_data?.map(item2 => {
                if (item2.type_id === "simple" && parseFloat(item2.price) !== 0)
                  tempData.push(item2);
              });
          });
          setHorizontalListData(tempData);
        })
        .catch(e => console.log(e.message));
  }, [link, currentStore]);

  useEffect(() => {
    if (data?.length > 0) {
      setHorizontalListData(data);
    }
  }, [data]);
  const goToDetails = () => {
    // if (parent_link === "2726") // production
    if (
      ctaData?.parent_link ===
      (process?.env?.REACT_APP_ENV === "production" ? "2726" : "1824")
    )
      history.push(`/deals/${ctaData?.link}`);
    else history.push(`/categories/${ctaData?.link}`);
  };
  return (
    <>
      {loading && horizontalListData?.length > 0 && (
        <div
          className={
            "container hr-productlist-container p-0 my-40 " + swiperName
          }
        >
          <div className="d-flex justify-content-between align-tems-center mb-2 px-3">
            <div className="hr-productlist-title">{title}</div>
            <div className="list-arrows-wrap d-flex">
              {link && (
                <span
                  className="c-pointer fw-700 color-green fs-14 d-flex align-items-center"
                  onClick={goToDetails}
                >
                  {ctaData?.text}
                </span>
              )}
              {/* {horizontalListData?.length > 8 && (
                <>
                  <div className={swiperName + " list-arrow left click-anim"} />
                  <div className={swiperName + " list-arrow right click-anim"} />
                </>
              )} */}
            </div>
          </div>
          {/* navigation={{
              nextEl: "." + swiperName + ".list-arrow.right",
              prevEl: "." + swiperName + ".list-arrow.left",
            }} */}
          <div className="slide-arrows">
            <div className="slide-button-prev">
              <FiChevronLeft className="arrow" />
            </div>
            <div className="slide-button-next">
              <FiChevronRight className="arrow" />
            </div>
            <Swiper
              cssMode={true}
              slidesPerView={"auto"}
              navigation={true}
              spaceBetween={10}
              navigation={{
                nextEl: "." + swiperName + " .slide-button-next",
                prevEl: "." + swiperName + " .slide-button-prev"
              }}
              observer={true}
              breakpoints={{
                // when window width is >= 280px
                0: {
                  slidesPerGroup: 1
                },
                // when window width is >= 460px
                460: {
                  slidesPerGroup: 2
                },
                // when window width is >= 640px
                640: {
                  slidesPerGroup: 3
                },
                // when window width is >= 820px
                820: {
                  slidesPerGroup: 4
                },
                // when window width is >= 1020px
                1020: {
                  slidesPerGroup: 5
                }
              }}
            >
              {horizontalListData?.length > maxProductLength ? (
                <>
                  {horizontalListData
                    ?.slice(0, maxProductLength - 1)
                    ?.map((item, index) => {
                      return (
                        <SwiperSlide key={index + "-" + item.sku}>
                          <CartCard
                            item={item}
                            className="cart-card-container-horizontal"
                            from={title}
                            index={index}
                            setimpressionList={setimpressionList}
                            impressionList={impressionList}
                          />
                        </SwiperSlide>
                      );
                    })}
                  <SwiperSlide
                    className="cart-card-container-horizontal d-flex justify-content-center align-items-center c-pointer"
                    key="show-more"
                    onClick={goToDetails}
                  >
                    Show More
                    <BiArrowToRight color="mediumseagreen" size={22} />
                  </SwiperSlide>
                </>
              ) : (
                horizontalListData?.map((item, index) => {
                  return (
                    <SwiperSlide key={index + "-" + item.sku}>
                      <CartCard
                        item={item}
                        className="cart-card-container-horizontal"
                        from={title}
                        index={index}
                        setimpressionList={setimpressionList}
                        impressionList={impressionList}
                      />
                    </SwiperSlide>
                  );
                })
              )}
            </Swiper>
          </div>
        </div>
      )}
    </>
  );
};

export default HorizontalProductList;
