import moment from "moment";
import { useContext } from "react";
import { AppContext } from "../../../context/AppContext";
import useWindowSize from "../../../hooks/useWindowSize";
import HomeBuilderRedirect from "../../HomeBuilderRedirect/HomeBuilderRedirect";
import OfferDesktop from "../widgets/Offer-Desktop.png";
import OfferMobile from "../widgets/Offer-phone.png";

const TwoBannerImageV2 = ({ widget }) => {
  const { width } = useWindowSize();
  const { getScaledFontSize } = useContext(AppContext);

  const timeCalc = () => {
    const difference = moment(widget?.details?.endDate).diff(moment());
    const duration = moment.duration(difference);
    const days = duration.asDays();
    return Math.floor(days);
  };

  return timeCalc() > 0 ? (
    <div
      className="d-flex justify-content-between grid-wrapper-1-v2"
      style={{
        background: `linear-gradient(115.14deg, ${widget?.details?.startGradient} 0%, ${widget?.details?.endGradient} 100%)`,
        // width: "12rem",
        cursor: width >= 678 ? "auto" : "pointer",
        height: "100%",
        minWidth: width > 850 ? "364px" : "200px",
        position: "relative",
        alignItems: "stretch",
      }}
    >
      <HomeBuilderRedirect
        classes="d-flex align-items-center justify-content-between grid-wrapper-1-v2"
        widget={widget}
      >
        {widget?.details?.endDate && (
          <div
            style={{
              position: "absolute",
              top: width > 425 ? "10px" : "5px",
              right: "0",
              width:
                width > 425
                  ? widget < 952
                    ? "55%"
                    : width < 768
                    ? "55%"
                    : "50%"
                  : "60%",
              overflow: "hidden",
            }}
          >
            <div className="two-banner-label-text">{timeCalc()} DAY OFFER</div>
            <img
              className="two-banner-label"
              src={width > 425 ? OfferDesktop : OfferMobile}
              alt="offer-end-date"
            />
          </div>
        )}

        <div className="offer-btn-wrapper d-flex flex-column align-items-start justify-content-between">
          <div
            className="banner-image-v2-discount-wrapper"
            style={{
              fontSize: `${getScaledFontSize(
                widget?.details?.discountFontSize,
                1.2
              )}px`,
            }}
          >
            {widget?.details?.discount}{" "}
            <span className="banner-image-v2-percent">%</span>{" "}
            <span className="banner-image-v2-off">Off</span>{" "}
          </div>
          <div
            className="banner-image-v2-title"
            style={{
              fontSize: `${getScaledFontSize(
                widget?.details?.titleFontSize,
                1.2
              )}px`,
            }}
          >
            {widget?.details?.title}
          </div>
          <div
            className="banner-image-v2-original-price"
            style={{
              fontSize: `${getScaledFontSize(
                widget?.details?.originalPriceFontSize,
                1.2
              )}px`,
            }}
          >
            {widget?.details?.originalPrice}
          </div>
          <div
            className="banner-image-v2-discounted-price"
            style={{
              fontSize: `${getScaledFontSize(
                widget?.details?.discountPriceFontSize,
                1.2
              )}px`,
            }}
          >
            {widget?.details?.discountPrice}
          </div>
          {width >= 768 && (
            <HomeBuilderRedirect widget={widget}>
              <button
                className="banner-button banner-btn-v2"
                style={{
                  background: widget?.details?.buttonColor,
                  color: widget?.details?.buttonFontColor,
                }}
              >
                Shop Now
              </button>
            </HomeBuilderRedirect>
          )}
        </div>
        <img
          className="banner-v2-images"
          src={
            width >= 445
              ? widget?.details?.imageUrlWeb
                ? widget?.details?.imageUrlWeb
                : widget?.details?.imageUrl
              : widget?.details?.imageUrl
          }
          alt="fruits-and-vegetables"
        />
      </HomeBuilderRedirect>
    </div>
  ) : (
    <div></div>
  );
};

export default TwoBannerImageV2;
