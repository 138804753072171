import { GoogleMap, Marker } from "@react-google-maps/api";
import { useFormik } from "formik";
import { useContext, useEffect, useRef, useState } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import Geocode from "react-geocode";
import Geosuggest from "react-geosuggest";
import { FiArrowLeft, FiEdit3 } from "react-icons/fi";
import { MdMyLocation } from "react-icons/md";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import {
  addNewAddress,
  deleteAddress,
  getAllAddress,
  getDetailsByCoords,
  getZoneById,
  updateAddress,
} from "../../api";
import { MAP_API_KEY } from "../../api/config";
import { AppContext } from "../../context/AppContext";
import { checkCustomAttributeValue } from "../../utils/checkCustomAttributeValue";
import { getAddressString } from "../../utils/getAddressString";
import "./AddressDetails.css";

const makeAddressData = (addr, locationData, profile) => {
  console.log("location", locationData);
  const newAddr = {
    id: addr?.entity_id,
    customer_id: profile?.id,
    firstname: addr?.firstname,
    lastname: ".",
    telephone: addr?.telephone,
    residence_type: addr?.residence_type || "building",
    building_name: addr?.building_name || "",
    floor_number: addr?.floor_number || "",
    villa_number: addr?.villa_number || "",
    street_number: addr?.street_number || "",
    street: [addr?.street],
    city: locationData?.city?.name,
    area_id: locationData?.id,
    area_name: locationData?.name,
    zms_city_id: locationData?.city?.id,
    zone_id: locationData?.zone?.id,
    zone_name: locationData?.zone?.name,
    latitude: addr?.latitude,
    longitude: addr?.longitude,
    region: {
      region_code: "UAE",
      region: "UAE",
      region_id: 43,
    },
    region_id: 43,
    custom_attributes: [],
    country_id: "AE",
  };

  newAddr.default_shipping = true;
  newAddr.default_billing = true;

  const custom_attributes = [
    "residence_type",
    "building_name",
    "floor_number",
    "villa_number",
    "street_number",
    "area_name",
    "area_id",
    "zms_city_id",
    "zone_id",
    "latitude",
    "longitude",
    "zone_name",
  ];
  let flatAddr = {
    ...newAddr,
    street: newAddr.street[0],
    mobile_number: checkCustomAttributeValue("mobile_number", profile),
    location: addr?.location || locationData?.name,
    entity_id: addr?.entity_id,
  };

  Object.keys(newAddr).forEach((k) => {
    if (custom_attributes.includes(k)) {
      newAddr.custom_attributes.push({
        attribute_code: k,
        value: newAddr[k],
      });
      delete newAddr[k];
    }
  });

  newAddr.custom_attributes.push({
    attribute_code: "mobile_number",
    value: checkCustomAttributeValue("mobile_number", profile),
  });

  newAddr.custom_attributes.push({
    attribute_code: "location",
    value: addr?.location,
  });

  return { flatAddr, newAddr };
};

const NewSystemMessageModal = ({ changeModal, setIsClosable }) => {
  const { profile } = useContext(AppContext);
  useEffect(() => {
    setIsClosable(false);
  }, []);

  return (
    <div className="address-modal-content">
      <div className="address-modal-header">
        <div className="address-modal-title">Welcome {profile?.firstname}</div>
      </div>
      <p>
        We have made some updates to our systems for more accurate and easier
        deliveries.
      </p>
      <p>
        We require some additional input from you. Let's proceed by selecting a
        default address.
      </p>
      <button
        className="btn btn-block btn-success"
        onClick={() => changeModal("default")}
      >
        Proceed
      </button>
    </div>
  );
};

const DefaultDeleteMessageModal = ({ changeModal, endsWith }) => {
  const { addressBook } = useContext(AppContext);
  let isOnlyAddress = addressBook?.length === 1;
  return (
    <div className="address-modal-content">
      <div className="address-modal-header">
        <div className="address-modal-title">
          {isOnlyAddress ? "Cannot Delete Address" : "Deleting Default Address"}
        </div>
      </div>
      <p>
        {isOnlyAddress
          ? "You only have 1 address, please add another address to be able to delete this address"
          : "Please select another address to be marked as default before deleting the current default address."}
      </p>
      {isOnlyAddress ? (
        <div className="d-flex justify-content-center">
          <button
            className="btn btn-success mr-3"
            onClick={() => changeModal("add-map")}
          >
            Proceed to add address
          </button>
          <button
            className="btn btn-secondary"
            onClick={() => changeModal("close")}
          >
            Close
          </button>
        </div>
      ) : (
        <button
          className="btn btn-block btn-success"
          onClick={() => changeModal("default-and-delete")}
        >
          Proceed
        </button>
      )}
    </div>
  );
};

const SelectDefaultAddress = ({
  changeModal,
  setSelectedItem,
  setLocationData,
  setIsClosable,
  onClose,
}) => {
  const {
    addressBook,
    setShowToast,
    setToastData,
    setAddressBook,
    profile,
    updateActiveAddress,
  } = useContext(AppContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setIsClosable(false);
  }, []);

  const changeSelected = async (addr) => {
    try {
      const result = await getDetailsByCoords(addr.latitude, addr.longitude);

      const { flatAddr, newAddr } = makeAddressData(addr, result.data, profile);
      setSelectedItem(flatAddr);

      if (!addr.zms_city_id || !addr.building_type) {
        try {
          // await updateAddress({ address: newAddr });
          updateActiveAddress(flatAddr);
          setLocationData({
            lat: parseFloat(addr.latitude),
            lng: parseFloat(addr.longitude),
            name: addr?.location || flatAddr?.location,
            area: {
              id: result?.data?.id,
              name: result?.data?.name,
            },
            zone: {
              id: result?.data?.zone?.id,
              name: result?.data?.zone?.name,
            },
            city: {
              id: result?.data?.city?.id,
              name: result?.data?.city?.name,
            },
            country: addr.country_id || "AE",
          });
          changeModal("edit-form");
        } catch (error) {
          setShowToast(true);
          setToastData({
            type: "error",
            text: "An error occurred while updating default address",
          });
        }
      }
      // else {
      //   // await updateAddress({ address: newAddr });
      //   setLocationData({
      //     lat: parseFloat(addr.latitude),
      //     lng: parseFloat(addr.longitude),
      //     name: addr?.location,
      //     area: {
      //       id: result?.data?.id,
      //       name: result?.data?.name,
      //     },
      //     zone: {
      //       id: result?.data?.zone?.id,
      //       name: result?.data?.zone?.name,
      //     },
      //     city: {
      //       id: result?.data?.city?.id,
      //       name: result?.data?.city?.name,
      //     },
      //     country: addr.country_id || "AE",
      //   });
      //   changeModal("edit-form");
      // }
    } catch (error) {
      setShowToast(true);
      setToastData({
        type: "error",
        text: "Selected Location is Not Deliverable",
      });
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const result = await getAllAddress();
        setAddressBook(result[0].addresses);
        // if (result[0].addresses.length === 1) {
        //   await changeSelected(result[0].addresses[0]);
        // }
      } catch (error) {
        setShowToast(true);
        setToastData({
          type: "error",
          text: "Error fetching your details, please try again later",
        });
      }
      setLoading(false);
    };
    getData();
  }, []);

  return (
    <div className="address-modal-content">
      <div className="address-modal-header">
        <div className="address-modal-title">Select a Default Address</div>
      </div>
      <hr />
      <div className="">
        <div className="address-modal-subtitle">Saved Addresses</div>
        <div className="list-address-row-list">
          {loading ? (
            <Skeleton count={3} />
          ) : addressBook.length > 0 ? (
            addressBook.map((addr) => {
              return (
                <div className={`list-address-row`} key={addr.entity_id}>
                  <div className="list-address-form-group">
                    <input
                      type="radio"
                      className="list-address-radio"
                      value={addr.entity_id}
                      onChange={() => changeSelected(addr)}
                      id={addr.entity_id}
                    />
                    <label
                      className="list-address-label"
                      htmlFor={addr.entity_id}
                    >
                      <span className="list-address-title">
                        {addr?.firstname}
                      </span>
                      {addr.default_shipping && (
                        <span className="list-address-tag">Default</span>
                      )}

                      <div className="list-address-text">
                        {getAddressString(addr)}
                      </div>
                    </label>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="text-center">
              You have no saved addresses, add an address to continue
            </div>
          )}
        </div>
      </div>
      {/* <div className="or-separator">
        <div className="line"></div>
        <div className="text">OR</div>
        <div className="line"></div>
      </div>
      <button
        className="btn btn-outline-success btn-block"
        onClick={() => changeModal("add-map")}
      >
        Add New Address
      </button> */}
    </div>
  );
};

const ListAddress = ({
  changeModal,
  setSelectedItem,
  onClose,
  setEnd,
  setIsClosable,
  setLocationData,
}) => {
  const {
    addressBook,
    currentStore,
    setShowToast,
    setToastData,
    updateLocation,
    updateCurrentStore,
    setAddressBook,
    profile,
    latLong,
    activeAddress,
    updateActiveAddress,
    store,
  } = useContext(AppContext);

  const [selectedAddress, setSelectedAddress] = useState();
  const [loading, setLoading] = useState(false);
  const [changeLoading, setChangeLoading] = useState(false);
  const history = useHistory();

  const changeSelected = async (addr) => {
    setSelectedAddress(addr.entity_id);
    setChangeLoading(true);
    try {
      if (!addr.zms_city_id) {
        const result = await getDetailsByCoords(addr.latitude, addr.longitude);
        const { flatAddr, newAddr } = makeAddressData(
          addr,
          result.data,
          profile
        );
        setSelectedItem(flatAddr);
        setLocationData({
          lat: parseFloat(addr.latitude),
          lng: parseFloat(addr.longitude),
          name: addr?.location,
          area: {
            id: result?.data?.id,
            name: result?.data?.name,
          },
          zone: {
            id: result?.data?.zone?.id,
            name: result?.data?.zone?.name,
          },
          city: {
            id: result?.data?.city?.id,
            name: result?.data?.city?.name,
          },
          country: addr.country_id || "AE",
        });
        changeModal("edit-form");
        setEnd("save");
        setIsClosable(false);
        setChangeLoading(false);
        return;
      }
      if (latLong?.zone?.id !== addr?.zone_id) {
        const result = await getZoneById(addr?.zone_id);
        onClose();
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="react-confirm-alert-body confirm-popup-button-body">
                <h2 className="fw-700">Confirm Store Change</h2>
                <div>
                  The location you have chosen is serviced by a different store,
                  Hence your cart and wishlist will be replaced with that of the
                  selected store.
                </div>
                <div className="btn-group d-flex justify-content-end">
                  <button
                    className={"confirm-popup-button confirm-button-update"}
                    onClick={() => {
                      updateLocation(
                        {
                          area: {
                            label: addr.area_name,
                            id: addr.area_id,
                          },
                          city: {
                            label: addr.city,
                            id: addr.city_id,
                          },
                          zone: {
                            label: addr.zone_name,
                            id: addr.zone_id,
                          },
                          lat: addr?.latitude,
                          long: addr?.longitude,
                        },
                        result?.data?.site
                      );
                      updateCurrentStore(result?.data?.site.stores[0]);
                      updateActiveAddress(addr);
                      onClose();
                      window.location.replace("/");
                    }}
                  >
                    {"Yes"}
                  </button>
                  <button
                    className={"cancel-popup-button cancel-button-update"}
                    onClick={() => {
                      onClose();
                    }}
                  >
                    No
                  </button>
                </div>
              </div>
            );
          },
          closeOnClickOutside: true,
        });
      } else {
        updateLocation(
          {
            area: {
              label: addr.area_name,
              id: addr.area_id,
            },
            city: {
              label: addr.city,
              id: addr.city_id,
            },
            zone: {
              label: addr.zone_name,
              id: addr.zone_id,
            },
            lat: addr?.latitude,
            long: addr?.longitude,
          },
          store
        );
        updateActiveAddress(addr);
        onClose();
      }
    } catch (error) {
      setShowToast(true);
      setToastData({
        type: "error",
        text: "Selected Location is Not Deliverable",
      });
      setSelectedAddress(null);
    }
    setChangeLoading(false);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const result = await getAllAddress();
        setAddressBook(result[0].addresses);
      } catch (error) {
        setShowToast(true);
        setToastData({
          type: "error",
          text: "Error fetching your details, please try again later",
        });
      }
      setLoading(false);
    };
    addressBook.length === 0 && getData();
  }, []);

  const setNewLocationData = (addr) => {
    setLocationData({
      lat: parseFloat(addr.latitude),
      lng: parseFloat(addr.longitude),
      name: addr?.location,
      area: {
        id: addr.area_id,
        name: addr.area_name,
      },
      zone: {
        id: addr.zone_id,
        name: addr.zone_name,
      },
      city: {
        id: addr.zms_city_id,
        name: addr.city,
      },
      country: addr.country_id,
    });
  };

  return (
    <div className="address-modal-content">
      {changeLoading && (
        <div className="full-size-fixed-spinner">
          <span className="ml-1 spinner-border spinner-border-sm text-light"></span>
        </div>
      )}
      <div className="address-modal-header">
        <div className="address-modal-title">Select Address</div>
        <button className="close-modal-button" onClick={onClose}>
          Close
        </button>
      </div>
      <hr />
      <div className="">
        <div className="address-modal-subtitle">Saved Addresses</div>
        <div className="list-address-row-list">
          {loading ? (
            <Skeleton count={3} />
          ) : addressBook.length > 0 ? (
            addressBook.map((addr) => {
              return (
                <div
                  className={`list-address-row ${
                    addr.entity_id ===
                      (activeAddress?.entity_id || activeAddress?.id) &&
                    "list-address-row-active"
                  }`}
                  key={addr.entity_id}
                >
                  <div className="list-address-form-group">
                    <input
                      type="radio"
                      className="list-address-radio"
                      value={addr.entity_id}
                      checked={
                        addr.entity_id ===
                        (activeAddress?.entity_id || activeAddress?.id)
                      }
                      onChange={() => changeSelected(addr)}
                      id={addr.entity_id}
                    />
                    <label
                      className="list-address-label"
                      htmlFor={addr.entity_id}
                    >
                      <span className="list-address-title">
                        {addr?.firstname || ""}
                      </span>
                      {addr.default_shipping && (
                        <span className="list-address-tag">Default</span>
                      )}

                      <div className="list-address-text">
                        {getAddressString(addr)}
                      </div>
                    </label>
                  </div>

                  <button
                    className="list-address-edit-modal-button"
                    onClick={() => {
                      setNewLocationData(addr);
                      setSelectedItem(addr);
                      changeModal("edit-form");
                    }}
                  >
                    <FiEdit3 />
                    <span className="ml-1">Edit</span>
                  </button>
                </div>
              );
            })
          ) : (
            <div className="text-center">
              You have no saved addresses, add an address to continue
            </div>
          )}
        </div>
      </div>
      <div className="or-separator">
        <div className="line"></div>
        <div className="text">OR</div>
        <div className="line"></div>
      </div>
      <button
        className="btn btn-outline-success btn-block"
        onClick={() => {
          setEnd("add-form");
          changeModal("add-map");
        }}
      >
        Add New Address
      </button>
    </div>
  );
};

const AddAddressMap = ({
  onClose,
  changeModal,
  locationData,
  setLocationData,
  end,
  setEnd,
}) => {
  const { setShowToast, setToastData, updateAddressLocation, latLong } =
    useContext(AppContext);
  const [coords, setCoords] = useState({
    lat: 0,
    lng: 0,
  });
  const [loading, setLoading] = useState(false);
  const geoRef = useRef();

  useEffect(() => {
    if (!navigator.geolocation) {
      getLocationData({
        lat: parseFloat(latLong.lat),
        lng: parseFloat(latLong.long),
      });
    } else {
      const onSuccess = (res) => {
        const { latitude, longitude } = res.coords;
        getLocationData({ lat: latitude, lng: longitude });
      };
      const onError = (err) => {
        getLocationData({
          lat: parseFloat(latLong.lat),
          lng: parseFloat(latLong.long),
        });
      };
      navigator.geolocation.getCurrentPosition(onSuccess, onError);
    }
  }, []);

  const getLocationData = async (latLng) => {
    Geocode.setApiKey(MAP_API_KEY);
    const { lat, lng } = latLng;
    setCoords({ lat, lng });
    Geocode.fromLatLng(latLng.lat, latLng.lng).then(
      (res) => {
        setLocationData({
          lat: latLng?.lat,
          lng: latLng?.lng,
          name: res?.results[0]?.formatted_address,
          city: {
            name: res?.results[0]?.address_components.filter(
              (item) => item?.types[0] === "administrative_area_level_1"
            )[0]?.long_name,
            code: res?.results[0]?.address_components.filter(
              (item) => item?.types[0] === "administrative_area_level_1"
            )[0]?.short_name,
          },
          country: {
            name: res?.results[0]?.address_components.filter(
              (item) => item?.types[0] === "country"
            )[0]?.long_name,
            code: res?.results[0]?.address_components.filter(
              (item) => item?.types[0] === "country"
            )[0]?.short_name,
          },
        });
        geoRef.current.update(res?.results[0]?.formatted_address);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const confirmFunction = async () => {
    setLoading(true);
    try {
      const { lat, lng } = coords;
      const result = await getDetailsByCoords(lat, lng);
      setLocationData({
        lat,
        lng,
        name: locationData?.name,
        area: {
          id: result.data.id,
          name: result.data.name,
        },
        zone: {
          id: result.data.zone.id,
          name: result.data.zone.name,
        },
        city: {
          id: result.data.city.id,
          name: result.data.city.name,
        },
        country: "AE",
      });
      updateAddressLocation({
        lat: locationData?.lat,
        long: locationData?.long,
        name: locationData?.name,
        store_id: result?.data?.zone?.site_id,
        website_id: result?.data?.zone?.site?.sap_website_id,
        city: locationData?.city,
        country: locationData?.country,
      });
      changeModal(end === "close" ? "add-form" : end);
    } catch (error) {
      setShowToast(true);
      setToastData({
        type: "error",
        text: "Selected Location is Not Deliverable",
      });
    }
    setLoading(false);
  };

  return (
    <div className="address-modal-content">
      <div className="address-modal-header">
        <div className="d-flex align-items-center">
          <button
            className="btn btn-light btn-sm mr-1"
            onClick={() => changeModal("list")}
          >
            <FiArrowLeft />
          </button>
          <div className="address-modal-title">
            {end === "edit-form" ? "Edit Address" : "Add New Address"}
          </div>
        </div>
        <button className="close-modal-button" onClick={onClose}>
          Close
        </button>
      </div>
      <hr />
      <div className="">
        <div className="address-modal-subtitle">Select Location</div>
        <div className="d-flex align-items-start mb-4">
          <Geosuggest
            ref={geoRef}
            className="mr-3 address-details-location-search"
            placeholder="Eg. Jumeirah Beach Residence, Dubai"
            inputClassName="form-control"
            suggestsClassName={`suggestions`}
            suggestItemClassName="item"
            autoComplete="off"
            onSuggestSelect={(e) => {
              if (e) {
                setCoords({ lat: e?.location?.lat, lng: e?.location?.lng });
                setLocationData({
                  lat: e?.location?.lat,
                  lng: e?.location?.lng,
                  name: e?.label,
                  city: {
                    name: e?.gmaps?.address_components.filter(
                      (item) => item?.types[0] === "administrative_area_level_1"
                    )[0]?.long_name,
                    code: e?.gmaps?.address_components.filter(
                      (item) => item?.types[0] === "administrative_area_level_1"
                    )[0]?.short_name,
                  },
                  country: {
                    name: e?.gmaps?.address_components.filter(
                      (item) => item?.types[0] === "country"
                    )[0]?.long_name,
                    code: e?.gmaps?.address_components.filter(
                      (item) => item?.types[0] === "country"
                    )[0]?.short_name,
                  },
                });
              }
            }}
          />
          <button
            className="btn btn-success flex-1"
            onClick={confirmFunction}
            disabled={loading}
          >
            {loading ? (
              <span className="ml-1 spinner-border spinner-border-sm text-light"></span>
            ) : (
              "Confirm"
            )}
          </button>
        </div>
        <GoogleMap
          center={{
            lat: locationData?.lat || locationData?.city.lat,
            lng: locationData?.lng || locationData?.city.lng,
          }}
          mapContainerStyle={{
            height: "450px",
            width: "100%",
          }}
          zoom={16}
          clickableIcons={false}
          onClick={(e) => getLocationData(e.latLng.toJSON())}
        >
          <Marker
            position={{
              lat: locationData?.lat || locationData?.city.lat,
              lng: locationData?.lng || locationData?.city.lng,
            }}
          />
        </GoogleMap>
      </div>
    </div>
  );
};

const AddAddressForm = ({ onClose, changeModal, locationData, endsWith }) => {
  const { profile, setShowToast, setToastData, addressBook, setAddressBook } =
    useContext(AppContext);

  const initialValues = {
    customer_id: profile?.id,
    firstname: "",
    lastname: ".",
    country_code: "971",
    telephone: "",
    residence_type: "building",
    building_name: "",
    floor_number: "",
    villa_number: "",
    street_number: "",
    street: "",
    area_id: locationData?.area?.id,
    area_name: locationData?.area?.name,
    zms_city_id: locationData?.city?.id,
    zone_id: locationData?.zone?.id,
    zone_name: locationData?.zone?.name,
    latitude: locationData?.lat,
    longitude: locationData?.lng,
    city: locationData?.city?.name,
    country_id: "AE",
    is_default: addressBook?.length === 0 ? true : false,
  };

  const validationSchema = Yup.object().shape({
    customer_id: Yup.number().required("This field is required"),
    firstname: Yup.string().required("This field is required"),
    country_code: Yup.string()
      .oneOf(["91", "971"])
      .required("This field is required"),
    telephone: Yup.string()
      .when("country_code", {
        is: "971",
        then: Yup.string()
          .test(
            "len",
            "Must be exactly 9 characters",
            (val) => val?.length === 9
          )
          .required("This field is required"),
      })
      .when("country_code", {
        is: "91",
        then: Yup.string()
          .test(
            "len",
            "Must be exactly 10 characters",
            (val) => val?.length === 10
          )
          .required("This field is required"),
      }),
    residence_type: Yup.string()
      .oneOf(["building", "villa"])
      .required("This field is required"),
    building_name: Yup.string().when("residence_type", {
      is: "building",
      then: Yup.string().required("This field is required"),
    }),
    floor_number: Yup.string().when("residence_type", {
      is: "building",
      then: Yup.string().required("This field is required"),
    }),
    villa_number: Yup.string().when("residence_type", {
      is: "villa",
      then: Yup.string().required("This field is required"),
    }),
    street_number: Yup.string().when("residence_type", {
      is: "villa",
      then: Yup.string().required("This field is required"),
    }),
    street: Yup.string(),
    is_default: Yup.bool().required("This field is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      let temp;
      if (values.residence_type === "building") {
        temp = { ...values, villa_number: "", street_number: "" };
      } else {
        temp = { ...values, building_name: "", floor_number: "" };
      }

      temp = {
        ...temp,
        street: [values.street || "."],
        telephone: values.country_code + values.telephone,
        region: {
          region_code: "UAE",
          region: "UAE",
          region_id: 43,
        },
        region_id: 43,
        custom_attributes: [],
      };

      delete temp.country_code;
      delete temp.is_default;

      if (values.is_default) {
        temp.default_shipping = true;
        temp.default_billing = true;
      }

      const custom_attributes = [
        "residence_type",
        "building_name",
        "floor_number",
        "villa_number",
        "street_number",
        "area_id",
        "area_name",
        "zms_city_id",
        "zone_id",
        "zone_name",
        "latitude",
        "longitude",
      ];

      Object.keys(temp).forEach((k) => {
        if (custom_attributes.includes(k)) {
          temp.custom_attributes.push({
            attribute_code: k,
            value: temp[k],
          });
          delete temp[k];
        }
      });

      temp.custom_attributes.push({
        attribute_code: "mobile_number",
        value: checkCustomAttributeValue("mobile_number", profile),
      });

      temp.custom_attributes.push({
        attribute_code: "location",
        value: locationData?.name,
      });

      try {
        await addNewAddress({ address: temp });

        const newAddr = await getAllAddress();

        setAddressBook(newAddr[0].addresses);

        endsWith === "close" || endsWith === "save" || endsWith === "add-form"
          ? onClose()
          : changeModal(endsWith);
      } catch (error) {
        setShowToast(true);
        setToastData({
          type: "error",
          text: error?.message || "An error occurred",
        });
      }
      return;
    },
  });

  return (
    <div className="address-modal-content">
      <div className="address-modal-header">
        <div className="d-flex align-items-center">
          <button
            className="btn btn-light btn-sm mr-1"
            onClick={() => changeModal("add-map")}
          >
            <FiArrowLeft />
          </button>
          <div className="address-modal-title">Add New Address</div>
        </div>
        <button
          className="close-modal-button"
          onClick={onClose}
          disabled={formik.isSubmitting}
        >
          Close
        </button>
      </div>
      <hr />
      <form className="" onSubmit={formik.handleSubmit}>
        <div className="address-modal-subtitle">Additional Details</div>
        <div className="form-group">
          <label htmlFor="firstname" className="required">
            Full Name
          </label>
          <input
            type="text"
            className="form-control"
            {...formik.getFieldProps("firstname")}
          />
          {formik.errors.firstname && (
            <p className="text-sm text-danger">{formik.errors.firstname}</p>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="telephone" className="required">
            Phone Number
          </label>
          <div className="d-flex align-items-center">
            <div className="input-group">
              <div className="input-group-prepend">
                <select
                  className="custom-select"
                  value={formik.values.country_code}
                  onChange={(e) =>
                    formik.setFieldValue("country_code", e.target.value)
                  }
                  style={{ borderRadius: "4px 0 0 4px" }}
                >
                  <option value="971">+971</option>
                  <option value="91">+91</option>
                </select>
              </div>
              <input
                type="text"
                className="form-control mr-3 mb-0"
                {...formik.getFieldProps("telephone")}
              />
            </div>
            <OverlayTrigger
              overlay={<Tooltip>To contact you at time of delivery.</Tooltip>}
            >
              <div style={{ whiteSpace: "nowrap" }}>
                <span
                  className="d-none d-md-inline mb-0 flex-shrink-0"
                  style={{ cursor: "default" }}
                >
                  Why we need this?
                </span>
                <span
                  className="d-md-none mb-0 flex-shrink-0"
                  style={{ cursor: "default" }}
                >
                  Why?
                </span>
              </div>
            </OverlayTrigger>
          </div>
          {formik.errors.telephone && (
            <p className="text-sm text-danger">{formik.errors.telephone}</p>
          )}
        </div>
        <hr />
        <div className="d-flex align-items-start mb-3 flex-column flex-md-row align-items-md-center">
          <p className="mb-0 mr-3 font-weight-bold required">Residence Type:</p>
          <div className="d-flex align-items-center">
            <div className="form-check mr-3">
              <input
                type="radio"
                className="form-check-input green-accent"
                value="building"
                id="building"
                checked={formik.values.residence_type === "building"}
                onChange={(e) =>
                  formik.setFieldValue("residence_type", e.target.value)
                }
              />
              <label htmlFor="building" className="form-check-label">
                Building/Apartment
              </label>
            </div>
            <div className="form-check">
              <input
                type="radio"
                className="form-check-input green-accent"
                value="villa"
                id="villa"
                checked={formik.values.residence_type === "villa"}
                onChange={(e) =>
                  formik.setFieldValue("residence_type", e.target.value)
                }
              />
              <label htmlFor="villa" className="form-check-label">
                Villa
              </label>
            </div>
          </div>
        </div>
        {formik.errors.residence_type && (
          <p className="text-sm text-danger">{formik.errors.residence_type}</p>
        )}
        {formik.values.residence_type === "building" && (
          <>
            <div className="form-group">
              <label htmlFor="building_name" className="required">
                Building Name
              </label>
              <input
                type="text"
                className="form-control"
                {...formik.getFieldProps("building_name")}
              />
              {formik.errors.building_name && (
                <p className="text-sm text-danger">
                  {formik.errors.building_name}
                </p>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="floor_number" className="required">
                Floor Number
              </label>
              <input
                type="text"
                className="form-control"
                {...formik.getFieldProps("floor_number")}
              />
              {formik.errors.floor_number && (
                <p className="text-sm text-danger">
                  {formik.errors.floor_number}
                </p>
              )}
            </div>
          </>
        )}
        {formik.values.residence_type === "villa" && (
          <>
            <div className="form-group">
              <label htmlFor="villa_number" className="required">
                Villa Number
              </label>
              <input
                type="text"
                className="form-control"
                {...formik.getFieldProps("villa_number")}
              />
              {formik.errors.villa_number && (
                <p className="text-sm text-danger">
                  {formik.errors.villa_number}
                </p>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="street_number" className="required">
                Street Number
              </label>
              <input
                type="text"
                className="form-control"
                {...formik.getFieldProps("street_number")}
              />
              {formik.errors.street_number && (
                <p className="text-sm text-danger">
                  {formik.errors.street_number}
                </p>
              )}
            </div>
          </>
        )}
        <div className="form-group">
          <label htmlFor="street">Additional Information (if any)</label>
          <input
            type="text"
            className="form-control"
            {...formik.getFieldProps("street")}
          />
          {formik.errors.street && (
            <p className="text-sm text-danger">{formik.errors.street}</p>
          )}
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="area_name" className="required">
                Area
              </label>
              <input
                type="text"
                className="form-control"
                {...formik.getFieldProps("area_name")}
                readOnly
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="city" className="required">
                City
              </label>
              <input
                type="text"
                className="form-control"
                {...formik.getFieldProps("city")}
                readOnly
              />
            </div>
          </div>
        </div>
        <button
          className="btn btn-block btn-success"
          type="submit"
          disabled={formik.isSubmitting}
        >
          Add to Address Book
          {formik.isSubmitting && (
            <span className="ml-1 spinner-border spinner-border-sm text-light"></span>
          )}
        </button>
        <button
          className="btn btn-block btn-outline-success"
          type="button"
          disabled={formik.isSubmitting}
          onClick={() => {
            formik.setFieldValue("is_default", true);
            formik.handleSubmit();
          }}
        >
          Set Address as Default
          {formik.isSubmitting && (
            <span className="ml-1 spinner-border spinner-border-sm text-light"></span>
          )}
        </button>
        {/* <pre>{JSON.stringify(formik.values, null, 2)}</pre> */}
      </form>
    </div>
  );
};

const EditAddressForm = ({
  item,
  changeModal,
  onClose,
  endsWith,
  isClosable,
  setIsClosable,
  locationData,
  setEnd,
}) => {
  const {
    profile,
    setShowToast,
    setToastData,
    addressBook,
    setAddressBook,
    updateLocation,
    updateCurrentStore,
    updateActiveAddress,
    activeAddress,
  } = useContext(AppContext);
  const history = useHistory();

  const [initialValues, setInitialValues] = useState({
    id: item?.entity_id,
    customer_id: profile?.id,
    firstname: item?.firstname,
    lastname: ".",
    country_code:
      item?.telephone.substring(0, 4) === "+971" ||
      item?.telephone.substring(0, 3) === "971"
        ? "971"
        : "91",
    telephone:
      item?.telephone.substring(0, 4) === "+971" ||
      item?.telephone.substring(0, 3) === "971"
        ? item?.telephone.slice(item?.telephone.length - 9)
        : item?.telephone.slice(item?.telephone.length - 10),
    residence_type: item?.residence_type || "building",
    building_name: item?.building_name || "",
    floor_number: item?.floor_number || "",
    villa_number: item?.villa_number || "",
    street_number: item?.street_number || "",
    street: item?.street,
    area_id: locationData?.area?.id,
    area_name: locationData?.area?.name,
    zms_city_id: locationData?.city?.id,
    zone_id: locationData?.zone?.id,
    zone_name: locationData?.zone?.name,
    latitude: locationData?.lat,
    longitude: locationData?.lng,
    city: locationData?.city?.name,
    country_id: "AE",
    is_default: item?.default_shipping || false,
    location: item?.location,
  });

  const validationSchema = Yup.object().shape({
    customer_id: Yup.number().required("This field is required"),
    firstname: Yup.string().required("This field is required"),
    country_code: Yup.string()
      .oneOf(["91", "971"])
      .required("This field is required"),
    telephone: Yup.string()
      .when("country_code", {
        is: "971",
        then: Yup.string()
          .test(
            "len",
            "Must be exactly 9 characters",
            (val) => val?.length === 9
          )
          .required("This field is required"),
      })
      .when("country_code", {
        is: "91",
        then: Yup.string()
          .test(
            "len",
            "Must be exactly 10 characters",
            (val) => val?.length === 10
          )
          .required("This field is required"),
      }),
    residence_type: Yup.string()
      .oneOf(["building", "villa"])
      .required("This field is required"),
    building_name: Yup.string().when("residence_type", {
      is: "building",
      then: Yup.string().required("This field is required"),
    }),
    floor_number: Yup.string().when("residence_type", {
      is: "building",
      then: Yup.string().required("This field is required"),
    }),
    villa_number: Yup.string().when("residence_type", {
      is: "villa",
      then: Yup.string().required("This field is required"),
    }),
    street_number: Yup.string().when("residence_type", {
      is: "villa",
      then: Yup.string().required("This field is required"),
    }),
    street: Yup.string(),
    is_default: Yup.bool().required("This field is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: async (values) => {
      let temp;
      if (values.residence_type === "building") {
        temp = { ...values, villa_number: "", street_number: "" };
      } else {
        temp = { ...values, building_name: "", floor_number: "" };
      }

      temp = {
        ...temp,
        street: [values.street || "."],
        telephone: values.country_code + values.telephone,
        region: {
          region_code: "UAE",
          region: "UAE",
          region_id: 43,
        },
        region_id: 43,
        custom_attributes: [],
      };

      delete temp.country_code;
      delete temp.is_default;

      if (values.is_default) {
        temp.default_shipping = true;
        temp.default_billing = true;
      }
      let forLocal = {
        ...temp,
        mobile_number: checkCustomAttributeValue("mobile_number", profile),
        location: locationData?.name,
        entity_id: temp?.id,
      };

      const custom_attributes = [
        "residence_type",
        "building_name",
        "floor_number",
        "villa_number",
        "street_number",
        "area_name",
        "area_id",
        "zms_city_id",
        "zone_id",
        "zone_name",
        "latitude",
        "longitude",
        "location",
      ];

      Object.keys(temp).forEach((k) => {
        if (custom_attributes.includes(k)) {
          temp.custom_attributes.push({
            attribute_code: k,
            value: temp[k],
          });
          delete temp[k];
        }
      });

      temp.custom_attributes.push({
        attribute_code: "mobile_number",
        value: checkCustomAttributeValue("mobile_number", profile),
      });

      temp.custom_attributes.push({
        attribute_code: "location",
        value: locationData?.name,
      });

      try {
        await updateAddress({ address: temp });

        const newAddr = await getAllAddress();
        setAddressBook(newAddr[0].addresses);

        if (
          endsWith === "save" ||
          (temp?.entity_id || temp?.id) ===
            (activeAddress?.entity_id || activeAddress?.id)
        ) {
          const result = await getDetailsByCoords(
            values.latitude,
            values.longitude
          );
          if (!result?.data?.city?.id) {
            setShowToast(true);
            setToastData({
              type: "error",
              text: "Invalid Coordinates",
            });
            return;
          }
          updateLocation(
            {
              area: {
                label: forLocal.area_name,
                id: forLocal.area_id,
              },
              city: {
                label: forLocal.city,
                id: forLocal.zms_city_id,
              },
              zone: {
                label: forLocal.zone_name,
                id: forLocal.zone_id,
              },
              lat: forLocal?.latitude,
              long: forLocal?.longitude,
            },
            result?.data?.zone?.site
          );
          updateCurrentStore(result?.data?.zone.site.stores[0]);
          updateActiveAddress(forLocal);
          onClose();
          setIsClosable(true);
          history.push("/");
        }
        endsWith === "close" || endsWith === "save"
          ? onClose()
          : changeModal(endsWith);
      } catch (error) {
        setShowToast(true);
        setToastData({
          type: "error",
          text: error?.message || "An error occurred",
        });
      }
    },
  });

  useEffect(() => {
    if (item) {
      console.log(
        item?.telephone,
        item?.telephone.substring(0, 3),
        item?.telephone.slice(item?.telephone.length - 9)
      );
      setInitialValues({
        id: item?.entity_id,
        customer_id: profile?.id,
        firstname: item?.firstname,
        lastname: ".",
        country_code:
          item?.telephone.substring(0, 4) === "+971" ||
          item?.telephone.substring(0, 3) === "971"
            ? "971"
            : "91",
        telephone:
          item?.telephone.substring(0, 4) === "+971" ||
          item?.telephone.substring(0, 3) === "971"
            ? item?.telephone.slice(item?.telephone.length - 9)
            : item?.telephone.slice(item?.telephone.length - 10),
        residence_type: item?.residence_type || "building",
        building_name: item?.building_name || "",
        floor_number: item?.floor_number || "",
        villa_number: item?.villa_number || "",
        street_number: item?.street_number || "",
        street: item?.street,
        area_id: locationData?.area?.id,
        area_name: locationData?.area?.name,
        zms_city_id: locationData?.city?.id,
        zone_id: locationData?.zone?.id,
        zone_name: locationData?.zone?.name,
        latitude: locationData?.lat,
        longitude: locationData?.lng,
        city: locationData?.city?.name,
        country_id: "AE",
        is_default: item?.default_shipping || false,
        location: item?.location,
      });
    }
  }, [item, profile, locationData]);

  return (
    <div className="address-modal-content">
      <div className="address-modal-header">
        <div className="d-flex align-items-center">
          <button
            className="btn btn-light btn-sm mr-1"
            onClick={() =>
              isClosable ? changeModal("list") : changeModal("default")
            }
          >
            <FiArrowLeft />
          </button>
          <div className="address-modal-title">Edit Address</div>
        </div>
        {isClosable && (
          <button className="close-modal-button" onClick={onClose}>
            Close
          </button>
        )}
      </div>
      <hr />
      <form className="" onSubmit={formik.handleSubmit}>
        <div className="d-flex align-items-center justify-content-between">
          <div className="address-modal-subtitle">Additional Details</div>
          {/* <div className="form-group form-check">
            <input
              type="checkbox"
              className="form-check-input green-accent"
              checked={formik.values.is_default}
              onChange={() =>
                formik.setFieldValue("is_default", !formik.values.is_default)
              }
            />
            <label className="form-check-label">
              Set this address as default
            </label>
            {formik.errors.is_default && (
              <p className="text-sm text-danger">{formik.errors.is_default}</p>
            )}
          </div> */}
        </div>
        <div className="form-group">
          <label htmlFor="firstname" className="required">
            Full Name
          </label>
          <input
            type="text"
            className="form-control"
            {...formik.getFieldProps("firstname")}
          />
          {formik.errors.firstname && (
            <p className="text-sm text-danger">{formik.errors.firstname}</p>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="telephone" className="required">
            Phone Number
          </label>
          <div className="d-flex align-items-center">
            <div className="input-group">
              <div className="input-group-prepend">
                <select
                  className="custom-select"
                  value={formik.values.country_code}
                  onChange={(e) =>
                    formik.setFieldValue("country_code", e.target.value)
                  }
                  style={{ borderRadius: "4px 0 0 4px" }}
                >
                  <option value="971">+971</option>
                  <option value="91">+91</option>
                </select>
              </div>
              <input
                type="text"
                className="form-control mr-3 mb-0"
                {...formik.getFieldProps("telephone")}
              />
            </div>
            <OverlayTrigger
              overlay={<Tooltip>To contact you at time of delivery.</Tooltip>}
            >
              <div style={{ whiteSpace: "nowrap" }}>
                <span
                  className="d-none d-md-inline mb-0 flex-shrink-0"
                  style={{ cursor: "default" }}
                >
                  Why we need this?
                </span>
                <span
                  className="d-md-none mb-0 flex-shrink-0"
                  style={{ cursor: "default" }}
                >
                  Why?
                </span>
              </div>
            </OverlayTrigger>
          </div>
          {formik.errors.telephone && (
            <p className="text-sm text-danger">{formik.errors.telephone}</p>
          )}
        </div>
        <hr />
        <div className="d-flex align-items-start mb-3 flex-column flex-md-row align-items-md-center">
          <p className="mb-0 mr-3 font-weight-bold required">Residence Type:</p>
          <div className="d-flex align-items-center">
            <div className="form-check mr-3">
              <input
                type="radio"
                className="form-check-input green-accent"
                value="building"
                id="building"
                checked={formik.values.residence_type === "building"}
                onChange={(e) =>
                  formik.setFieldValue("residence_type", e.target.value)
                }
              />
              <label htmlFor="building" className="form-check-label">
                Building/Apartment
              </label>
            </div>
            <div className="form-check">
              <input
                type="radio"
                className="form-check-input green-accent"
                value="villa"
                id="villa"
                checked={formik.values.residence_type === "villa"}
                onChange={(e) =>
                  formik.setFieldValue("residence_type", e.target.value)
                }
              />
              <label htmlFor="villa" className="form-check-label">
                Villa
              </label>
            </div>
          </div>
        </div>
        {formik.errors.residence_type && (
          <p className="text-sm text-danger">{formik.errors.residence_type}</p>
        )}
        {formik.values.residence_type === "building" && (
          <>
            <div className="form-group">
              <label htmlFor="building_name" className="required">
                Building Name
              </label>
              <input
                type="text"
                className="form-control"
                {...formik.getFieldProps("building_name")}
              />
              {formik.errors.building_name && (
                <p className="text-sm text-danger">
                  {formik.errors.building_name}
                </p>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="floor_number" className="required">
                Floor Number
              </label>
              <input
                type="text"
                className="form-control"
                {...formik.getFieldProps("floor_number")}
              />
              {formik.errors.floor_number && (
                <p className="text-sm text-danger">
                  {formik.errors.floor_number}
                </p>
              )}
            </div>
          </>
        )}
        {formik.values.residence_type === "villa" && (
          <>
            <div className="form-group">
              <label htmlFor="villa_number" className="required">
                Villa Number
              </label>
              <input
                type="text"
                className="form-control"
                {...formik.getFieldProps("villa_number")}
              />
              {formik.errors.villa_number && (
                <p className="text-sm text-danger">
                  {formik.errors.villa_number}
                </p>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="street_number" className="required">
                Street Number
              </label>
              <input
                type="text"
                className="form-control"
                {...formik.getFieldProps("street_number")}
              />
              {formik.errors.street_number && (
                <p className="text-sm text-danger">
                  {formik.errors.street_number}
                </p>
              )}
            </div>
          </>
        )}
        <div className="form-group">
          <label htmlFor="street">Additional Information (if any)</label>
          <input
            type="text"
            className="form-control"
            {...formik.getFieldProps("street")}
          />
          {formik.errors.street && (
            <p className="text-sm text-danger">{formik.errors.street}</p>
          )}
        </div>
        <button
          className="btn btn-link px-0 mb-3 d-flex align-items-center"
          onClick={() => {
            setEnd("edit-form");
            changeModal("add-map");
          }}
          type="button"
        >
          <MdMyLocation />
          <span className="ml-1">Change City and Area</span>
        </button>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="area_name" className="required">
                Area
              </label>
              <input
                type="text"
                className="form-control"
                {...formik.getFieldProps("area_name")}
                value={formik.values.area_name}
                readOnly
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="city" className="required">
                City
              </label>
              <input
                type="text"
                className="form-control"
                {...formik.getFieldProps("city")}
                readOnly
              />
            </div>
          </div>
        </div>
        <button
          className="btn btn-block btn-success"
          type="submit"
          disabled={formik.isSubmitting}
        >
          Save Address
          {formik.isSubmitting && (
            <span className="ml-1 spinner-border spinner-border-sm text-light"></span>
          )}
        </button>
        {!item?.default_shipping &&
          (item?.building_name || item?.villa_number) && (
            <button
              className="btn btn-block btn-outline-success"
              type="button"
              onClick={() => changeModal("confirm-default")}
            >
              Set as Default Address
            </button>
          )}
        {addressBook.length > 1 && endsWith !== "save" && (
          <button
            className="btn btn-block btn-light"
            type="button"
            onClick={() => {
              if (item?.default_billing) {
                return changeModal("default-delete-message");
              }
              changeModal("delete");
            }}
          >
            Remove Address
          </button>
        )}
      </form>
    </div>
  );
};

const ConfirmDefaultAddressModal = ({
  changeModal,
  item,
  locationData,
  onClose,
}) => {
  const { addressBook, profile, setShowToast, setToastData, setAddressBook } =
    useContext(AppContext);
  const [defaultAddr, setDefaultAddr] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setDefaultAddr(addressBook.filter((a) => a.default_billing)[0]);
  }, [addressBook]);

  const confirmDefault = async () => {
    setLoading(true);
    let addr = {
      id: item?.entity_id,
      customer_id: profile?.id,
      firstname: item?.firstname,
      lastname: ".",
      telephone:
        item?.telephone.substring(0, 4) === "+971" ||
        item?.telephone.substring(0, 3) === "971"
          ? item?.telephone.slice(item?.telephone.length - 9)
          : item?.telephone.slice(item?.telephone.length - 10),
      residence_type: item?.residence_type || "building",
      building_name: item?.building_name || "",
      floor_number: item?.floor_number || "",
      villa_number: item?.villa_number || "",
      street_number: item?.street_number || "",
      street: [item?.street],
      area_id: locationData?.area?.id,
      area_name: locationData?.area?.name,
      zms_city_id: locationData?.city?.id,
      zone_id: locationData?.zone?.id,
      zone_name: locationData?.zone?.name,
      latitude: locationData?.lat,
      longitude: locationData?.lng,
      city: locationData?.city?.name,
      country_id: locationData?.country,
    };
    addr.default_shipping = true;
    addr.default_billing = true;

    const custom_attributes = [
      "residence_type",
      "building_name",
      "floor_number",
      "villa_number",
      "street_number",
      "area_name",
      "area_id",
      "zms_city_id",
      "zone_id",
      "latitude",
      "longitude",
      "zone_name",
    ];

    addr.custom_attributes = [];

    Object.keys(addr).forEach((k) => {
      if (custom_attributes.includes(k)) {
        addr.custom_attributes.push({
          attribute_code: k,
          value: addr[k],
        });
        delete addr[k];
      }
    });

    addr.custom_attributes.push({
      attribute_code: "mobile_number",
      value: checkCustomAttributeValue("mobile_number", profile),
    });

    addr.custom_attributes.push({
      attribute_code: "location",
      value: item?.location,
    });
    try {
      await updateAddress({ address: addr });

      setAddressBook((addr) =>
        addr.map((a) => {
          if (a.entity_id === item.entity_id) {
            return { ...a, default_billing: true, default_shipping: true };
          }
          if (a.entity_id === defaultAddr.entity_id) {
            return { ...a, default_billing: false, default_shipping: false };
          }
          return a;
        })
      );
      onClose();
    } catch (error) {
      setShowToast(true);
      setToastData({
        type: "error",
        text: "An error occurred while updating default address",
      });
    }
    setLoading(false);
  };

  return (
    <div className="address-modal-content">
      <div className="address-modal-header">
        <div className="delete-modal-title">Confirm Default Address</div>
      </div>
      <hr />
      <div className="">
        <div className="mb-2">Your default address will be changed from:</div>
        {defaultAddr && (
          <>
            <p className="delete-modal-text fw-700 mb-0">
              {defaultAddr.firstname}
            </p>
            <p className="delete-modal-text">{getAddressString(defaultAddr)}</p>
          </>
        )}
        <div className="mb-2">To:</div>
        <p className="delete-modal-text fw-700 mb-0">{item.firstname}</p>
        <p className="delete-modal-text">{getAddressString(item)}</p>
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <button
          className="btn btn-outline-success mr-4"
          onClick={() => changeModal("edit-form")}
        >
          Cancel
        </button>
        <button
          className="btn btn-success"
          onClick={confirmDefault}
          disabled={loading}
        >
          Yes, Change Default Address
          {loading && (
            <span className="ml-1 spinner-border spinner-border-sm text-light"></span>
          )}
        </button>
      </div>
    </div>
  );
};

const DeleteModal = ({ item, changeModal, closeFunction, endsWith }) => {
  const {
    setShowToast,
    setToastData,
    setAddressBook,
    activeAddress,
    addressBook,
    updateActiveAddress,
  } = useContext(AppContext);
  const [loading, setLoading] = useState(false);

  const confirmDelete = async () => {
    setLoading(true);
    try {
      await deleteAddress(item.entity_id);

      if (activeAddress.entity_id === item.entity_id) {
        const defaultAddress = addressBook.filter((a) => a.default_billing)[0];
        updateActiveAddress(defaultAddress);
        return window.location.reload();
      }

      setAddressBook((addr) => {
        return addr.filter((add) => add.entity_id !== item.entity_id);
      });

      changeModal(endsWith || "list");
      setShowToast(true);
      setToastData({
        type: "success",
        text: "Address deleted",
      });
    } catch (error) {
      setShowToast(true);
      setToastData({
        type: "error",
        text: error?.message || "An error occurred",
      });
    }
    setLoading(false);
  };

  return (
    <div className="address-modal-content">
      <div className="address-modal-header">
        <div className="delete-modal-title">Confirm Deletion</div>
      </div>
      <hr />
      <div className="">
        <div className="delete-modal-subtitle">
          Are you sure you want to delete this address?
        </div>
        <p className="delete-modal-text fw-500 mb-0">{item.firstname}</p>
        <p className="delete-modal-text">{getAddressString(item)}</p>
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <button
          className="btn btn-outline-success mr-4"
          onClick={() =>
            endsWith === "close" ? closeFunction() : changeModal("edit-form")
          }
        >
          Cancel
        </button>
        <button
          className="btn btn-success"
          onClick={confirmDelete}
          disabled={loading}
        >
          Delete Address
          {loading && (
            <span className="ml-1 spinner-border spinner-border-sm text-light"></span>
          )}
        </button>
      </div>
    </div>
  );
};

const SelectDefaultAndDelete = ({
  item,
  setSelectedItem,
  changeModal,
  onClose,
}) => {
  const [loading, setLoading] = useState(false);
  const {
    addressBook,
    profile,
    setShowToast,
    setToastData,
    activeAddress,
    updateActiveAddress,
    setAddressBook,
  } = useContext(AppContext);

  const changeSelected = async (addr) => {
    setLoading(true);
    const newAddr = {
      id: addr?.entity_id,
      customer_id: profile?.id,
      firstname: addr?.firstname,
      lastname: ".",
      telephone:
        addr?.telephone.substring(0, 4) === "+971" ||
        addr?.telephone.substring(0, 3) === "971"
          ? addr?.telephone.slice(addr?.telephone.length - 9)
          : addr?.telephone.slice(addr?.telephone.length - 10),
      residence_type: addr?.residence_type || "building",
      building_name: addr?.building_name || "",
      floor_number: addr?.floor_number || "",
      villa_number: addr?.villa_number || "",
      street_number: addr?.street_number || "",
      street: [addr?.street],
      city: addr?.name,
      area_id: addr?.area_id,
      area_name: addr?.area_name,
      zms_city_id: addr?.zms_city_id,
      zone_id: addr?.zone_id,
      zone_name: addr?.zone_name,
      latitude: addr?.latitude,
      longitude: addr?.longitude,
      region: {
        region_code: "UAE",
        region: "UAE",
        region_id: 43,
      },
      region_id: 43,
      custom_attributes: [],
      country_id: "AE",
    };

    newAddr.default_shipping = true;
    newAddr.default_billing = true;

    const custom_attributes = [
      "residence_type",
      "building_name",
      "floor_number",
      "villa_number",
      "street_number",
      "area_name",
      "area_id",
      "zms_city_id",
      "zone_id",
      "latitude",
      "longitude",
      "zone_name",
    ];
    let flatAddr = {
      ...newAddr,
      street: newAddr.street[0],
      mobile_number: checkCustomAttributeValue("mobile_number", profile),
      location: addr?.location,
      entity_id: addr?.entity_id,
    };
    setSelectedItem(flatAddr);

    Object.keys(newAddr).forEach((k) => {
      if (custom_attributes.includes(k)) {
        newAddr.custom_attributes.push({
          attribute_code: k,
          value: newAddr[k],
        });
        delete newAddr[k];
      }
    });

    newAddr.custom_attributes.push({
      attribute_code: "mobile_number",
      value: checkCustomAttributeValue("mobile_number", profile),
    });

    newAddr.custom_attributes.push({
      attribute_code: "location",
      value: addr?.location,
    });
    try {
      await updateAddress({ address: newAddr });
      await deleteAddress(item.entity_id);

      if (activeAddress.entity_id === item.entity_id) {
        updateActiveAddress(flatAddr);
        return window.location.reload();
      }

      setAddressBook((addr) => {
        return addr
          .map((add) => {
            if (add.entity_id === newAddr.id) {
              return { ...add, default_billing: true, default_shipping: true };
            }
            return add;
          })
          .filter((add) => add.entity_id !== item.entity_id);
      });

      setLoading(true);
      changeModal("edit-form");
      setShowToast(true);
      setToastData({
        type: "success",
        text: "Address deleted",
      });
    } catch (error) {
      setShowToast(true);
      setToastData({
        type: "error",
        text: "An error occurred while updating default address",
      });
      setLoading(true);
    }
  };

  return (
    <div className="address-modal-content">
      <div className="address-modal-header">
        <div className="d-flex align-items-center">
          <button
            className="btn btn-light btn-sm mr-1"
            onClick={() => changeModal("list")}
          >
            <FiArrowLeft />
          </button>
          <div className="address-modal-title">
            Select a New Default Address
          </div>
        </div>
        <button className="close-modal-button" onClick={onClose}>
          Close
        </button>
      </div>
      <hr />
      <div className="">
        <div className="address-modal-subtitle">Saved Addresses</div>
        <div className="list-address-row-list">
          {loading ? (
            <Skeleton count={3} />
          ) : (
            addressBook.length > 0 &&
            addressBook
              .filter((a) => !a.default_billing)
              .map((addr) => {
                return (
                  <div className={`list-address-row`} key={addr.entity_id}>
                    <div className="list-address-form-group">
                      <input
                        type="radio"
                        className="list-address-radio"
                        value={addr.entity_id}
                        onChange={() => changeSelected(addr)}
                        id={addr.entity_id}
                      />
                      <label
                        className="list-address-label"
                        htmlFor={addr.entity_id}
                      >
                        <span className="list-address-title">
                          {addr?.firstname}
                        </span>
                        {addr.default_shipping && (
                          <span className="list-address-tag">Default</span>
                        )}

                        <div className="list-address-text">
                          {getAddressString(addr)}
                        </div>
                      </label>
                    </div>
                  </div>
                );
              })
          )}
        </div>
      </div>
    </div>
  );
};

export const AddressDetails = ({
  show,
  onClose,
  startsWith = "list",
  endsWith = "list",
  item,
  initialLocation,
}) => {
  const { latLong } = useContext(AppContext);
  const [currentModal, setCurrentModal] = useState(startsWith);
  const [locationData, setLocationData] = useState({
    lat: parseFloat(latLong?.lat || latLong?.city?.lat),
    lng: parseFloat(latLong?.long || latLong?.city?.long),
  });
  const [selectedItem, setSelectedItem] = useState(null);
  const [isClosable, setIsClosable] = useState(true);
  const [modalSize, setModalSize] = useState("md");
  const [end, setEnd] = useState(endsWith);
  const modalBodyRef = useRef();

  const changeModal = (name) => {
    if (name === "close") {
      onClose();
    }
    setCurrentModal(name);
    modalBodyRef.current.scrollIntoView();
  };

  useEffect(() => {
    if (startsWith) {
      setCurrentModal(startsWith);
    }
  }, [startsWith, show]);

  useEffect(() => {
    setEnd(endsWith);
  }, [endsWith, show]);

  useEffect(() => {
    setSelectedItem(item);
  }, [item]);

  useEffect(() => {
    if (initialLocation) {
      setLocationData(initialLocation);
    }
  }, [initialLocation]);

  useEffect(() => {
    if (currentModal === "add-map") {
      setModalSize("lg");
    } else {
      setModalSize("md");
    }
  }, [currentModal, setModalSize]);

  const closeFunction = () => {
    onClose();
    setCurrentModal(startsWith);
  };

  const modalContent = () => {
    if (currentModal === "new-message") {
      return (
        <NewSystemMessageModal
          changeModal={changeModal}
          setIsClosable={setIsClosable}
        />
      );
    }

    if (currentModal === "default") {
      return (
        <SelectDefaultAddress
          changeModal={changeModal}
          setSelectedItem={setSelectedItem}
          setIsClosable={setIsClosable}
          setLocationData={setLocationData}
          onClose={closeFunction}
        />
      );
    }

    if (currentModal === "add-map") {
      return (
        <AddAddressMap
          locationData={locationData}
          setLocationData={setLocationData}
          changeModal={changeModal}
          onClose={closeFunction}
          end={end}
          setEnd={setEnd}
        />
      );
    }

    if (currentModal === "add-form") {
      return (
        <AddAddressForm
          onClose={closeFunction}
          changeModal={changeModal}
          locationData={locationData}
          endsWith={end}
        />
      );
    }

    if (currentModal === "edit-form") {
      return (
        <EditAddressForm
          item={selectedItem}
          onClose={closeFunction}
          changeModal={changeModal}
          endsWith={end}
          isClosable={isClosable}
          setIsClosable={setIsClosable}
          locationData={locationData}
          setEnd={setEnd}
        />
      );
    }

    if (currentModal === "delete") {
      return (
        <DeleteModal
          item={selectedItem}
          changeModal={changeModal}
          closeFunction={closeFunction}
          endsWith={endsWith}
        />
      );
    }

    if (currentModal === "default-delete-message") {
      return (
        <DefaultDeleteMessageModal
          changeModal={changeModal}
          endsWith={endsWith}
        />
      );
    }

    if (currentModal === "default-and-delete") {
      return (
        <SelectDefaultAndDelete
          changeModal={changeModal}
          item={selectedItem}
          setSelectedItem={setSelectedItem}
          onClose={closeFunction}
        />
      );
    }

    if (currentModal === "confirm-default") {
      return (
        <ConfirmDefaultAddressModal
          changeModal={changeModal}
          item={selectedItem}
          onClose={closeFunction}
          locationData={locationData}
        />
      );
    }

    return (
      <ListAddress
        setSelectedItem={setSelectedItem}
        changeModal={changeModal}
        onClose={closeFunction}
        setEnd={setEnd}
        setIsClosable={setIsClosable}
        setLocationData={setLocationData}
      />
    );
  };

  return (
    <Modal
      show={show}
      onHide={closeFunction}
      animation="false"
      size={modalSize}
      backdrop={!isClosable ? "static" : true}
      centered
    >
      <Modal.Body className="p-0" ref={modalBodyRef}>
        {show && modalContent()}
      </Modal.Body>
    </Modal>
  );
};
