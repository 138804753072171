// dubai=50 = 9052
// sharjah=30 = 9051
// ajman=30

export const minimumPrice =
  process?.env?.REACT_APP_ENV === "production"
    ? {
        9052: 50,
        9051: 30,
      }
    : process?.env?.REACT_APP_ENV === "staging"
    ? { 9052: 50, 9051: 50, 8042: 10, 8085: 30, 8129: 30, 8126: 30, 8133: 30 }
    : {};

export const minimumPriceForFreeShipping =
  process?.env?.REACT_APP_ENV === "production"
    ? {
        9052: 100,
        9051: 50,
      }
    : process?.env?.REACT_APP_ENV === "staging"
    ? { 9052: 100, 9051: 50, 8042: 10, 8085: 50, 8129: 50, 8126: 50, 8133: 50 }
    : {};

// sharja = 50 , 10 = 9051 (including taxes)
// al qa = 100 , 10 = 9052  (including taxes)
