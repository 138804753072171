import moment from "moment";
import React, { Fragment } from "react";
import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { Modal, OverlayTrigger, Popover } from "react-bootstrap";
import TagManager from "react-gtm-module";
import { BsFillInfoCircleFill } from "react-icons/bs";
import {
  FiPlus,
  FiChevronDown,
  FiEdit,
  FiMinus,
  FiChevronUp,
} from "react-icons/fi";
import { useHistory } from "react-router-dom";
import {
  addCoupon,
  addShipping,
  addShippingOrderDetails,
  cardPayment,
  createCart,
  createOrder,
  getAllAddress,
  getCouponInfo,
  getDetailsByCoords,
  getOrder,
  getProductFromSku,
  getSavedCards,
  getTimeSlotsByZone,
  removeCoupon,
} from "../../api";
import SidebarCartItem from "../../components/SidebarCartItem";
import { AppContext } from "../../context/AppContext";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import {
  FaRegCalendarAlt,
  FaMoneyBillAlt,
  FaRegClock,
  FaCcVisa,
  FaCcMastercard,
  FaCcDinersClub,
  FaCcDiscover,
  FaCreditCard,
} from "react-icons/fa";
import { SiAmericanexpress } from "react-icons/si";
import "./Checkout.css";
import { confirmAlert } from "react-confirm-alert";
import ModalPopup from "../../components/ModalPopup/ModalPopup";
import { AddressDetails } from "../../components/AddressDetails/AddressDetails";
import CheckboxSvg from "../../assets/images/checkbox.svg";
import axios from "axios";
import Loader from "react-loader-spinner";
import { RiCoupon3Fill } from "react-icons/ri";
import { MdCancel } from "react-icons/md";

export default function Checkout() {
  const {
    setBreadcrumbs,
    cartData,
    addressBook,
    setAddressBook,
    latLong,
    setShowToast,
    setToastData,
    profile,
    currentStore,
    cartId,
    updateActivePaymentPayload,
    addressLocation,
    inaamData,
    setInaamData,
    couponApplied,
    setCouponApplied,
    initializeCart,
    userSavedCards,
    setUserSavedCards,
    cartUpdated,
    setCartUpdated,
    defaultLocale,
    store,
    activeAddress,
    categories,
    storePrices,
  } = useContext(AppContext);
  const [totals, setTotals] = useState({});
  const [isToggle, setIsToggle] = useState(true);
  const [deliveryNote, setDeliveryNote] = useState("");
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [userSelectedAddress, setUserSelectedAddress] = useState(null);
  const [selectedTimeslotDate, setSelectedTimeslotDate] = useState("today");
  const [selectedTimeslotType, setSelectedTimeslotType] = useState("scheduled");
  const [todayTimeslots, setTodayTimeslots] = useState([]);
  const [tomorrowTimeslots, setTomorrowTimeslots] = useState([]);
  const [selectedTimeslot, setSelectedTimeslot] = useState(null);
  const [step, setStep] = useState("address");
  const [deliveryCords, setDeliveryCords] = useState({});
  const [shippingAddress, setShippingAddress] = useState();
  const [paymentDetails, setPaymentDetails] = useState();
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState();
  const [shownPayment, setShownPayment] = useState();
  const [paymentMethodDetails, setPaymentMethodDetails] = useState();
  const [orderLoading, setOrderLoading] = useState(false);
  const [wasOrderPlaced, setWasOrderPlaced] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [couponWaiting, setCouponWaiting] = useState(false);
  const [couponInvalid, setCouponInvalid] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isNgeniusonlineSelected, setIsNgeniusonlineSelected] = useState(false);
  const [selectedSavedCard, setSelectedSavedCard] = useState({});
  const [selectedSavedCardId, setSelectedSavedCardId] = useState("");
  const [cvv, setCvv] = useState("");
  const [cartTotal, setCartTotal] = useState(0);
  const [addressModalDetails, setAddressModalDetails] = useState({
    show: "",
    startsWith: "",
    endsWith: "",
    item: null,
    initialLocation: null,
  });
  const [timeslotLoading, setTimeslotLoading] = useState(false);
  const [addressLoading, setAddressLoading] = useState(false);
  const [showAddressPickerPopup, setShowAddressPickerPopup] = useState(false);
  const [showTimeslotPickerPopup, setShowTimeslotPickerPopup] = useState(false);
  const [placeOrder, setPlaceOrder] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  console.log("store", store, "storePrices", storePrices);

  const handleToggle = () => {
    setIsToggle(!isToggle);
  };

  const getAddressString = (addr) => {
    let area = addr.area;
    let city = addr.city;
    let street = addr.street;

    const ifIsString = (v, last = false) => {
      if (v) {
        return v + (last ? "" : ", ");
      }
      return "";
    };

    if (addr.residence_type === "villa") {
      let villa_number = addr.villa_number;
      let street_number = addr.street_number;

      return (
        ifIsString(villa_number) +
        ifIsString(street_number) +
        ifIsString(street) +
        ifIsString(area) +
        ifIsString(city, true)
      );
    } else {
      let bulding_name = addr.building_name;
      let flat_number = addr.flat_number;

      return (
        ifIsString(bulding_name) +
        ifIsString(flat_number) +
        ifIsString(street) +
        ifIsString(area) +
        ifIsString(city, true)
      );
    }
  };

  const addressSelect = async (addr) => {
    setAddressLoading(true);
    try {
      const result = await getDetailsByCoords(addr.latitude, addr.longitude);
      if (!result?.data?.id) {
        setToastData({
          type: "error",
          text: "Selected location is not deliverable from this store",
        });
        setShowToast(true);
        setAddressLoading(false);
        return;
      }
      if (result?.data?.zone?.site_id !== store?.id) {
        setToastData({
          type: "error",
          text: "Selected location is not deliverable from this store",
        });
        setShowToast(true);
        setAddressLoading(false);
        return;
      }
      if (!addr?.zone_id) {
        setAddressModalDetails({
          show: true,
          startsWith: "edit-form",
          endsWith: "close",
          item: addr,
          initialLocation: {
            lat: parseFloat(addr.latitude),
            lng: parseFloat(addr.longitude),
            name: addr?.location || result?.data?.name,
            area: {
              id: result?.data?.id,
              name: result?.data?.name,
            },
            zone: {
              id: result?.data?.zone?.id,
              name: result?.data?.zone?.name,
            },
            city: {
              id: result?.data?.city?.id,
              name: result?.data?.city?.name,
            },
            country: addr.country_id || "AE",
          },
        });
        setAddressLoading(false);
        return;
      }
      setSelectedAddress(addr);
      setUserSelectedAddress(addr);
      setStep("slot");
      setDeliveryCords({
        lat: addr?.latitude,
        long: addr?.longitude,
        name: addr?.location,
      });
      setShippingAddress({
        firstname: addr?.firstname,
        lastname: addr?.lastname,
        telephone: addr?.telephone,
        email: profile?.email,
        country_id: addr?.country_id,
        postcode: addr?.postcode || "",
        region_id: 43,
        region: addr?.region?.region,
        city: addr?.city,
        region_code: addr?.region?.region_code,
        street: Array.isArray(addr?.street) ? addr?.street : [addr?.street],
      });
      setPaymentDetails(result?.data?.zone?.zone_order?.min_order_value);
    } catch (error) {
      setToastData({
        type: "error",
        text: "Selected location is not deliverable from this store",
      });
      setShowToast(true);
    }
    setAddressLoading(false);
  };

  const handleProceedToCheckOut = async (
    nextStep = "payment",
    timeSlot = null
  ) => {
    let currentSlot = timeSlot ? timeSlot : selectedTimeslot;
    if (cartData?.filter((x) => x.is_salable !== 1).length > 0) {
      setToastData({
        type: "error",
        text: "Out of stock item(s) in your cart",
      });
      setShowToast(true);
      return 0;
    }
    if (!currentSlot?.day || !currentSlot?.slot) {
      setToastData({
        type: "error",
        text: "Please select slot date and time",
      });
      setShowToast(true);
      return 0;
    }
    setTimeslotLoading(true);
    await addShippingOrderDetails({
      card_id: cartId,
      timeslot_id: currentSlot?.slot?.timeSlotId,
      slot_id: currentSlot?.slot?.id,
      zone_id: selectedAddress.zone_id,
      delivery_date: moment(currentSlot.date).format("YYYY-MM-DD"),
    });
    addShipping(currentStore?.store_code, {
      addressInformation: {
        shipping_address: shippingAddress,
        billing_address: shippingAddress,
        shipping_carrier_code: "tablerate",
        shipping_method_code: "bestway",
      },
    })
      .then((res) => {
        setPaymentMethods(res.payment_methods);
        setTotals(res.totals);
        setStep(nextStep);
        setTimeslotLoading(false);
      })
      .catch((e) => {
        setToastData({
          type: "error",
          text: "Error fetching shipping details",
        });
        setShowToast(true);
        setTimeslotLoading(false);
        setSelectedTimeslot(null);
      });
  };

  const findChildAmongCategories = (arr, childId) => {
    let temp = null;
    arr.forEach((c) => {
      if (c?.id === childId) {
        temp = c?.name;
      }
      if (!temp && c?.children_data.length > 0) {
        temp = findChildAmongCategories(c?.children_data, childId);
      }
    });
    return temp;
  };

  const handlePlaceOrder = (payMethod = paymentMethod) => {
    // if (totals?.base_subtotal + totals?.base_tax_amount < paymentDetails) {
    //   setShowToast(true);
    //   setToastData({
    //     type: "error",
    //     text: `Minimum Cart Value excluding delivery charge should be ${paymentDetails} AED`,
    //   });
    //   return 0;
    // }

    if (payMethod === "ngeniusonline") {
      if (selectedSavedCardId) {
        if (cvv.length > 4 || cvv.length < 3 || cvv.length === 0) {
          setShowToast(true);
          setToastData({
            type: "error",
            text: "Please enter a valid CVV",
          });
          return 0;
        }
      }
    }

    if (payMethod) {
      // process?.env?.REACT_APP_ENV === "production" &&
      //   TagManager.dataLayer({
      //     dataLayerName: "dataLayer",
      //     dataLayer: {
      //       ecommerce: null,
      //     },
      //   });

      // process?.env?.REACT_APP_ENV === "production" &&
      //   TagManager.dataLayer({
      //     dataLayerName: "dataLayer",
      //     dataLayer: {
      //       event: "checkoutOption",
      //       ecommerce: {
      //         checkout_option: {
      //           actionField: { step: 3, option: payMethod },
      //         },
      //       },
      //     },
      //   });
      setOrderLoading(true);
      let prods = [];
      cartData?.forEach((i) => {
        prods.push({
          name: i?.name,
          id: i?.sku,
          price: i?.tax_included_price,
          brand: "",
          category: "",
          variant: "",
          quantity: i?.qty,
        });
      });
      // process?.env?.REACT_APP_ENV === "production" &&
      //   TagManager.dataLayer({
      //     dataLayerName: "dataLayer",
      //     dataLayer: {
      //       ecommerce: null,
      //     },
      //   });

      // process?.env?.REACT_APP_ENV === "production" &&
      //   TagManager.dataLayer({
      //     dataLayerName: "dataLayer",
      //     dataLayer: {
      //       event: "checkout",
      //       ecommerce: {
      //         checkout: {
      //           actionField: {
      //             list: "checkout",
      //             step: 3,
      //             option: payMethod,
      //           },
      //           products: prods,
      //         },
      //       },
      //     },
      //   });
      if (payMethod === "ngeniusonline") {
        localStorage.setItem("paymentMethod", "ngeniusonline");
        updateActivePaymentPayload(
          {
            quote_id: cartId,
            custom_data: {
              address_id: selectedAddress?.entity_id || selectedAddress?.id,
              delivery_date: moment(selectedTimeslot.date).format("YYYY-MM-DD"),
              from: selectedTimeslot?.slot?.delivery_from_time,
              to: selectedTimeslot?.slot?.delivery_to_time,
              lat: deliveryCords?.lat || addressLocation?.lat,
              long: deliveryCords?.long || addressLocation?.long,
              timeslot_id: selectedTimeslot?.slot?.timeSlotId,
              slot_id: selectedTimeslot?.slot?.id,
              slot_type: selectedTimeslot?.type,
              zone_id: selectedAddress?.zone_id,
              store_id: currentStore?.store_id,
              is_substitution: isToggle,
              delivery_notes:
                deliveryNote === ""
                  ? "Placed on Website"
                  : deliveryNote + "-Placed on Website",
            },
          },
          totals
        );
        const redirectUrl =
          process?.env?.REACT_APP_ENV === "production"
            ? `https://gateway.nesto.shop/auth/production_payment/status?env=production&payment=`
            : `https://gateway.nesto.shop/auth/payment/status?env=staging&payment=`;
        const payload = {
          env: process?.env?.REACT_APP_ENV,
          quot_id: cartId,
          currencyCode: totals.base_currency_code,
          value: totals.base_grand_total,
          redirectUrl:
            redirectUrl +
            encodeURIComponent(
              window.location.href.replace("/checkout", "/order-status")
            ),
          sub_total: totals.base_subtotal,
          card_id: payMethod === "ngeniusonline" ? selectedSavedCardId : "",
          used_card: selectedSavedCardId ? true : false,
          masked_pan:
            selectedSavedCard !== {} ? selectedSavedCard.maskedPan : "",
          cvv: cvv,
          delivery_date: moment(selectedTimeslot.date).format("YYYY-MM-DD"),
          from: selectedTimeslot?.slot?.delivery_from_time,
          to: selectedTimeslot?.slot?.delivery_to_time,
          timeslot_id: selectedTimeslot?.slot?.timeSlotId,
          lat: deliveryCords?.lat || addressLocation?.lat,
          long: deliveryCords?.long || addressLocation?.long,
          store_id: currentStore?.store_id,
          is_substitution: isToggle,
          delivery_notes:
            deliveryNote === ""
              ? "Placed on Website"
              : deliveryNote + "-Placed on Website",
        };
        cardPayment(payload)
          .then((res) => {
            setOrderLoading(false);
            if (res?.error) {
              setShowToast(true);
              setToastData({
                type: "error",
                text: res?.msg,
              });
            } else {
              if (res?.reference_id) {
                if (res.payment_link) {
                  window.location.href = res.payment_link;
                } else {
                  window.location.href = `/order-status?ref=${res?.reference_id}`;
                }
              } else {
                setShowToast(true);
                setToastData({
                  type: "error",
                  text: "Something went wrong!",
                });
              }
            }
          })
          .catch(() => {
            setOrderLoading(false);
            setShowToast(true);
            setToastData({
              type: "error",
              text: "Something went wrong!",
            });
          })
          .finally(() => setOrderLoading(false));
      } else {
        localStorage.setItem("paymentMethod", "cashondelivery");
        if (selectedTimeslot?.slot?.id) {
          createOrder(currentStore?.store_code, {
            quote_id: cartId,
            custom_data: {
              address_id: selectedAddress?.entity_id || selectedAddress?.id,
              delivery_date: moment(selectedTimeslot.date).format("YYYY-MM-DD"),
              from: selectedTimeslot?.slot?.delivery_from_time,
              to: selectedTimeslot?.slot?.delivery_to_time,
              lat: deliveryCords?.lat || addressLocation?.lat,
              long: deliveryCords?.long || addressLocation?.long,
              timeslot_id: selectedTimeslot?.slot?.timeSlotId,
              slot_id: selectedTimeslot?.slot?.id,
              slot_type: selectedTimeslot?.type,
              zone_id: selectedAddress?.zone_id,
              store_id: currentStore?.store_id,
              is_substitution: isToggle,
              delivery_notes:
                deliveryNote === ""
                  ? "Placed on Website"
                  : deliveryNote + "-Placed on Website",
            },
            reference_id: "",
            payment_method: "cashondelivery",
          })
            .then((res) => {
              if (res?.error) {
                setShowToast(true);
                setToastData({
                  type: "error",
                  text: res?.msg,
                });
                return 0;
              }
              // process?.env?.REACT_APP_ENV === "production" &&
              //   TagManager.dataLayer({
              //     dataLayerName: "dataLayer",
              //     dataLayer: {
              //       ecommerce: null,
              //     },
              //   });
              let orderProds = [];
              let tempCartData = [];
              let orderData = {};
              getOrder(res?.orderid).then((orderRes) => {
                let skus = orderRes?.items?.map((prod) => prod?.sku);
                getProductFromSku(currentStore?.store_code, skus).then(
                  (skuRes) => {
                    orderRes?.items?.forEach((i) => {
                      let itemCategories = skuRes.filter(
                        (it) => it?.sku === i?.sku
                      )[0]?.category_ids;
                      let currentCategory = "";
                      if (itemCategories.length >= 3) {
                        currentCategory = findChildAmongCategories(
                          categories?.children_data,
                          itemCategories[2]
                        );
                      }
                      orderProds.push({
                        name: i?.name,
                        id: i?.sku,
                        price: i?.price_incl_tax,
                        brand: "",
                        category: "",
                        // item_list_name: currentCategory || "",
                        variant: "",
                        quantity: parseInt(~~i?.qty_ordered),
                      });
                    });
                    console.log("gtagitems", orderProds);
                    window.gtag("event", "purchase", {
                      transaction_id: res?.orderid, //Transaction id is required for ecommerce transactions. The value of this field should be unique to each transaction, and should not change over the lifetime of the transaction.
                      affiliation: "Online Store",
                      value: totals?.base_grand_total, // Total transaction value (incl. tax and shipping)
                      tax: totals?.base_grand_total - totals?.base_subtotal,
                      shipping: totals?.shipping_incl_tax,
                      currency: "AED",
                      coupon: orderRes?.coupon || "",
                      items: orderProds,
                    });
                  }
                );
              });

              // process?.env?.REACT_APP_ENV === "production" &&
              //   TagManager.dataLayer({
              //     dataLayerName: "dataLayer",
              //     dataLayer: {
              //       event: "purchaseCompleted",
              //       ecommerce: {
              //         purchase: {
              //           actionField: {
              //             id: res?.orderid, // Transaction ID. Required for purchases and refunds.
              //             affiliation: "Online Store",
              //             revenue: totals?.base_grand_total, // Total transaction value (incl. tax and shipping)
              //             tax: totals?.base_grand_total - totals?.base_subtotal,
              //             shipping: totals?.shipping_incl_tax,
              //             coupon: "",
              //           },
              //           products: orderProds,
              //         },
              //       },
              //     },
              //   });
              setWasOrderPlaced(true);
              createCart(currentStore?.store_code).then((cartres) => {
                initializeCart({ id: cartres, items: [] });
                history.push(`/order-status/order-placed/${res?.orderid}`);
              });
            })
            .catch((e) => history.push("/order-status/order-failed"))
            .finally(() => setOrderLoading(false));
        }
      }
    } else {
      localStorage.setItem("paymentMethod", null);
      setShowToast(true);
      setToastData({
        type: "info",
        text: "Select a payment method",
      });
    }
  };

  // useEffect(() => {
  //   axios
  //     .get("https://staging.nesto.shop/rest/V1/customapi/categoriestree", {
  //       params: {
  //         store_id: 1,
  //         cron: 0,
  //       },
  //     })
  //     .then((res) => {
  //       console.log(res);
  //     });
  // }, []);

  const savedCardClickHandler = (e) => {
    setSelectedSavedCardId(e.target.value);
    setCvv("");
    const card = userSavedCards.find((x) => x.id === e.target.value);
    setSelectedSavedCard(card);
  };

  const getCardIcon = (scheme) => {
    switch (scheme) {
      case "VISA":
        return <FaCcVisa size={"25px"} />;
      case "MASTERCARD":
        return <FaCcMastercard size={"25px"} />;
      case "DINERS":
        return <FaCcDinersClub size={"25px"} />;
      case "DISCOVER":
        return <FaCcDiscover size={"25px"} />;
      case "AMEX":
        return <SiAmericanexpress size={"25px"} />;
      default:
        return <FaCcVisa size={"25px"} />;
    }
  };

  const handleAddCoupon = (couponcode) => {
    if (!selectedTimeslot) {
      setShowToast(true);
      setToastData({
        type: "error",
        text: `Please select timeslot before adding coupon`,
      });
      return 0;
    }
    if (
      totals?.base_subtotal + totals?.base_tax_amount <
      storePrices?.min_order_value
    ) {
      setShowToast(true);
      setToastData({
        type: "error",
        text: `Minimum amount should be AED ${storePrices?.min_order_value}.`,
      });
      return 0;
    }
    if (couponcode !== "") {
      setCouponWaiting(true);
      getCouponInfo(couponcode, currentStore?.store_code)
        .then((res) => {
          if (res[0].error) {
            setCouponInvalid(true);
            setShowToast(true);
            setToastData({
              type: "error",
              text: res[0].message,
            });
            setCouponWaiting(false);
          } else {
            if (
              res[0].minCartValue <=
              totals?.base_subtotal + totals?.base_tax_amount
            ) {
              addCoupon(couponcode, currentStore?.store_code)
                .then((addRes) => {
                  if (addRes) {
                    setCouponApplied(true);
                    handleProceedToCheckOut();
                    setShowToast(true);
                    setToastData({
                      type: "success",
                      text: "Coupon code applied successfully",
                    });
                    setCartUpdated(!cartUpdated);
                    setCouponInvalid(false);
                  } else {
                    setShowToast(true);
                    setToastData({
                      type: "error",
                      text: "The coupon code entered is not valid!",
                    });
                    setCouponInvalid(true);
                  }
                })
                .catch((e) => {
                  setShowToast(true);
                  setToastData({
                    type: "error",
                    text: e.message,
                  });
                  setCouponInvalid(true);
                })
                .finally(() => setCouponWaiting(false));
            } else {
              setShowToast(true);
              setToastData({
                type: "info",
                text: `To apply this coupon minimum amount (Excl VAT) should be AED ${res[0].minCartValue}`,
              });
              setCouponWaiting(false);
            }
          }
        })
        .catch((e) => {
          setShowToast(true);
          setToastData({
            type: "error",
            text: e.message,
          });
          setCouponInvalid(true);
          setCouponWaiting(false);
        });
    }
  };

  /**coupon removal */
  const handleRemoveCoupon = () => {
    if (couponCode !== "") {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="react-confirm-alert-body confirm-popup-button-body">
              <h2 className="fw-700">
                {defaultLocale?.DO_YOU_WANT_TO_REMOVE_THIS_COUPON}
              </h2>
              <div>{defaultLocale?.COUPON_REMOVAL_ALERT_MSG}</div>
              <div className="btn-group d-flex justify-content-end">
                <button
                  className={"confirm-popup-button confirm-button-delete"}
                  onClick={() => {
                    removeCoupon(currentStore?.store_code)
                      .then((res) => {
                        if (res) {
                          setCouponApplied(false);
                          setCouponCode("");
                          handleProceedToCheckOut();
                          setShowToast(true);
                          setToastData({
                            type: "success",
                            text: "Coupon code removed!",
                          });
                          setCartUpdated(!cartUpdated);
                        }
                      })
                      .catch((e) => {
                        setShowToast(true);
                        setToastData({
                          type: "error",
                          text: e.message,
                        });
                      });

                    onClose();
                  }}
                >
                  {defaultLocale?.REMOVE}
                </button>
                <button
                  className={"cancel-popup-button cancel-button-delete"}
                  onClick={onClose}
                >
                  {defaultLocale?.NO}
                </button>
              </div>
            </div>
          );
        },
        closeOnClickOutside: true,
      });
    }
  };

  const calcCartTotal = () => {
    let sum = 0;
    cartData.forEach((v) => {
      sum = sum + parseFloat(v.price * v.qty);
    });
    setCartTotal(Math.round((sum + Number.EPSILON) * 100) / 100);
  };

  const setNewLocationData = (addr) => {
    return {
      lat: parseFloat(addr.latitude),
      lng: parseFloat(addr.longitude),
      name: addr?.location,
      area: {
        id: addr.area_id,
        name: addr.area_name,
      },
      zone: {
        id: addr.zone_id,
        name: addr.zone_name,
      },
      city: {
        id: addr.zms_city_id,
        name: addr.city,
      },
      country: addr.country_id,
    };
  };

  const AddressPickerPopup = ({ show, onClose }) => {
    return (
      <Modal show={show} onHide={onClose} animation="false" size="sm" centered>
        <Modal.Body className="px-1 py-2">
          {addressBook.map((addr) => {
            return (
              <div
                className={`address-modal-box position-relative ${
                  userSelectedAddress?.entity_id === addr.entity_id &&
                  "selected-address"
                }`}
                key={addr.entity_id}
              >
                {addressLoading && (
                  <div className="full-size-fixed-spinner">
                    <span className="ml-1 spinner-border spinner-border-sm text-light"></span>
                  </div>
                )}
                <div className="d-flex align-items-center justify-content-between">
                  <div className="">
                    <div
                      className=""
                      onClick={() => {
                        setSelectedAddress(addr);
                        setSelectedAddress(addr);
                        onClose();
                        setStep("slot");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <span className="font-weight-bold">{addr.firstname}</span>
                      {addr.is_default && (
                        <span className="text-success">Default</span>
                      )}

                      <div className="faded-text mb-2 mt-1">
                        {getAddressString(addr)}
                      </div>
                    </div>
                  </div>
                  <div
                    className="btn"
                    onClick={() => {
                      setAddressModalDetails({
                        show: true,
                        startsWith: "edit-form",
                        endsWith: "close",
                        item: addr,
                        initialLocation: setNewLocationData(addr),
                      });
                    }}
                  >
                    <FiEdit />
                  </div>
                </div>
              </div>
            );
          })}
        </Modal.Body>
        <Modal.Footer className="pt-0 justify-content-start border-0">
          <button
            className="btn btn-line-success mt-3"
            type="button"
            onClick={() =>
              setAddressModalDetails({
                show: true,
                startsWith: "add-map",
                endsWith: "add-form",
              })
            }
          >
            <FiPlus /> Add a New Address
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

  const TimeslotPickerPopup = ({
    show,
    onClose,
    selectedTimeslotType,
    selectedTimeSlot,
    selectedTimeslotDate,
  }) => {
    const [tsType, setTsType] = useState();
    const [ts, setTs] = useState();

    useEffect(() => {
      setTsType(selectedTimeslotType);
    }, [selectedTimeslotType]);

    useEffect(() => {
      setTs(selectedTimeSlot);
    }, [selectedTimeSlot]);

    useEffect(() => {
      if (
        placeOrder &&
        ts?.slot?.id === selectedTimeSlot?.slot?.id &&
        ts?.day === selectedTimeSlot?.day
      ) {
        console.log("should order");
        handleProceedToCheckOut("order");
        setPlaceOrder(false);
      }
    }, [ts, selectedTimeSlot]);
    return (
      <Modal show={show} onHide={onClose} animation={false} centered>
        <Modal.Header className="justify-content-start">
          <div className="form-check mr-3">
            <input
              type="radio"
              className="form-check-input green-accent"
              value="scheduled"
              id="standard-mob"
              checked={tsType === "scheduled"}
              onChange={(e) => setTsType(e.target.value)}
            />
            <label htmlFor="standard-mob" className="form-check-label fw-500">
              Standard
            </label>
          </div>
          <div className="form-check">
            <input
              type="radio"
              className="form-check-input green-accent"
              value="express"
              id="express-mob"
              checked={tsType === "express"}
              onChange={(e) => setTsType(e.target.value)}
            />
            <label htmlFor="express-mob" className="form-check-label fw-500">
              Express
            </label>
          </div>
        </Modal.Header>
        <Modal.Body className="checkout-page mb-0">
          <div
            className="mb-3 d-flex flex-wrap"
            style={{ gap: "20px", maxWidth: "410px" }}
          >
            {selectedTimeslotDate === "today" && tsType === "scheduled" ? (
              todayTimeslots?.normalSlot &&
              todayTimeslots?.normalSlot.length > 0 ? (
                todayTimeslots?.normalSlot.map((v) => {
                  return (
                    <div className="" key={v.id}>
                      <button
                        className={`${
                          ts?.day === "today" && ts?.slot?.id === v.id
                            ? "timeslot-badge"
                            : "btn timeslot-btn"
                        }`}
                        type="button"
                        onClick={() => {
                          let item = {
                            slot: v,
                            date: moment(),
                            day: "today",
                            type: "scheduled",
                          };
                          setTs(item);
                          setSelectedTimeslot(item);
                          setSelectedTimeslotType(tsType);
                          setPlaceOrder(true);
                          onClose();
                        }}
                      >{`${v.delivery_from_time.slice(
                        0,
                        -3
                      )} - ${v.delivery_to_time.slice(0, -3)}`}</button>
                    </div>
                  );
                })
              ) : (
                <div>No Available timeslots</div>
              )
            ) : null}
            {selectedTimeslotDate === "today" && tsType === "express" ? (
              todayTimeslots?.expressSlot &&
              todayTimeslots?.expressSlot.length > 0 ? (
                todayTimeslots?.expressSlot.map((v) => {
                  return (
                    <div className="" key={v.id}>
                      <button
                        className={`${
                          ts?.day === "today" && ts?.slot?.id === v.id
                            ? "timeslot-badge"
                            : "btn timeslot-btn"
                        }`}
                        type="button"
                        onClick={() => {
                          let item = {
                            slot: v,
                            date: moment(),
                            day: "today",
                            type: "express",
                          };
                          setTs(item);
                          setSelectedTimeslot(item);
                          setSelectedTimeslotType(tsType);
                          setPlaceOrder(true);
                          onClose();
                        }}
                      >{`${v.delivery_from_time.slice(
                        0,
                        -3
                      )} - ${v.delivery_to_time.slice(0, -3)}`}</button>
                    </div>
                  );
                })
              ) : (
                <div>No Available timeslots</div>
              )
            ) : null}
            {selectedTimeslotDate === "tomorrow" && tsType === "scheduled" ? (
              tomorrowTimeslots?.normalSlot &&
              tomorrowTimeslots?.normalSlot.length > 0 ? (
                tomorrowTimeslots?.normalSlot.map((v) => {
                  return (
                    <div className="" key={v.id}>
                      <button
                        className={`${
                          ts?.day === "tomorrow" && ts?.slot?.id === v.id
                            ? "timeslot-badge"
                            : "btn timeslot-btn"
                        }`}
                        type="button"
                        onClick={() => {
                          let item = {
                            slot: v,
                            date: moment().add(1, "days"),
                            day: "tomorrow",
                            type: "scheduled",
                          };
                          setTs(item);
                          setSelectedTimeslot(item);
                          setSelectedTimeslotType(tsType);
                          setPlaceOrder(true);
                          onClose();
                        }}
                      >{`${v.delivery_from_time.slice(
                        0,
                        -3
                      )} - ${v.delivery_to_time.slice(0, -3)}`}</button>
                    </div>
                  );
                })
              ) : (
                <div>No Available timeslots</div>
              )
            ) : null}
            {selectedTimeslotDate === "tomorrow" && tsType === "express" ? (
              tomorrowTimeslots?.expressSlot &&
              tomorrowTimeslots?.expressSlot.length > 0 ? (
                tomorrowTimeslots?.expressSlot.map((v) => {
                  return (
                    <div className="" key={v.id}>
                      <button
                        className={`${
                          ts?.day === "tomorrow" && ts?.slot?.id === v.id
                            ? "timeslot-badge"
                            : "btn timeslot-btn"
                        }`}
                        type="button"
                        onClick={() => {
                          let item = {
                            slot: v,
                            date: moment().add(1, "days"),
                            day: "tomorrow",
                            type: "express",
                          };
                          setTs(item);
                          setSelectedTimeslot(item);
                          setSelectedTimeslotType(tsType);
                          setPlaceOrder(true);
                          onClose();
                        }}
                      >{`${v.delivery_from_time.slice(
                        0,
                        -3
                      )} - ${v.delivery_to_time.slice(0, -3)}`}</button>
                    </div>
                  );
                })
              ) : (
                <div>No Available timeslots</div>
              )
            ) : null}
          </div>
        </Modal.Body>
        {/* <Modal.Footer className="pt-0 border-0 justify-content-start">
          <button
            className="btn btn-outline-success"
            type="button"
            onClick={() => {
              setSelectedTimeslot(ts);
              setSelectedTimeslotType(tsType);
              setPlaceOrder(true);
              onClose();
            }}
            disabled={timeslotLoading}
          >
            Select Time and Date
            {timeslotLoading && (
              <span className="ml-1 spinner-border spinner-border-sm text-light"></span>
            )}
          </button>
        </Modal.Footer> */}
      </Modal>
    );
  };

  useEffect(() => {
    calcCartTotal();
  }, [cartData]);

  useEffect(() => {
    setBreadcrumbs([
      { title: "Home", path: "/" },
      { title: "Cart", path: "/cart" },
      { title: "Checkout" },
    ]);
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        const result = await getAllAddress();
        setAddressBook(result[0].addresses);
      } catch (error) {
        console.log(error);
        setToastData({
          type: "error",
          text: "error fetching address",
        });
        setShowToast(true);
      }
    };
    addressBook.length === 0 && getData();
  }, []);

  useEffect(() => {
    const chooseAddressAuto = () => {
      if (activeAddress) {
        addressSelect(activeAddress);
        setStep("slot");
      } else {
        let addr = addressBook.filter((a) => a.default_shipping === true);
        addressSelect(addr[0]);
        setStep("slot");
      }
    };
    addressBook.length > 0 && chooseAddressAuto();
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        const result = await getTimeSlotsByZone(latLong?.zone?.id);
        setTodayTimeslots(result.data.today);
        setTomorrowTimeslots(result.data.tomorrow);
      } catch (error) {
        console.log(error);
        setToastData({
          type: "error",
          text: "error fetching timeslots",
        });
        setShowToast(true);
      }
    };

    getData();
  }, [latLong?.zone]);

  useEffect(() => {
    if (
      !wasOrderPlaced &&
      paymentMethods &&
      totals &&
      selectedAddress &&
      selectedTimeslot
    ) {
      handleProceedToCheckOut();
    }

    if (!wasOrderPlaced && cartData?.length < 1) {
      history.push("/cart");
    }
  }, [cartData]);

  useEffect(() => {
    setSelectedSavedCardId(userSavedCards[0]?.id);
  }, [userSavedCards]);

  useEffect(() => {
    selectedSavedCardId
      ? localStorage.setItem("used_card", true)
      : localStorage.setItem("used_card", false);
  }, [selectedSavedCardId]);

  useEffect(() => {
    const getUniqueSavedCards = (cards) => {
      const flag = {};
      const uniqueCards = [];
      cards.forEach((card) => {
        if (!flag[card?.maskedPan]) {
          flag[card?.maskedPan] = true;
          uniqueCards.push(card);
        }
      });
      return uniqueCards;
    };
    getSavedCards()
      .then((res) => JSON.parse(res))
      .then((res) => {
        const uniqueCards = getUniqueSavedCards(res?.data);
        setUserSavedCards([...uniqueCards]);
      })
      .catch((err) => console.log(err));
  }, []);

  useDocumentTitle("Checkout - Nesto Online Shopping");

  return (
    <div className="checkout-page container">
      <AddressDetails
        show={addressModalDetails.show}
        onClose={() => setAddressModalDetails((d) => ({ ...d, show: false }))}
        startsWith={addressModalDetails.startsWith}
        endsWith={addressModalDetails.endsWith}
        item={addressModalDetails.item}
        initialLocation={addressModalDetails.initialLocation}
      />
      <AddressPickerPopup
        show={showAddressPickerPopup}
        onClose={() => setShowAddressPickerPopup(false)}
      />
      <TimeslotPickerPopup
        show={showTimeslotPickerPopup}
        onClose={() => setShowTimeslotPickerPopup(false)}
        selectedTimeslotType={selectedTimeslotType}
        selectedTimeSlot={selectedTimeslot}
        selectedTimeslotDate={selectedTimeslotDate}
      />
      {(timeslotLoading || orderLoading) && (
        <div className="full-size-fixed-spinner d-flex align-items-center justify-content-center">
          <span className="ml-1 spinner-border spinner-border-sm text-success"></span>
        </div>
      )}
      <div className="content px-md-2 d-md-flex justify-content-between">
        <div className="menu mb-3 md-mb-0">
          <h2 className="heading">Checkout</h2>
          <hr />
          <div className="step-box">
            <div className="step-name d-none d-md-block">01</div>
            <div className="step-details w-100" id="step-address">
              {step !== "address" ? (
                <div className="step-details-mini">
                  <div>
                    <p className="text-sm">
                      <span>Delivery Address</span>
                      {selectedAddress && (
                        <span className="ml-2">
                          <img src={CheckboxSvg} alt="" />
                        </span>
                      )}
                    </p>
                    {selectedAddress && (
                      <div>
                        <span className="font-weight-bold">
                          {selectedAddress.firstname}
                        </span>
                        {selectedAddress.is_default && (
                          <span className="text-success">Default</span>
                        )}

                        <div className="faded-text mb-2">
                          {getAddressString(selectedAddress)}
                        </div>
                      </div>
                    )}
                  </div>
                  <div>
                    <button
                      className="btn btn-line-success"
                      type="button"
                      onClick={() => setStep("address")}
                    >
                      <span className="d-none d-md-block">Edit</span>
                      <span className="d-block d-md-none">
                        {step === "address" ? <FiMinus /> : <FiPlus />}
                      </span>
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <div className="step-details-full">
                    <div className="d-flex align-items-center justify-content-between">
                      <p className="text-sm">Select Delivery Address</p>
                      <button
                        className="btn btn-line-success d-inline-block d-md-none"
                        type="button"
                        onClick={() => setStep(null)}
                      >
                        <FiMinus />
                      </button>
                    </div>
                    <div className="d-md-none">
                      <div className="faded-text mb-1">Delivery Address</div>
                      <button
                        className="select-button"
                        onClick={() => setShowAddressPickerPopup(true)}
                      >
                        <span>
                          {selectedAddress
                            ? `${selectedAddress.firstname}${
                                selectedAddress.default_shipping
                                  ? "- Default"
                                  : ""
                              }`
                            : "Select Address"}
                        </span>
                        <span>
                          <FiChevronDown />
                        </span>
                      </button>
                    </div>
                    <div className="d-none d-md-block">
                      <div className="address-list">
                        {addressBook &&
                          addressBook.length > 0 &&
                          addressBook.map((addr) => {
                            return (
                              <div
                                className={`address-box ${
                                  userSelectedAddress?.entity_id ===
                                    addr.entity_id && "selected-address"
                                }`}
                                key={addr.entity_id}
                              >
                                <div className="d-flex align-items-center justify-content-between">
                                  <div
                                    onClick={() => setUserSelectedAddress(addr)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <span className="font-weight-bold">
                                      {addr.firstname}
                                    </span>
                                    {addr.is_default && (
                                      <span className="text-success">
                                        Default
                                      </span>
                                    )}

                                    <div className="faded-text mb-2">
                                      {getAddressString(addr)}
                                    </div>
                                  </div>
                                  {userSelectedAddress?.entity_id ===
                                    addr.entity_id && (
                                    <div>
                                      <button
                                        className="btn"
                                        onClick={() => {
                                          setAddressModalDetails({
                                            show: true,
                                            startsWith: "edit-form",
                                            endsWith: "close",
                                            item: addr,
                                            initialLocation:
                                              setNewLocationData(addr),
                                          });
                                        }}
                                      >
                                        Edit
                                      </button>
                                    </div>
                                  )}
                                </div>

                                {userSelectedAddress?.entity_id ===
                                  addr.entity_id && (
                                  <button
                                    className="btn btn-outline-success"
                                    type="button"
                                    onClick={() => addressSelect(addr)}
                                    disabled={addressLoading}
                                  >
                                    Deliver Here
                                    {addressLoading && (
                                      <span className="ml-1 spinner-border spinner-border-sm text-light"></span>
                                    )}
                                  </button>
                                )}
                              </div>
                            );
                          })}
                      </div>
                      <button
                        className="btn btn-line-success mt-3"
                        type="button"
                        onClick={() =>
                          setAddressModalDetails({
                            show: true,
                            startsWith: "add-map",
                            endsWith: "add-form",
                          })
                        }
                      >
                        <FiPlus /> Add a New Address
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <hr />
          <div className="step-box">
            <div className="step-name d-none d-md-block">02</div>
            <div className="step-details w-100" id="step-timeslot">
              {step !== "slot" ? (
                <div className="step-details-mini">
                  <div>
                    <p className="text-sm">
                      <span>Delivery Timeslot</span>
                      {selectedTimeslot && (
                        <span className="ml-2">
                          <img src={CheckboxSvg} alt="" />
                        </span>
                      )}
                    </p>
                    {selectedTimeslot && (
                      <div>
                        <p style={{ textTransform: "capitalize" }}>{`${
                          selectedTimeslot.day
                        }, ${selectedTimeslot.slot.delivery_from_time.slice(
                          0,
                          -3
                        )} - ${selectedTimeslot.slot.delivery_to_time.slice(
                          0,
                          -3
                        )}`}</p>
                      </div>
                    )}
                  </div>
                  {selectedAddress && (
                    <div>
                      <button
                        className="btn btn-line-success"
                        type="button"
                        onClick={() => setStep("slot")}
                      >
                        <span className="d-none d-md-block">Edit</span>
                        <span className="d-block d-md-none">
                          {step === "slot" ? <FiMinus /> : <FiPlus />}
                        </span>
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                <div className="step-details-full">
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="text-sm">Select Delivery Timeslot</p>
                    <button
                      className="btn btn-line-success d-inline-block d-md-none"
                      type="button"
                      onClick={() => setStep(null)}
                    >
                      <FiMinus />
                    </button>
                  </div>
                  <div className="d-md-none">
                    <div className="mb-3">
                      <div className="faded-text mb-1">Delivery Date</div>
                      <select
                        className="form-control custom-arrow"
                        value={selectedTimeslotDate}
                        onChange={(e) =>
                          setSelectedTimeslotDate(e.target.value)
                        }
                        style={{ padding: "12px 16px", height: "auto" }}
                      >
                        <option value="today">
                          Today, {moment().format("DD/MM/yyyy")}
                        </option>
                        <option value="tomorrow">
                          Tomorrow,{" "}
                          {moment().add(1, "days").format("DD/MM/yyyy")}
                        </option>
                      </select>
                    </div>
                    <div className="">
                      <div className="faded-text mb-1">Select Timeslot</div>
                      <button
                        className="select-button"
                        onClick={() => setShowTimeslotPickerPopup(true)}
                      >
                        <span>
                          {selectedTimeslot
                            ? `${selectedTimeslot.slot.delivery_from_time.slice(
                                0,
                                -3
                              )} - ${selectedTimeslot.slot.delivery_to_time.slice(
                                0,
                                -3
                              )}`
                            : "Select a timeslot"}
                        </span>
                        <span>
                          <FiChevronDown />
                        </span>
                      </button>
                    </div>
                  </div>
                  <div className="d-none d-md-block">
                    <div className="d-flex align-items-center flex-wrap form-group">
                      <label className="mr-sm-2 mb-sm-0">Delivery Date:</label>
                      <select
                        className="form-control"
                        style={{ width: "auto", minWidth: "200px" }}
                        value={selectedTimeslotDate}
                        onChange={(e) =>
                          setSelectedTimeslotDate(e.target.value)
                        }
                      >
                        <option value="today">
                          Today, {moment().format("DD/MM/yyyy")}
                        </option>
                        <option value="tomorrow">
                          Tomorrow,{" "}
                          {moment().add(1, "days").format("DD/MM/yyyy")}
                        </option>
                      </select>
                    </div>
                    <div className="d-flex align-items-start mb-3 flex-column flex-md-row align-items-md-center">
                      <p className="mb-0 mr-3 font-weight-bold">
                        Delivery Timeslots
                      </p>
                      <div className="form-check mr-3">
                        <input
                          type="radio"
                          className="form-check-input green-accent"
                          value="scheduled"
                          id="standard"
                          checked={selectedTimeslotType === "scheduled"}
                          onChange={(e) =>
                            setSelectedTimeslotType(e.target.value)
                          }
                        />
                        <label htmlFor="standard" className="form-check-label">
                          Standard
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="radio"
                          className="form-check-input green-accent"
                          value="express"
                          id="express"
                          checked={selectedTimeslotType === "express"}
                          onChange={(e) =>
                            setSelectedTimeslotType(e.target.value)
                          }
                        />
                        <label htmlFor="express" className="form-check-label">
                          Express
                        </label>
                      </div>
                    </div>
                    <div
                      className="mb-3 d-flex flex-wrap"
                      style={{ gap: "20px", maxWidth: "410px" }}
                    >
                      {selectedTimeslotDate === "today" &&
                      selectedTimeslotType === "scheduled" ? (
                        todayTimeslots?.normalSlot &&
                        todayTimeslots?.normalSlot.length > 0 ? (
                          todayTimeslots?.normalSlot.map((v) => {
                            return (
                              <div className="" key={v.id}>
                                <button
                                  className={`${
                                    selectedTimeslot?.day === "today" &&
                                    selectedTimeslot?.slot?.id === v.id
                                      ? "timeslot-badge"
                                      : "btn timeslot-btn"
                                  }`}
                                  type="button"
                                  onClick={() => {
                                    let item = {
                                      slot: v,
                                      date: moment(),
                                      day: "today",
                                      type: "scheduled",
                                    };
                                    setSelectedTimeslot(item);
                                    handleProceedToCheckOut("payment", item);
                                  }}
                                >{`${v.delivery_from_time.slice(
                                  0,
                                  -3
                                )} - ${v.delivery_to_time.slice(
                                  0,
                                  -3
                                )}`}</button>
                              </div>
                            );
                          })
                        ) : (
                          <div>No Available timeslots</div>
                        )
                      ) : null}
                      {selectedTimeslotDate === "today" &&
                      selectedTimeslotType === "express" ? (
                        todayTimeslots?.expressSlot &&
                        todayTimeslots?.expressSlot.length > 0 ? (
                          todayTimeslots?.expressSlot.map((v) => {
                            return (
                              <div className="" key={v.id}>
                                <button
                                  className={`${
                                    selectedTimeslot?.day === "today" &&
                                    selectedTimeslot?.slot?.id === v.id
                                      ? "timeslot-badge"
                                      : "btn timeslot-btn"
                                  }`}
                                  type="button"
                                  onClick={() => {
                                    let item = {
                                      slot: v,
                                      date: moment(),
                                      day: "today",
                                      type: "express",
                                    };
                                    setSelectedTimeslot(item);
                                    handleProceedToCheckOut("payment", item);
                                  }}
                                >{`${v.delivery_from_time.slice(
                                  0,
                                  -3
                                )} - ${v.delivery_to_time.slice(
                                  0,
                                  -3
                                )}`}</button>
                              </div>
                            );
                          })
                        ) : (
                          <div>No Available timeslots</div>
                        )
                      ) : null}
                      {selectedTimeslotDate === "tomorrow" &&
                      selectedTimeslotType === "scheduled" ? (
                        tomorrowTimeslots?.normalSlot &&
                        tomorrowTimeslots?.normalSlot.length > 0 ? (
                          tomorrowTimeslots?.normalSlot.map((v) => {
                            return (
                              <div className="" key={v.id}>
                                <button
                                  className={`${
                                    selectedTimeslot?.day === "tomorrow" &&
                                    selectedTimeslot?.slot?.id === v.id
                                      ? "timeslot-badge"
                                      : "btn timeslot-btn"
                                  }`}
                                  type="button"
                                  onClick={() => {
                                    let item = {
                                      slot: v,
                                      date: moment().add(1, "days"),
                                      day: "tomorrow",
                                      type: "scheduled",
                                    };
                                    setSelectedTimeslot(item);
                                    handleProceedToCheckOut("payment", item);
                                  }}
                                >{`${v.delivery_from_time.slice(
                                  0,
                                  -3
                                )} - ${v.delivery_to_time.slice(
                                  0,
                                  -3
                                )}`}</button>
                              </div>
                            );
                          })
                        ) : (
                          <div>No Available timeslots</div>
                        )
                      ) : null}
                      {selectedTimeslotDate === "tomorrow" &&
                      selectedTimeslotType === "express" ? (
                        tomorrowTimeslots?.expressSlot &&
                        tomorrowTimeslots?.expressSlot.length > 0 ? (
                          tomorrowTimeslots?.expressSlot.map((v) => {
                            return (
                              <div className="" key={v.id}>
                                <button
                                  className={`${
                                    selectedTimeslot?.day === "tomorrow" &&
                                    selectedTimeslot?.slot?.id === v.id
                                      ? "timeslot-badge"
                                      : "btn timeslot-btn"
                                  }`}
                                  type="button"
                                  onClick={() => {
                                    let item = {
                                      slot: v,
                                      date: moment().add(1, "days"),
                                      day: "tomorrow",
                                      type: "express",
                                    };
                                    setSelectedTimeslot(item);
                                    handleProceedToCheckOut("payment", item);
                                  }}
                                >{`${v.delivery_from_time.slice(
                                  0,
                                  -3
                                )} - ${v.delivery_to_time.slice(
                                  0,
                                  -3
                                )}`}</button>
                              </div>
                            );
                          })
                        ) : (
                          <div>No Available timeslots</div>
                        )
                      ) : null}
                    </div>
                    {/* <button
                      className="btn btn-outline-success d-none d-md-block"
                      type="button"
                      onClick={() => handleProceedToCheckOut()}
                      disabled={timeslotLoading}
                    >
                      Select Time and Date
                      {timeslotLoading && (
                        <span className="ml-1 spinner-border spinner-border-sm text-light"></span>
                      )}
                    </button> */}
                  </div>
                </div>
              )}
            </div>
          </div>
          <hr />
          <div className="d-block d-md-none step-box">
            <div className="step-details w-100" id="step-order">
              {step !== "order" ? (
                <div className="step-details-mini">
                  <div>
                    <p className="text-sm">
                      <span>Order Details</span>
                      {selectedAddress && selectedTimeslot && (
                        <span className="ml-2">
                          <img src={CheckboxSvg} alt="" />
                        </span>
                      )}
                    </p>
                    <p className="">{cartData.length} items in cart</p>
                  </div>
                  {selectedAddress && selectedTimeslot && (
                    <button
                      className="btn btn-line-success"
                      type="button"
                      onClick={() => setStep("order")}
                    >
                      <span className="d-none d-md-block">Edit</span>
                      <span className="d-block d-md-none">
                        {step === "order" ? <FiMinus /> : <FiPlus />}
                      </span>
                    </button>
                  )}
                </div>
              ) : (
                <div className="step-details-full">
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="text-sm">Edit Order Details</p>
                    <button
                      className="btn btn-line-success d-inline-block d-md-none"
                      type="button"
                      onClick={() => setStep(null)}
                    >
                      <FiMinus />
                    </button>
                  </div>
                  <hr />
                  {cartData?.map((item, index) => {
                    return (
                      <SidebarCartItem
                        key={`sidebar-cart-item-${index}-${item?.item_id}`}
                        cartData={item}
                      />
                    );
                  })}
                  <hr />
                  <div>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <p className="m-0 d-flex align-items-center">
                        <span className="mr-2">Allow Substitutions</span>
                        <OverlayTrigger
                          trigger={["hover", "focus"]}
                          placement="bottom"
                          overlay={
                            <Popover id="popover-basic">
                              <Popover.Title as="h3" className="text-center">
                                Substitutions
                              </Popover.Title>
                              <Popover.Content>
                                When enabled, if you miss the substitution
                                suggestion time limit of 10 minutes, Nesto will
                                automatically allocate the next best substitute
                                for any out-of-stock items.
                              </Popover.Content>
                            </Popover>
                          }
                        >
                          <BsFillInfoCircleFill />
                        </OverlayTrigger>
                      </p>

                      <button
                        className={
                          "d-flex align-items-center toggle-switch" +
                          (isToggle ? " on" : "")
                        }
                        onClick={handleToggle}
                      >
                        <div className="toggle-indicator"></div>
                      </button>
                    </div>
                    <div className="form-group">
                      <textarea
                        className="form-control"
                        placeholder="Delivery Note"
                        onChange={(e) => setDeliveryNote(e.target.value)}
                        value={deliveryNote}
                      ></textarea>
                    </div>
                    <div className="form-group">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          style={{
                            borderColor:
                              couponCode !== "" && couponInvalid
                                ? "red"
                                : "green",
                          }}
                          class="form-control p-4"
                          aria-label={defaultLocale?.COUPON_PLACEHOLDER}
                          placeholder={defaultLocale?.COUPON_PLACEHOLDER}
                          maxLength="15"
                          value={couponCode}
                          onFocus={() => setCouponInvalid(false)}
                          onChange={(e) => setCouponCode(e.target.value)}
                        />
                        <div className="input-group-append">
                          <button
                            type="button"
                            className="btn btn-outline-success"
                            onClick={() =>
                              !isLoading && handleAddCoupon(couponCode)
                            }
                          >
                            {couponWaiting ? (
                              <Loader
                                type="TailSpin"
                                color={"white"}
                                height={20}
                                width={20}
                              />
                            ) : (
                              <RiCoupon3Fill className="text-white fs-20" />
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    {totals?.total_segments?.map((item, index) => {
                      if (item?.area !== "footer") {
                        return (
                          <Fragment key={index}>
                            <hr />
                            <div
                              key={index}
                              className="d-flex justify-content-between mb-2 row"
                              style={{ fontSize: "0.9em" }}
                            >
                              <p className="col-8 text-left">
                                {item?.code === "shipping"
                                  ? "Shipping & Handling"
                                  : item?.title}
                              </p>
                              <p className="col-4 fw-500 text-right">
                                {totals?.base_currency_code +
                                  " " +
                                  parseFloat(item?.value).toFixed(2)}
                              </p>
                            </div>
                          </Fragment>
                        );
                      }
                      return null;
                    })}
                  </div>
                  {totals?.total_segments?.map((item, index) => {
                    if (item?.area === "footer") {
                      return (
                        <Fragment key={index}>
                          <hr />
                          <div
                            key={index}
                            className="d-flex justify-content-between "
                          >
                            <p className="m-0 fw-700 fs-20 mb-0 d-flex flex-column">
                              <span className="pb-0">{item?.title}</span>
                              <span className="fs-10 faded-text">
                                VAT Inclusive
                              </span>
                            </p>
                            <p className="m-0 fw-700 fs-20">
                              {totals?.base_currency_code +
                                " " +
                                parseFloat(item?.value).toFixed(2)}
                            </p>
                          </div>
                        </Fragment>
                      );
                    }
                    return null;
                  })}
                  <button
                    className="btn btn-outline-success btn-block mt-3"
                    type="button"
                    onClick={() => {
                      setStep("payment");
                      document.getElementById("step-timeslot").scrollIntoView();
                    }}
                  >
                    Proceed to payment
                  </button>
                </div>
              )}
            </div>
          </div>
          <hr className="d-md-none" />
          <div className="step-box">
            <div className="step-name d-none d-md-block">03</div>
            <div className="step-details w-100" id="step-payment">
              {step !== "payment" ? (
                <div className="step-details-mini">
                  <div>
                    <p className="text-sm">
                      <span>Payment Information</span>
                      {paymentMethod && (
                        <span className="ml-2">
                          <img src={CheckboxSvg} alt="" />
                        </span>
                      )}
                    </p>
                    {paymentMethod === "ngeniusonline" ? (
                      paymentMethodDetails === "card" ? (
                        <span>{selectedSavedCard?.maskedPan}</span>
                      ) : (
                        <span>Using a New Card</span>
                      )
                    ) : (
                      <span>Pay via Cash or Card</span>
                    )}
                  </div>
                  {selectedAddress && selectedTimeslot && (
                    <button
                      className="btn btn-line-success d-inline-block d-md-none"
                      type="button"
                      onClick={() => setStep("payment")}
                    >
                      <FiPlus />
                    </button>
                  )}
                </div>
              ) : (
                <div className="step-details-full">
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="text-sm">Select Payment Information</p>
                    <button
                      className="btn btn-line-success d-inline-block d-md-none"
                      type="button"
                      onClick={() => setStep(null)}
                    >
                      <FiMinus />
                    </button>
                  </div>
                  {paymentMethods?.map((item, index) => {
                    if (item.code === "ngeniusonline")
                      return (
                        <div
                          className={`${
                            shownPayment === "ngeniusonline"
                              ? "selected-payment"
                              : ""
                          }`}
                          key={index}
                        >
                          <div
                            className={`form-check d-flex align-items-center px-2 py-2 py-md-4 mb-3`}
                            style={{ cursor: "pointer" }}
                          >
                            <input
                              type="radio"
                              id="ngeniusonline"
                              name="ngeniusonline"
                              className="form-check-input green-accent mb-1"
                              checked={shownPayment === "ngeniusonline"}
                              onChange={() => setShownPayment("ngeniusonline")}
                              hidden
                            />
                            <label
                              htmlFor="ngeniusonline"
                              className="form-check-label flex-grow-1 d-flex align-items-center justify-content-between"
                              style={{ cursor: "pointer" }}
                            >
                              {item?.title}
                              <span className="">
                                {shownPayment === "ngeniusonline" ? (
                                  <FiChevronUp />
                                ) : (
                                  <FiChevronDown />
                                )}
                              </span>
                            </label>
                          </div>
                          {paymentMethod === "ngeniusonline" ? (
                            <div className="d-flex align-items-center justify-content-between">
                              {paymentMethodDetails === "card" ? (
                                <span>{selectedSavedCard?.maskedPan}</span>
                              ) : (
                                <span>Using a New Card</span>
                              )}
                              <button
                                className="btn btn-line-success"
                                onClick={() => {
                                  setPaymentMethod(null);
                                  localStorage.setItem(null);
                                  setShownPayment("ngeniusonline");
                                  setPaymentMethodDetails(null);
                                }}
                              >
                                Change
                              </button>
                            </div>
                          ) : (
                            <>
                              {/*** Saved cards start */}

                              {shownPayment === "ngeniusonline" &&
                                userSavedCards.length > 0 &&
                                userSavedCards.map((card) => {
                                  return (
                                    <div
                                      className={`payment-label my-2 p-3 d-flex justify-content-between ${
                                        selectedSavedCardId === card?.id
                                          ? "selected-payment-method"
                                          : ""
                                      }`}
                                      key={card?.id}
                                    >
                                      <div className="flex-grow-1">
                                        <label
                                          key={card?.id}
                                          htmlFor={`saved-card-${card?.id}`}
                                          className="w-100"
                                          style={{ cursor: "pointer" }}
                                          // style={{marginBottom: "-0.5rem"}}
                                        >
                                          <div className="d-flex align-items-center">
                                            <div className="mr-1">
                                              {getCardIcon(card?.scheme)}
                                            </div>
                                            <span className="fw-500">
                                              {card?.maskedPan}
                                            </span>
                                          </div>
                                          <input
                                            className="d-none pay-radio"
                                            id={`saved-card-${card?.id}`}
                                            name="saved-card"
                                            type="radio"
                                            checked={
                                              selectedSavedCardId === card?.id
                                            }
                                            value={card?.id}
                                            onChange={(e) =>
                                              savedCardClickHandler(e)
                                            }
                                          />
                                        </label>
                                        {selectedSavedCardId === card?.id && (
                                          <>
                                            <div className="d-flex flex-row justify-content-start align-items-center py-3 pl-1">
                                              <label
                                                htmlFor="cvv-input"
                                                className="fw-500"
                                                style={{
                                                  marginBottom: "0",
                                                  zIndex: "8",
                                                }}
                                              >
                                                CVV
                                              </label>
                                              <input
                                                className="cvv-input"
                                                id="cvv-input"
                                                type="number"
                                                pattern="[0-9]"
                                                inputMode="numeric"
                                                onInput={(e) => {
                                                  if (
                                                    e.target.value.length >
                                                    e.target.maxLength
                                                  ) {
                                                    e.target.value =
                                                      e.target.value.slice(
                                                        0,
                                                        e.target.maxLength
                                                      );
                                                  }
                                                  setCvv(e.target.value);
                                                }}
                                                maxlength="4"
                                                style={{
                                                  marginLeft: "1rem",
                                                  zIndex: "8",
                                                  width: "5rem",
                                                }}
                                              />
                                            </div>
                                          </>
                                        )}
                                      </div>
                                      {selectedSavedCardId === card?.id && (
                                        <div>
                                          <button
                                            className="btn btn-success d-none d-md-flex"
                                            type="button"
                                            onClick={() => {
                                              setPaymentMethodDetails("card");
                                              handlePlaceOrder(shownPayment);
                                            }}
                                            disabled={orderLoading}
                                          >
                                            Checkout
                                            {orderLoading && (
                                              <span className="ml-1 spinner-border spinner-border-sm text-light"></span>
                                            )}
                                          </button>
                                          <button
                                            className="btn btn-line-success d-md-none py-0"
                                            onClick={() => {
                                              if (
                                                cvv.length > 4 ||
                                                cvv.length < 3 ||
                                                cvv.length === 0
                                              ) {
                                                setShowToast(true);
                                                setToastData({
                                                  type: "error",
                                                  text: "Please enter a valid CVV",
                                                });
                                                return 0;
                                              }
                                              setPaymentMethod(shownPayment);
                                              setPaymentMethodDetails("card");
                                              setStep(null);
                                            }}
                                          >
                                            Select
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  );
                                })}

                              {/*** Saved cards end */}

                              {shownPayment === "ngeniusonline" && (
                                <label
                                  htmlFor={`add-new-card`}
                                  className={`payment-label d-flex justify-content-between align-items-center px-3 py-3 ${
                                    selectedSavedCardId === ""
                                      ? "selected-payment-method"
                                      : ""
                                  }`}
                                >
                                  <div className="d-flex align-items-center">
                                    <div className="mr-1">
                                      <FaCreditCard
                                        size="25px"
                                        className="d-none d-md-inline-block"
                                      />
                                      <FaCreditCard className="d-md-none" />
                                    </div>
                                    <span className="fw-500">
                                      Use Other Card
                                    </span>
                                  </div>
                                  <input
                                    className="d-none pay-radio"
                                    id={`add-new-card`}
                                    name="saved-card"
                                    type="radio"
                                    checked={selectedSavedCardId === ""}
                                    value={""}
                                    onChange={(e) => setSelectedSavedCardId("")}
                                    hidden
                                  />
                                  {selectedSavedCardId === "" && (
                                    <>
                                      <button
                                        className="btn btn-success d-none d-md-flex"
                                        type="button"
                                        onClick={() => {
                                          handlePlaceOrder(shownPayment);
                                        }}
                                        disabled={orderLoading}
                                      >
                                        Checkout
                                        {orderLoading && (
                                          <span className="ml-1 spinner-border spinner-border-sm text-light"></span>
                                        )}
                                      </button>
                                      <button
                                        className="btn btn-line-success d-md-none py-0"
                                        onClick={() => {
                                          setPaymentMethod(shownPayment);
                                          setStep(null);
                                        }}
                                      >
                                        Select
                                      </button>
                                    </>
                                  )}
                                </label>
                              )}
                            </>
                          )}

                          <hr />
                        </div>
                      );
                    else if (item.code === "cashondelivery")
                      return (
                        <>
                          <div
                            className={`px-2 py-2 ${
                              shownPayment === "cashondelivery"
                                ? "selected-payment"
                                : ""
                            }`}
                            key={index}
                          >
                            <div
                              className={`d-flex align-items-center mb-3`}
                              style={{ cursor: "pointer" }}
                            >
                              <input
                                type="radio"
                                id="cashondelivery"
                                name="cashondelivery"
                                className="form-check-input green-accent mb-1"
                                checked={shownPayment === "cashondelivery"}
                                onChange={() =>
                                  setShownPayment("cashondelivery")
                                }
                                hidden
                              />
                              <label
                                htmlFor="cashondelivery"
                                className="form-check-label pl-0 flex-grow-1 d-flex align-items-center justify-content-between"
                                style={{ cursor: "pointer" }}
                              >
                                <span>{item?.title}</span>
                                <span className="">
                                  {shownPayment === "cashondelivery" ? (
                                    <FiChevronUp />
                                  ) : (
                                    <FiChevronDown />
                                  )}
                                </span>
                              </label>
                            </div>
                            {paymentMethod === "cashondelivery" ? (
                              <div className="d-flex align-items-center justify-content-between">
                                <span>Pay via Cash or Card</span>
                                <button
                                  className="btn btn-line-success"
                                  onClick={() => {
                                    setPaymentMethod(null);
                                    setShownPayment("cashondelivery");
                                    setPaymentMethodDetails(null);
                                  }}
                                >
                                  Change
                                </button>
                              </div>
                            ) : (
                              <>
                                {shownPayment === "cashondelivery" && (
                                  <div className="d-flex align-items-center justify-content-between pl-3">
                                    <div className="">
                                      Pay via Cash or Card.
                                    </div>
                                    <button
                                      className="btn btn-success d-none d-md-flex"
                                      type="button"
                                      onClick={() => {
                                        handlePlaceOrder(shownPayment);
                                      }}
                                      disabled={orderLoading}
                                    >
                                      Checkout
                                      {orderLoading && (
                                        <span className="ml-1 spinner-border spinner-border-sm text-light"></span>
                                      )}
                                    </button>
                                    <button
                                      className="btn btn-line-success d-md-none py-0"
                                      onClick={() => {
                                        setPaymentMethod(shownPayment);
                                        setStep(null);
                                      }}
                                    >
                                      Select
                                    </button>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                          <hr />
                        </>
                      );
                    else {
                      return null;
                    }
                  })}

                  {/*======coupon start=========*/}
                  {/* <p
                    className={
                      "text-14 font-weight-bold d-flex justify-content-between mt-4 " +
                      (couponApplied ? "mb-0" : "mb-1")
                    }
                  >
                    <p>{defaultLocale?.COUPON_LABEL}</p>
                  </p>
                  {couponApplied ? (
                    <div
                      style={{ borderColor: "mediumseagreen" }}
                      className="form-control my-0 p-4 d-flex justify-content-between align-items-center fw-900"
                    >
                      <div>{couponCode.toUpperCase()}</div>
                      <MdCancel
                        className="c-pointer"
                        onClick={!isLoading && handleRemoveCoupon}
                        size="26"
                        color="mediumseagreen"
                      />
                    </div>
                  ) : (
                    <div class="input-group">
                      <input
                        style={{
                          borderColor:
                            couponCode !== "" && couponInvalid
                              ? "red"
                              : "green",
                        }}
                        type="text"
                        class="form-control p-4"
                        aria-label={defaultLocale?.COUPON_PLACEHOLDER}
                        placeholder={defaultLocale?.COUPON_PLACEHOLDER}
                        maxLength="15"
                        value={couponCode}
                        onFocus={() => setCouponInvalid(false)}
                        onChange={(e) => setCouponCode(e.target.value)}
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-success"
                          type="button"
                          onClick={() =>
                            !isLoading && handleAddCoupon(couponCode)
                          }
                        >
                          {couponWaiting ? (
                            <Loader
                              type="TailSpin"
                              color={"white"}
                              height={20}
                              width={20}
                            />
                          ) : (
                            "Apply"
                          )}
                        </button>
                      </div>
                    </div>
                  )} */}

                  {/*======coupon end=========*/}

                  {/* <p className="text-14 font-weight-bold mt-4">Apply Coupon</p>
                  <div className="d-flex">
                    <input
                      className="p-3 border-line-all flex-grow-1 corner-left"
                      placeholder="Coupon Code"
                    />
                    <button className="icon-button p-2 corner-right">
                      <RiCoupon3Fill className="text-white fs-20" />
                    </button>
                  </div> */}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="cart-details d-none d-md-block">
          <h2 className="heading">Order Summary</h2>
          <hr />
          <div className="sidebar-cart-listing">
            {cartData?.map((item, index) => {
              return (
                <SidebarCartItem
                  key={`sidebar-cart-item-${index}-${item?.item_id}`}
                  cartData={item}
                />
              );
            })}
          </div>
          <hr />
          <div>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <p className="m-0 d-flex align-items-center">
                <span className="mr-2">Allow Substitutions</span>
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  placement="bottom"
                  overlay={
                    <Popover id="popover-basic">
                      <Popover.Title as="h3" className="text-center">
                        Substitutions
                      </Popover.Title>
                      <Popover.Content>
                        When enabled, if you miss the substitution suggestion
                        time limit of 10 minutes, Nesto will automatically
                        allocate the next best substitute for any out-of-stock
                        items.
                      </Popover.Content>
                    </Popover>
                  }
                >
                  <BsFillInfoCircleFill />
                </OverlayTrigger>
              </p>

              <button
                className={
                  "d-flex align-items-center toggle-switch" +
                  (isToggle ? " on" : "")
                }
                onClick={handleToggle}
              >
                <div className="toggle-indicator"></div>
              </button>
            </div>
            <div className="form-group">
              <textarea
                className="form-control"
                placeholder="Delivery Note"
                onChange={(e) => setDeliveryNote(e.target.value)}
                value={deliveryNote}
              ></textarea>
            </div>
            <div className="form-group">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  style={{
                    borderColor: couponCode !== "" && couponInvalid && "red",
                  }}
                  class="form-control p-4"
                  aria-label={defaultLocale?.COUPON_PLACEHOLDER}
                  placeholder={defaultLocale?.COUPON_PLACEHOLDER}
                  maxLength="15"
                  value={couponCode}
                  onFocus={() => setCouponInvalid(false)}
                  onChange={(e) => setCouponCode(e.target.value)}
                />
                <div className="input-group-append">
                  <button
                    type="button"
                    className="btn btn-outline-success"
                    onClick={() => !isLoading && handleAddCoupon(couponCode)}
                  >
                    {couponWaiting ? (
                      <Loader
                        type="TailSpin"
                        color={"white"}
                        height={20}
                        width={20}
                      />
                    ) : (
                      "Apply"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
          {!totals?.total_segments && (
            <div className="row">
              <p className="col-8 text-left mb-2 subtotal-text">SubTotal</p>
              <p className="col-4 text-right subtotal-text">{cartTotal}</p>
              <hr />
              <p className="col-8 text-left mb-2 amount-text">
                Shipping & Handling
              </p>
              <p className="col-4 text-right amount-text">-</p>
              <hr />
              <p className="col-8 text-left mb-2 amount-text">Tax</p>
              <p className="col-4 text-right amount-text">-</p>
            </div>
          )}
          <div className="">
            {totals?.total_segments?.map((item, index) => {
              if (item?.area !== "footer") {
                return (
                  <Fragment key={index}>
                    <hr />
                    <div
                      className={`d-flex justify-content-between mb-2 row ${
                        item?.title === "Subtotal"
                          ? "subtotal-text"
                          : "amount-text"
                      }`}
                    >
                      <p className="col-8 text-left">
                        {item?.code === "shipping"
                          ? "Shipping & Handling"
                          : item?.title}
                      </p>
                      <p className="col-4 text-right">
                        {totals?.base_currency_code +
                          " " +
                          parseFloat(item?.value).toFixed(2)}
                      </p>
                    </div>
                  </Fragment>
                );
              }
              return null;
            })}
          </div>
          {totals?.total_segments?.map((item, index) => {
            if (item?.area === "footer") {
              return (
                <Fragment key={index}>
                  <hr />
                  <div className="d-flex justify-content-between ">
                    <p className="m-0 fw-700 fs-20 mb-0 d-flex flex-column">
                      <span className="pb-0">{item?.title}</span>
                      <span className="fs-10 faded-text">VAT Inclusive</span>
                    </p>
                    <p className="m-0 fw-700 fs-20">
                      {totals?.base_currency_code +
                        " " +
                        parseFloat(item?.value).toFixed(2)}
                    </p>
                  </div>
                </Fragment>
              );
            }
            return null;
          })}
        </div>
      </div>
      <div className="d-md-none p-3 d-flex align-items-center justify-content-between mobile-fixed-checkout">
        <div className="">
          <div className="mb-1 item-details">
            <span className="fw-300">{cartData.length}</span>
            <span className="fw-400">{` `}ITEMS</span>
          </div>
          <div>
            <span className="fw-700 cart-amount">{`AED ${
              totals?.total_segments
                ? totals?.total_segments
                    ?.filter((i) => i?.title === "Subtotal")[0]
                    ?.value.toFixed(2)
                : cartTotal
            }`}</span>
          </div>
        </div>
        <button
          className="btn btn-success"
          disabled={!paymentMethod}
          onClick={() => handlePlaceOrder(shownPayment)}
        >
          Checkout
        </button>
      </div>
    </div>
  );
}
