import React from "react";
import { FiMail, FiPhoneCall, FiSmartphone } from "react-icons/fi";
import heroBanner from "../../assets/images/hero-banner.png";
import nestoCartLogo from "../../assets/images/nesto-cart-logo.png";
import sameDayDeliveries from "../../assets/images/same-day-deliveries.png";
import exclusiveDeals from "../../assets/images/exclusive-deals.png";
import getInaamRewards from "../../assets/images/get-inaam-rewards.png";
import freshnessGuaranteed from "../../assets/images/freshness-guaranteed.png";
import secureTransactions from "../../assets/images/secure-transactions.png";
import freshFood from "../../assets/images/fresh-food.png";
import groceries from "../../assets/images/groceries.png";
import electronics from "../../assets/images/electronics.png";
import personalCare from "../../assets/images/personal-care.png";
import babyCare from "../../assets/images/baby-care.png";
import homeAndKitchen from "../../assets/images/home-and-kitchen.png";
import outdoorAndLeisure from "../../assets/images/outdoor-and-leisure.png";
import stationaryWalletsAndBags from "../../assets/images/stationary-wallets-and-bags.png";
import inaamLogoAndText from "../../assets/images/inaam-logo-and-text.png";
import phoneImage from "../../assets/images/phone-image.png";
import nestoCartOnly from "../../assets/images/nesto-cart-only.png";
import faq from "../../assets/images/faq.png";
import mobileSvgArt from "../../assets/images/mobile-svg-art.png";
import "./Location.css";
import Footer from "../../components/Footer/Footer";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { useState } from "react";
import { useEffect } from "react";
import { getAreas, getCities, getZoneById } from "../../api";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { useRef } from "react";
import useWindowSize from "../../hooks/useWindowSize";

function Location() {
  const [cityData, setCityData] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [areaOptions, setAreaOptions] = useState([]);
  const [areaData, setAreaData] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedArea, setSelectedArea] = useState(null);
  const [cityLoading, setCityLoading] = useState(false);
  const [areaLoading, setAreaLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cityKeyword, setCityKeyword] = useState("");
  const [cityPage, setCityPage] = useState(1);
  const [searchCityPage, setSearchCityPage] = useState(1);
  const [areaKeyword, setAreaKeyword] = useState("");
  const [areaPage, setAreaPage] = useState(1);
  const [searchAreaPage, setSearchAreaPage] = useState(1);
  const { updateLocation, updateCurrentStore, setShowLogin, isLoggedIn } =
    useContext(AppContext);
  const { width } = useWindowSize();
  const history = useHistory();

  const selectStyle = {
    control: (provided, { menuIsOpen, isSelected }) => ({
      ...provided,
      padding: "8px 8px",
      margin: 0,
      fontSize: 14,
      textAlign: "left",
      borderColor: menuIsOpen ? "#28a745" : "hsl(0, 0%, 80%)",
      boxShadow: menuIsOpen ? "0 0 0 1px #28a745" : undefined,
      ":hover": {
        borderColor: "#28a745",
        boxShadow: menuIsOpen ? "0 0 0 1px #28a745" : undefined,
      },
    }),
    option: (provided, { isSelected, isDisabled, isFocused }) => ({
      ...provided,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? "#28a745"
        : isFocused
        ? "#EAF4EC"
        : undefined,

      color: isSelected ? "white" : "#181818",
      ":active": {
        ...provided[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? "#28a745"
            : "#EAF4EC"
          : undefined,
      },
    }),
    placeholder: (provided, state) => ({
      ...provided,
      textAlign: "left",
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none",
    }),
    menuList: (provided, state) => ({
      ...provided,
      textAlign: "left",
    }),
  };

  const loadCityOptions = () => {
    let params = {
      pagination: { page: cityKeyword ? searchCityPage : cityPage, limit: 20 },
    };
    let currentPage = cityKeyword ? searchCityPage : cityPage;
    if (cityKeyword) {
      params.filter = { name: cityKeyword };
    }
    return getCities(params)
      .then((res) => {
        if (res.data.data.hits.total.value - currentPage * 20 > 0) {
          cityKeyword
            ? setSearchCityPage((p) => p + 1)
            : setCityPage((p) => p + 1);
        }
        return {
          hasMore: res.data.data.hits.total.value - currentPage * 20 > 0,
          options: res.data.data.hits.hits,
        };
      })
      .catch((err) => []);
  };

  const loadAreaOptions = () => {
    if (!selectedCity) {
      return [];
    }
    let params = {
      pagination: { page: areaKeyword ? searchAreaPage : areaPage, limit: 10 },
      filter: { city_id: selectedCity._id },
    };
    let currentPage = areaKeyword ? searchAreaPage : areaPage;
    if (areaKeyword) {
      params.filter = { ...params.filter, name: areaKeyword };
    }
    return getAreas(params)
      .then((res) => {
        if (res.data.data.hits.total.value - currentPage * 10 > 0) {
          areaKeyword
            ? setSearchAreaPage((p) => p + 1)
            : setAreaPage((p) => p + 1);
        }
        return {
          hasMore: res.data.data.hits.total.value - currentPage * 10 > 0,
          options: res.data.data.hits.hits,
        };
      })
      .catch((err) => []);
  };

  // useEffect(() => {
  //   const getData = async () => {
  //     setCityLoading(true);
  //     try {
  //       const result = await getCities();
  //       setCityData(result.data.hits.hits);
  //       let options = result.data.hits.hits.map((v) => {
  //         return {
  //           value: v._id,
  //           label: v._source.name,
  //         };
  //       });
  //       setCityOptions(options);
  //     } catch (error) {}
  //     setCityLoading(false);
  //   };
  //   getData();
  // }, []);

  // useEffect(() => {
  //   const getData = async () => {
  //     setAreaLoading(true);
  //     try {
  //       const result = await getAreas(selectedCity.value);
  //       setAreaData(result.data.hits.hits);
  //       let options = result.data.hits.hits.map((v) => {
  //         return {
  //           value: v._id,
  //           label: v._source.name || v._source.area_name,
  //         };
  //       });
  //       setAreaOptions(options);
  //     } catch (error) {}
  //     setAreaLoading(false);
  //   };
  //   if (selectedCity?.value) {
  //     getData();
  //   }
  // }, [selectedCity]);

  const setAreaAndProceed = async () => {
    setLoading(true);
    console.log(selectedCity, selectedArea);
    if (!selectedCity || !selectedArea) {
      return;
    }
    const zoneId = selectedArea._source.zone_id;

    const { lat, long } = selectedCity._source;

    try {
      const result = await getZoneById(zoneId);
      updateLocation(
        {
          city: { ...selectedCity, lat, long },
          area: selectedArea,
          zone: {
            label: result?.data?.name,
            id: result?.data?.id,
          },
        },
        result?.data?.site
      );
      updateCurrentStore({
        ...result?.data?.site?.stores[0],
      });

      history.push("/");
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  console.log(width);

  return (
    <>
      <div className="location-web">
        <div
          className="hero-image"
          style={{ backgroundImage: `url(${heroBanner})` }}
        >
          <div className="container location-page-padding py-4 d-flex align-items-center justify-content-between">
            <Link
              to="/nesto-app"
              className="d-flex align-items-center text-white"
            >
              <FiSmartphone fontSize={20} />
              <span className="ml-1">Get The App</span>
            </Link>
            {isLoggedIn ? (
              <Link className="btn btn-white" to="/">
                Home
              </Link>
            ) : (
              <button
                className="btn btn-white"
                onClick={() => setShowLogin(true)}
              >
                Get Started
              </button>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-center container location-page-padding">
          <div className="location-banner">
            <img src={nestoCartLogo} className="mb-3" alt="" />
            <h1 className="heading">All That You Need</h1>
            <p className="sub-heading">
              Bringing the Hypermarket Experience Online
            </p>
            <div className="row m-0 mb-3">
              <div className="col-5 p-0 pr-2">
                {/* <AsyncSelect
                  placeholder="Select Emirate"
                  styles={selectStyle}
                  options={cityOptions}
                  isSearchable={false}
                  onChange={(v) => {
                    setSelectedCity(v);
                    setSelectedArea(null);
                  }}
                  value={selectedCity}
                  isLoading={cityLoading}
                /> */}
                <AsyncPaginate
                  debounceTimeout={300}
                  cacheOptions
                  defaultOptions
                  getOptionLabel={(option) =>
                    option._source.name || option._source.area_name
                  }
                  getOptionValue={(option) => option._id}
                  loadOptions={loadCityOptions}
                  inputValue={cityKeyword}
                  onInputChange={(v) => {
                    setSearchCityPage(1);
                    setCityKeyword(v);
                  }}
                  value={selectedCity}
                  onChange={(v) => {
                    setAreaPage(1);
                    setSelectedCity(v);
                  }}
                  placeholder="Select Emirate"
                  styles={selectStyle}
                />
              </div>
              <div className="col-7 p-0">
                <AsyncPaginate
                  key={selectedCity?._id}
                  debounceTimeout={300}
                  cacheOptions
                  defaultOptions
                  getOptionLabel={(option) =>
                    option._source.name || option._source.area_name
                  }
                  getOptionValue={(option) => option._id}
                  loadOptions={loadAreaOptions}
                  inputValue={areaKeyword}
                  onInputChange={(v) => {
                    setSearchAreaPage(1);
                    setAreaKeyword(v);
                  }}
                  value={selectedArea}
                  onChange={(v) => {
                    setSelectedArea(v);
                  }}
                  placeholder="Select Area"
                  styles={selectStyle}
                  isDisabled={selectedCity ? false : true}
                  isLoading={areaLoading}
                />
                {/* <Select
                  placeholder="Select Area"
                  styles={selectStyle}
                  isSearchable={true}
                  options={areaOptions}
                  onChange={(v) => {
                    setSelectedArea(v);
                  }}
                  value={selectedArea}
                  isDisabled={selectedCity ? false : true}
                  isLoading={areaLoading}
                /> */}
              </div>
            </div>
            <button
              className="btn btn-success btn-lg btn-block"
              onClick={setAreaAndProceed}
              disabled={loading || !selectedCity || !selectedArea}
            >
              Start Shopping
              {loading && <span className="spinner-border text-success"></span>}
            </button>
          </div>
        </div>
        <div className="service-details container location-page-padding">
          <div className="service-item two-span">
            <img src={sameDayDeliveries} alt="" />
            <p className="heading">Same Day Deliveries</p>
            <p className="details">
              Get Orders Delivered quickly and conveniently
            </p>
          </div>
          <div className="service-item two-span">
            <img src={exclusiveDeals} alt="" />
            <p className="heading">Exclusive Deals</p>
            <p className="details">Get Exclusive deals curated for you</p>
          </div>
          <div className="service-item two-span">
            <img src={getInaamRewards} alt="" />
            <p className="heading">Get INAAM Rewards</p>
            <p className="details">Get offers, cashbacks and more on INAAM</p>
          </div>
          <div className="service-item three-span">
            <img src={freshnessGuaranteed} alt="" />
            <p className="heading">Freshness Guaranteed</p>
            <p className="details">Get Fresh Groceries and Foods</p>
          </div>
          <div className="service-item three-span">
            <img src={secureTransactions} alt="" />
            <p className="heading">Secure Transaction</p>
            <p className="details">
              Get Peace of mind when you shop on our secure servers
            </p>
          </div>
        </div>
        <div className="service-tiles container location-page-padding">
          <div className="heading">More Products. More Choices.</div>
          <div className="image-grid">
            <div className="item">
              <img src={freshFood} alt="" />
              <div className="image-grid-title">Fresh Food</div>
            </div>
            <div className="item">
              <img src={groceries} alt="" />
              <div className="image-grid-title">Groceries</div>
            </div>
            <div className="item">
              <img src={electronics} alt="" />
              <div className="image-grid-title">Electronics</div>
            </div>
            <div className="item">
              <img src={personalCare} alt="" />
              <div className="image-grid-title">Personal Care</div>
            </div>
          </div>
          <div className="image-grid">
            <div className="item">
              <img src={babyCare} alt="" />
              <div className="image-grid-title">Baby Care</div>
            </div>
            <div className="item">
              <img src={homeAndKitchen} alt="" />
              <div className="image-grid-title">Home &amp; Kitchen</div>
            </div>
            <div className="item">
              <img src={outdoorAndLeisure} alt="" />
              <div className="image-grid-title">Outdoor &amp; Leisure</div>
            </div>
            <div className="item">
              <img src={stationaryWalletsAndBags} alt="" />
              <div className="image-grid-title">
                Stationary, Wallets &amp; Bags
              </div>
            </div>
          </div>
        </div>
        <div className="container location-page-padding">
          <div className="inaam-banner row mx-0">
            <div className="col-7 col-lg-6">
              <h1 className="heading">Earn Rewards</h1>
              <h3 className="sub-heading">by Shopping at Nesto Hypermarket</h3>
              <div className="details">
                Claim points, cashbacks and discounts in return.
              </div>
              <hr />
              <Link className="btn btn-lg btn-danger" to="/inaam-app">
                Download INAAM App
              </Link>
            </div>
            <div className="col-5 col-lg-6 d-flex align-items-center justify-content-center">
              <img src={inaamLogoAndText} alt="" />
            </div>
          </div>
        </div>
        <div className="container location-page-padding">
          <div className="nesto-app-banner row mx-0">
            <div className="col-6 image-col d-flex align-items-end">
              <img src={phoneImage} className="mr-2" alt="" />
              <img src={phoneImage} className="d-none d-lg-block" alt="" />
            </div>
            <div className="col-6 content-col">
              <div>
                <div className="d-flex align-items-center mb-3">
                  <img src={nestoCartOnly} className="mr-1" alt="" />
                  <div className="badge">0 km away!</div>
                </div>
                <h2 className="heading">Download Nesto</h2>
                <div className="details">
                  Bring the experience of Hypermarket in your hands
                </div>
                <hr />
                <Link className="btn btn-lg btn-success" to="/nesto-app">
                  Download Nesto App
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      {width < 768 && (
        <div className="location-mobile">
          <div className="mob-header">
            <img src={nestoCartLogo} alt="" />
            <button className="btn btn-outline-success">
              <FiSmartphone className="mb-1" />
              <span className="ml-1">Get the app</span>
            </button>
          </div>
          <div className="mob-hero">
            <Swiper
              slidesPerView={1}
              style={{ height: "100%" }}
              loop={true}
              pagination={{
                clickable: true,
                renderBullet: (index, className) => {
                  return `<span class="${className} bg-white p-1"></span>`;
                },
              }}
              modules={[Pagination]}
            >
              <SwiperSlide>
                <div className="slide">
                  <img src={mobileSvgArt} alt="" />
                  <div className="details">
                    Shop for a wide range of Products
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="slide">
                  <img src={mobileSvgArt} alt="" />
                  <div className="details">
                    Shop for a wide range of Products
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="slide">
                  <img src={mobileSvgArt} alt="" />
                  <div className="details">
                    Shop for a wide range of Products
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          <div className="mob-location-banner">
            <h2 className="heading">All That You Need</h2>
            <div className="details">
              Bringing the Hypermarket experience Online
            </div>
            <div className="my-3">
              <div className="mb-2">
                <AsyncPaginate
                  debounceTimeout={300}
                  cacheOptions
                  defaultOptions
                  getOptionLabel={(option) => option._source.name}
                  getOptionValue={(option) => option._id}
                  loadOptions={loadCityOptions}
                  inputValue={cityKeyword}
                  onInputChange={(v) => {
                    setSearchCityPage(1);
                    setCityKeyword(v);
                  }}
                  value={selectedCity}
                  onChange={(v) => {
                    setAreaPage(1);
                    setSelectedCity(v);
                  }}
                  placeholder="Select Emirate"
                  styles={selectStyle}
                />
              </div>
              <div className="mb-2">
                <AsyncPaginate
                  key={selectedCity?._id}
                  debounceTimeout={300}
                  cacheOptions
                  defaultOptions
                  getOptionLabel={(option) => option._source.name}
                  getOptionValue={(option) => option._id}
                  loadOptions={loadAreaOptions}
                  inputValue={areaKeyword}
                  onInputChange={(v) => {
                    setSearchAreaPage(1);
                    setAreaKeyword(v);
                  }}
                  value={selectedArea}
                  onChange={(v) => {
                    setSelectedArea(v);
                  }}
                  placeholder="Select Area"
                  styles={selectStyle}
                  isDisabled={selectedCity ? false : true}
                  isLoading={areaLoading}
                />
              </div>
              <button
                className="btn btn-success btn-lg btn-block"
                onClick={setAreaAndProceed}
                disabled={loading || !selectedCity || !selectedArea}
              >
                Start Shopping
                {loading && (
                  <span className="spinner-border text-success"></span>
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Location;
