import React, { useEffect, useState } from "react";
import { useContext } from "react";
import TagManager from "react-gtm-module";
import { useHistory } from "react-router-dom";
import { useLocation } from 'react-router-dom'
import placeholder from "../../assets/images/placeholder.svg";
import { AppContext } from "../../context/AppContext";
import useWindowSize from "../../hooks/useWindowSize";
import "./OrderItemView.css";
const OrderItemView = ({ item, view }) => {
  const [selectedQuantity, setSelectedQuantity] = React.useState(1);
  const {checkedItems, setCheckedItems} = useContext(AppContext)
  const [isSelected, setIsSelected] = useState(false);
  const [currentReturnItem, setCurrentReturnItem] = useState({});
  const history = useHistory();
  const location = useLocation();
  const { width } = useWindowSize();


  console.log("currentReturnItem", currentReturnItem)

  useEffect(() => {
    const itemIndex = checkedItems?.findIndex((checkedItem => checkedItem?.id == item?.item_id));
    if (itemIndex > -1) {
      setIsSelected(true)
    }else {
      setIsSelected(false)
    }
  }, [checkedItems])

  const onProductClick = () => {
    // process?.env?.REACT_APP_ENV === "production" &&
    //   TagManager.dataLayer({
    //     dataLayerName: "dataLayer",
    //     dataLayer: {
    //       ecommerce: null
    //     }
    //   });
    // process?.env?.REACT_APP_ENV === "production" &&
    //   TagManager.dataLayer({
    //     dataLayerName: "dataLayer",
    //     dataLayer: {
    //       event: "productClick",
    //       ecommerce: {
    //         click: {
    //           actionField: { list: "orderDetailsList" }, // Optional list property.
    //           products: [
    //             {
    //               name: item?.name, // Name or ID is required.
    //               id: item?.sku,
    //               price: item?.tax_included_price,
    //               brand: "",
    //               category: "",
    //               variant: "",
    //               position: 0
    //             }
    //           ]
    //         }
    //       }
    //     }
    //   });
    history.push(`/products/${item.sku}`);
  };

  const quantityChangeHandler = (e) => {
    const itemIndex = checkedItems?.findIndex((checkedItem => checkedItem?.id == item?.item_id))
    const tempItem = checkedItems[itemIndex];
    tempItem.quantity = parseInt(e?.target?.value);
    checkedItems[itemIndex] = tempItem;
    setCheckedItems([...checkedItems]);
  }

  console.log("checkedItems", checkedItems)

  useEffect(() => {

    const tempItem = checkedItems?.find((checkedItem => checkedItem?.id == item?.item_id))
    console.log(tempItem);
    setCurrentReturnItem(tempItem);
  } , [checkedItems])


  return (
    <>
    {location.pathname.includes("/orderdetails/") && (
     <div class="mx-1 my-auto">
     <select class= "quantity-selector p-1 rounded" disabled={!isSelected} id="" style={{cursor: "pointer"}} onChange={quantityChangeHandler}>
       {Array.from({length: parseInt(item?.qty_ordered)}, (_, i) => i + 1).map((i) => (
         <option selected value={i}>{i}</option>
       ))}
     </select>
   </div>
    )}

    
    <div
      className={
        "order-item-container fs-14" + (view === "modal" ? " row m-0 p-0" : "")
      }
      onClick={onProductClick}
    >
      <div
        className={
          "order-item-image-container" +
          (view === "modal" ? " col-md-1 col-2 pl-0" : "")
        }
      >
        <span className="order-item-qty">{ location.pathname.includes("/orderdetails/") ? parseInt(item?.qty_ordered) : parseInt(currentReturnItem?.quantity)}</span>
        <img src={placeholder} alt="product" height="60px" />
      </div>
      <div
        className={
          "order-item-name" + (view === "modal" ? " col-md-8 col-6" : "")
        }
        style={{paddingLeft: location.pathname.includes("/orderdetails/") ? width >= 768 ?  "1.5rem" : "0px" : "0px"}}
      >
        {item?.name}
      </div>
      <div
        className={
          "order-item-price" + (view === "modal" ? " col-3 text-right" : "")
        }
      >
        {`AED ${parseFloat(item?.base_price_incl_tax).toFixed(2)}`}
      </div>
    </div>
    </>
  );
};

export default OrderItemView;
